export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS'
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS'

export const CHANGE_NOTIFICATIONS_PAGINATION_META = 'CHANGE_NOTIFICATIONS_PAGINATION_META'
export const RECEIVE_NOTIFICATIONS_PAGINATION_META = 'RECEIVE_NOTIFICATIONS_PAGINATION_META'

export const REQUEST_UNREAD_NOTIFICATIONS_COUNT = 'REQUEST_UNREAD_NOTIFICATIONS_COUNT'
export const RECEIVE_UNREAD_NOTIFICATIONS_COUNT = 'RECEIVE_UNREAD_NOTIFICATIONS_COUNT'

export const MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ'
export const MARK_NOTIFICATIONS_ALL_AS_READ = 'MARK_NOTIFICATIONS_ALL_AS_READ'
export const DISMISS_NOTIFICATIONS = 'DISMISS_NOTIFICATIONS'

export const REQUEST_DISPATCHED_NOTIFICATIONS = 'REQUEST_DISPATCHED_NOTIFICATIONS'
export const RECEIVE_DISPATCHED_NOTIFICATIONS = 'RECEIVE_DISPATCHED_NOTIFICATIONS'

export const SEND_NOTIFICATION = 'SEND_NOTIFICATION'

export const REQUEST_NOTIFICATIONS_ROUTINGS = 'REQUEST_NOTIFICATIONS_ROUTINGS'
export const RECEIVE_NOTIFICATIONS_ROUTINGS = 'RECEIVE_NOTIFICATIONS_ROUTINGS'
export const REQUEST_NOTIFICATIONS_ROUTING_GRAPH = 'REQUEST_NOTIFICATIONS_ROUTING_GRAPH'
export const RECEIVE_NOTIFICATIONS_ROUTING_GRAPH = 'RECEIVE_NOTIFICATIONS_ROUTING_GRAPH'
export const SETUP_NOTIFICATIONS_ROUTINGS = 'SETUP_NOTIFICATIONS_ROUTINGS'
export const CREATE_NOTIFICATIONS_ROUTINGS = 'CREATE_NOTIFICATIONS_ROUTINGS'
export const UPDATE_NOTIFICATIONS_ROUTINGS = 'UPDATE_NOTIFICATIONS_ROUTINGS'
export const REMOVE_NOTIFICATIONS_ROUTINGS = 'REMOVE_NOTIFICATIONS_ROUTINGS'

export const REQUEST_NOTIFICATIONS_SINKS = 'REQUEST_NOTIFICATIONS_SINKS'
export const RECEIVE_NOTIFICATIONS_SINKS = 'RECEIVE_NOTIFICATIONS_SINKS'
export const CREATE_NOTIFICATIONS_SINK = 'CREATE_NOTIFICATIONS_SINK'
export const UPDATE_NOTIFICATIONS_SINK = 'UPDATE_NOTIFICATIONS_SINK'
export const REMOVE_NOTIFICATIONS_SINK = 'REMOVE_NOTIFICATIONS_SINK'
export const TEST_NOTIFICATIONS_SINK = 'TEST_NOTIFICATIONS_SINK'

export const REQUEST_NOTIFICATIONS_TEMPLATES = 'REQUEST_NOTIFICATIONS_TEMPLATES'
export const RECEIVE_NOTIFICATIONS_TEMPLATES = 'RECEIVE_NOTIFICATIONS_TEMPLATES'
export const CREATE_NOTIFICATIONS_TEMPLATE = 'CREATE_NOTIFICATIONS_TEMPLATE'
export const UPDATE_NOTIFICATIONS_TEMPLATE = 'UPDATE_NOTIFICATIONS_TEMPLATE'
export const REMOVE_NOTIFICATIONS_TEMPLATE = 'REMOVE_NOTIFICATIONS_TEMPLATE'

export const START_FETCHING_NOTIFICATIONS = 'START_FETCHING_NOTIFICATIONS'
export const STOP_FETCHING_NOTIFICATIONS = 'STOP_FETCHING_NOTIFICATIONS'
