import React from 'react'
import moment, { Moment } from 'moment'
import { DateRange } from '@mui/x-date-pickers-pro'
import { IntlShape } from 'react-intl'

import { DATE_FORMATS, TIME_FORMATS, getDateFormats } from '@constants/date-formats.constants'

/* Setup locates */

import 'moment/locale/de'

import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'
import { SHORT_LOCALES } from '@constants/locales.constants'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'ddd, MMM DD, YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd, D MMMM YYYY HH:mm',
  },
})

moment.updateLocale('de', {
  week: {
    dow: 1,
  },
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'ddd, DD. MMM YYYY',
    LLL: 'D. MMMM YYYY HH:mm',
    LLLL: 'dddd, D. MMMM YYYY HH:mm',
  },
})

export const DEFAULT_TOOLTIP_DATE_FORMAT = 'LL'
export const ISO_CALENDAR_WEEK_FORMAT = 'W'

export const DEFAULT_DATE_PICKER_FORMAT = 'MMM D, YYYY'
export const DEFAULT_CHART_TICKS_FORMAT = 'MMM DD'

/**
 * @function unixTimestampToDate Format unix timestamp to Date (moment)
 *
 * @param {Number} unix Unix Timestamp
 *
 * @return {Object} Date (Moment Instance)
 */
export const unixTimestampToDate = (unix: number | string) => {
  if (!unix) {
    return undefined
  }

  return moment.unix(Number(unix)).utc().isValid() ? moment.unix(Number(unix)).utc() : undefined
}

/**
 * @function toUnixTimestamp Format Date (moment) to unix timestamp
 *
 * @param {Object} date Date (Moment Instance)
 *
 * @return {Number} Unix Timestamp
 */
export const toUnixTimestamp = (date: moment.Moment): string => {
  return date && date.format('X')
}

/**
 * @function dateStringToUnixTimestamp Format Date (string) to unix timestamp
 *
 * @param {Object} date Date string
 *
 * @return {Number} Unix Timestamp
 */
export const dateStringToUnixTimestamp = (date: string, format = 'YYYY-MM-DD'): number => {
  return Number(toUnixTimestamp(moment.utc(date, format)))
}

/**
 * @function getCurrentTimestamp Returns current date as timestamp
 *
 * @return {Number} Unix Timestamp
 */
export const getCurrentTimestamp = (): string => {
  const date = moment().utc().startOf('day')

  return toUnixTimestamp(date)
}

/**
 * @function getCurrentDate Returns current date as ISO 8601
 *
 * @return {String} Date ISO 8601
 */
export const getCurrentDate = (): string => {
  const date = moment()

  return date.toISOString()
}

/**
 * @function getFormattedCurrentDate Returns current date as 'YYYY-MM-DD' for Hermes
 *
 * @param {String} format Format as a string
 *
 * @return {String} Date 'YYYY-MM-DD'
 */
export const getFormattedCurrentDate = (format = 'YYYY-MM-DD') => {
  const date = moment()

  return date.format(format)
}

/**
 * @function formatDateString Formats Date
 *
 * @param  {String} date Date as a string
 * @param  {String} format Format as a string
 *
 * @return {String} Formatted date
 */
export const formatDateString = (date: string, format = 'YYYY-MM-DD'): string => {
  const momentDate = moment(date)

  return momentDate.format(format)
}

/**
 * @function formatTimestamp Returns formated timestamp
 *
 * @param  {String} timestamp UNIX timestamp
 * @param  {String} format format as a string
 *
 * @return {String} Returns formated timestamp
 */
export const formatTimestamp = (timestamp: number, locale?: string | SHORT_LOCALES, format = 'DD-MM-YYYY'): string => {
  const momentDate = unixTimestampToDate(timestamp)

  if (momentDate && locale) {
    momentDate.locale(locale)
  }

  return momentDate ? momentDate.format(format) : ''
}

/**
 * @function formatDateTime Formats Date & Time
 *
 * @param {Object} intl Intl object
 * @param {Number} value UNIX timestamp
 * @param {Boolean} fullTime Shows seconds
 *
 * @return {String} Formatted date
 */
export const formatDateTime = (
  intl: IntlShape,
  value: number | string,
  fullTime: boolean = false,
) => {
  const date = Number.isInteger(Number(value)) ? unixTimestampToDate(value as number)?.toISOString() : new Date(value).toISOString()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const formats = getDateFormats(timeZone)
  const timeFormat = fullTime ? TIME_FORMATS.TIME_24_DURATION : TIME_FORMATS.TIME_24

  // @ts-ignore
  return `${intl.formatDate(date, formats.date[DATE_FORMATS.FULL_DATE_SHORT])} ${intl.formatTime(date, formats.time[timeFormat])}` || '-'
}

/**
 * @function formatDate Formats Date
 *
 * @param {Object} intl Intl object
 * @param {Number} value UNIX timestamp
 * @param {String} format one of DATE_FORMATS
 *
 * @return {String} Formatted date
 */
export const formatDate = (
  intl: IntlShape,
  value?: number | string | null,
  format = DATE_FORMATS.FULL_DATE_SHORT,
) => {
  const date = Number.isInteger(Number(value)) ? unixTimestampToDate(value || 0) : value
  const formats = getDateFormats()

  // @ts-ignore
  return `${intl.formatDate(date, formats.date[format])}` || '-'
}

/**
 * @function formatTime Formats Date
 *
 * @param {Object} intl Intl object
 * @param {Number} value UNIX timestamp
 * @param {String} format one of TIME_FORMATS
 *
 * @return {String} Formatted time
 */
export const formatTime = (
  intl: IntlShape,
  value?: number | string | null,
  format = TIME_FORMATS.TIME_24,
) => {
  const date = Number.isInteger(Number(value)) ? unixTimestampToDate(value || 0) : value
  const formats = getDateFormats(undefined, false)

  // @ts-ignore
  return `${intl.formatTime(date, formats.time[format])}` || '-'
}

/**
 * @function getStartOfTheWeek Returns start of the week
 *
 * @returns {String} Start of the current week
 */
export const getStartOfTheWeek = () => {
  return moment().startOf('week').format('YYYY-MM-DD')
}

/**
 * @function formatDateForTooltip Formats Date for tooltip
 *
 * @param intl IntlShape
 * @param date Date
 *
 * @returns {String} Formatted date
 */
export const formatDateForTooltip = (intl: IntlShape, date: string | number) => {
  const isTimestamp = typeof date === 'number'
  const formattedDate = isTimestamp ? formatTimestamp(date, intl.locale, DEFAULT_TOOLTIP_DATE_FORMAT) : formatDateString(date, DEFAULT_TOOLTIP_DATE_FORMAT)
  const formattedCalendarWeek = isTimestamp ? formatTimestamp(date, intl.locale, ISO_CALENDAR_WEEK_FORMAT) : formatDateString(date, ISO_CALENDAR_WEEK_FORMAT)

  return intl.formatMessage({ id: 'common.dates.formatWithWeek' }, {
    date: (<IntlFormatBoldComponent>{formattedDate}</IntlFormatBoldComponent>),
    week: formattedCalendarWeek,
  })
}

/**
 * @function transformDatePickerValues - Convert date picker values to custom format
 *
 * @param value - Date picker value
 *
 * @returns {null | {
 *  from: string | null,
 *  to: string | null,
 * }}
 */
export const transformDatePickerValues = (value: Common.DatePickerValue, format = 'YYYY-MM-DD') => {
  if (!value || (!value[0] && !value[1])) {
    return null
  }

  const finalValue: {
    from: string | null,
    to: string | null,
  } = {
    from: null,
    to: null,
  }

  if (value[0]) {
    finalValue.from = value[0].format(format)
  }

  if (value[1]) {
    finalValue.to = value[1].format(format)
  }

  return finalValue
}

/**
 * @function shortcutOptionToDateRange - Convert time window option to date range
 *
 * @param shortcutOption - Shortcut option
 * @param resetValues - Reset values
 *
 * @returns {DateRange<Moment>} Date
 */
export const shortcutOptionToDateRange = (
  shortcutOption: DATE_PICKER_SHORTCUT,
): DateRange<Moment> => {
  switch (shortcutOption) {
    case DATE_PICKER_SHORTCUT.LAST_WEEK: {
      return [
        moment().utc().subtract(7, 'days').startOf('week'),
        moment().utc().subtract(7, 'days').endOf('week'),
      ]
    }

    case DATE_PICKER_SHORTCUT.FROM_LAST_WEEK: {
      return [
        moment().utc().subtract(7, 'days').startOf('week'),
        null,
      ]
    }

    case DATE_PICKER_SHORTCUT.THIS_MONTH: {
      return [
        moment().utc().startOf('month'),
        moment().utc().endOf('month'),
      ]
    }

    case DATE_PICKER_SHORTCUT.FROM_THIS_MONTH: {
      return [
        moment().utc().startOf('month'),
        null,
      ]
    }

    case DATE_PICKER_SHORTCUT.LAST_MONTH: {
      return [
        moment().utc().subtract(1, 'month').startOf('month'),
        moment().utc().subtract(1, 'month').endOf('month'),
      ]
    }

    case DATE_PICKER_SHORTCUT.THIS_YEAR: {
      return [
        moment().utc().startOf('year'),
        moment().utc().endOf('year'),
      ]
    }

    case DATE_PICKER_SHORTCUT.FROM_THIS_YEAR: {
      return [
        moment().utc().startOf('year'),
        null,
      ]
    }

    case DATE_PICKER_SHORTCUT.FROM_TODAY: {
      return [
        moment().utc().startOf('day'),
        null,
      ]
    }

    case DATE_PICKER_SHORTCUT.LAST_YEAR: {
      return [
        moment().utc().subtract(1, 'year').startOf('year'),
        moment().utc().subtract(1, 'year').endOf('year'),
      ]
    }

    case DATE_PICKER_SHORTCUT.SINCE_INCEPTION: {
      return [
        null,
        null,
      ]
    }

    default: {
      return [
        null,
        null,
      ]
    }
  }
}
