import produce from 'immer'

import { InsightsState, ReducerPayload } from '@redux/modules/types'

import {
  getEventsVisibility,
  getEventsToExclude,
  getLastYearVisibility,
} from '@utils/local-storage.utils'

const defaultCbcClassification = {
  a: 0,
  b: 0,
  c: 0,
  total: 0,
}

const defaultInsightsPipelineRunInfo = {
  dateOfExecution: '',
  pipelineRunId: '',
} as Hera.LatestPipelineRunInfoAPIResponse

export const initialState: InsightsState = {
  fetchingKeys: [],
  abcTotals: defaultCbcClassification,
  pipelineRunInfo: defaultInsightsPipelineRunInfo,
  eventsVisibility: getEventsVisibility(),
  eventsToExclude: getEventsToExclude(),
  lastYearVisibility: getLastYearVisibility(),
}

export const receiveInsightsAbcTotals = (state: InsightsState, action: ReducerPayload<Hera.AbcTotalsApiResponse>) => {
  const nextState = produce(state, (draftState) => {
    draftState.abcTotals = action.payload
  })

  return nextState
}

export const receiveInsightsPipelineInfo = (state: InsightsState, action: ReducerPayload<Hera.LatestPipelineRunInfoAPIResponse>) => {
  const nextState = produce(state, (draftState) => {
    draftState.pipelineRunInfo = action.payload
  })

  return nextState
}

export const receiveEventsVisibility = (state: InsightsState, action: ReducerPayload<boolean>) => {
  const nextState = produce(state, (draftState) => {
    draftState.eventsVisibility = action.payload
  })

  return nextState
}

export const receiveEventsSettings = (state: InsightsState, action: ReducerPayload<string[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.eventsToExclude = action.payload
  })

  return nextState
}

export const receiveLastYearVisibility = (state: InsightsState, action: ReducerPayload<boolean>) => {
  const nextState = produce(state, (draftState) => {
    draftState.lastYearVisibility = action.payload
  })

  return nextState
}
