import { styled } from '@mui/material'

const ChartTooltipTableCellComponent = styled('td')({
  display: 'table-cell',
  verticalAlign: 'middle',
  paddingRight: '20px',
  '&:last-child': {
    paddingRight: 0,
  },
} as any)

export default ChartTooltipTableCellComponent
