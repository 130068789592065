export const ROOT_PAGE_NAME = 'root'
export const DASHBOARD_PAGE_NAME = 'dashboard'
export const PIPELINES_PAGE_NAME = 'pipelines'
export const USE_CASE_PIPELINES_PAGE_NAME = 'use_case_pipelines'
export const PIPELINE_OVERVIEW_PAGE_NAME = 'pipeline_overview'
export const COMPANIES_PAGE_NAME = 'companies'
export const USERS_PAGE_NAME = 'users'
export const CONNECT_PAGE_NAME = 'connect'
export const DATA_UPLOAD_PAGE_NAME = 'data_upload'
export const DATA_FILE_LINK_PAGE_NAME = 'data_upload_file'
export const OAUTH_CALLBACK_PAGE_NAME = 'oauth_callback'

export const OPTIMIZE_PAGE_NAME = 'optimize'
export const OPTIMIZE_DRILL_DOWN_PAGE_NAME = 'optimize_drill_down'
export const TRADE_OFFS_PAGE_NAME = 'trade_offs'
export const NOT_FOUND_PAGE_NAME = '404'
export const MONITOR_PAGE_NAME = 'monitor'
export const MONITOR_BACKTESTING_PAGE_NAME = 'monitor_backtesting'
export const MONITOR_LIVE_MONITORING_PAGE_NAME = 'monitor_live_monitoring'
export const EXPORT_PAGE_NAME = 'export'
export const SNAPSHOTS_PAGE_NAME = 'snapshots'
export const SNAPSHOT_DETAILS_PAGE_NAME = 'snapshot_details'
export const FILE_MANAGER_PAGE_NAME = 'file_manager'
export const FILE_MANAGER_FOLDER_PAGE_NAME = 'file_manager_folder'
export const CONNECTORS_PAGE_NAME = 'connectors'
export const ANALYZE_PAGE_NAME = 'insights'
export const TRAINING_DATA_OVERVIEW_PAGE_NAME = 'trainging_data_overview'
export const NOTIFICATIONS_PAGE_NAME = 'notifications'
export const DISPATCHED_NOTIFICATIONS_PAGE_NAME = 'dispatched_notifications'
export const NOTIFICATIONS_SINKS_PAGE_NAME = 'notifications_sinks'
export const NOTIFICATIONS_TEMPLATES_PAGE_NAME = 'notifications_templates'
export const NOTIFICATIONS_GLOBAL_SINKS_PAGE_NAME = 'notifications_global_sinks'

export const USE_CASE_NOTIFICATIONS_PAGE_NAME = 'use_case_notifications'
export const USE_CASE_NOTIFICATIONS_DISPATCHED_PAGE_NAME = 'use_case_notifications_dispatched'
export const USE_CASE_NOTIFICATIONS_ROUTINGS_PAGE_NAME = 'use_case_notifications_routings'
export const USE_CASE_NOTIFICATIONS_SINKS_PAGE_NAME = 'use_case_notifications_sinks'

export const ARTICLE_ALLOCATION_SETUP_PAGE_NAME = 'aa_setup'
export const ARTICLE_ALLOCATION_RESULTS_PAGE_NAME = 'aa_results'
export const ARTICLE_ALLOCATION_EXPORT_PAGE_NAME = 'aa_export'
export const ARTICLE_ALLOCATION_ANALYZE_PAGE_NAME = 'aa_analyze'

export const REPLENISHMENT_SETUP_PAGE_NAME = 'rp_setup'
export const REPLENISHMENT_RESULTS_PAGE_NAME = 'rp_results'
export const REPLENISHMENT_EXPORT_PAGE_NAME = 'rp_export'
export const REPLENISHMENT_ANALYZE_PAGE_NAME = 'rp_analyze'
export const REPLENISHMENT_DISCOVER_PAGE_NAME = 'rp_discover'

export const ROOT_PATH = '/'
export const DASHBOARD_PATH = '/dashboard'
export const COMPANIES_PATH = '/companies'
export const PIPELINES_PATH = '/pipelines'
export const USERS_PATH = '/users'
export const NOT_FOUND_PATH = '/404'
export const CONNECTORS_PATH = '/connectors'
export const OAUTH_CALLBACK_PATH = '/oauth/callback'

export const NOTIFICATIONS_PATH = '/notifications'
export const DISPATCHED_NOTIFICATIONS_PATH = '/notifications/dispatched'
export const NOTIFICATIONS_SINKS_PATH = '/notifications/sinks'
export const NOTIFICATIONS_TEMPLATES_PATH = '/notifications/templates'
export const NOTIFICATIONS_GLOBAL_SINKS_PATH = '/notifications/global-sinks'
export const USE_CASE_NOTIFICATIONS_PATH = `${NOTIFICATIONS_PATH}/use-case/:usecase`
export const USE_CASE_NOTIFICATIONS_DISPATCHED_PATH = `${USE_CASE_NOTIFICATIONS_PATH}/dispatched`
export const USE_CASE_NOTIFICATIONS_ROUTINGS_PATH = `${USE_CASE_NOTIFICATIONS_PATH}/routings`
export const USE_CASE_NOTIFICATIONS_SINKS_PATH = `${USE_CASE_NOTIFICATIONS_PATH}/sinks`

export const RESULTS_PATH_KEY = 'results'
export const EXPORT_PATH_KEY = 'export'
export const ANALYZE_PATH_KEY = 'analyze'
export const DISCOVER_PATH_KEY = 'discover'

export const CONNECT_PATH_KEY = 'project'
export const TRADE_OFFS_PATH_KEY = 'trade-offs'
export const MONITOR_PATH_KEY = 'monitor'
export const MONITOR_BACKTESTING_PATH_KEY = 'backtesting'
export const MONITOR_LIVE_MONITORING_PATH_KEY = 'live-monitoring'
export const OPTIMIZE_PATH_KEY = 'optimize'
export const FILE_MANAGER_PATH_KEY = 'file-manager'
export const SNAPSHOTS_PATH_KEY = 'snapshots'
export const TRAINING_DATA_OVERVIEW_PATH_KEY = 'trainging-data'
export const INSIGHTS_ANALYZE_PATH_KEY = 'insights'

export const ARTICLE_ALLOCATION_KEY = 'aa'
export const ARTICLE_ALLOCATION_RESULTS_PATH = `/${ARTICLE_ALLOCATION_KEY}-${RESULTS_PATH_KEY}/:usecase`
export const ARTICLE_ALLOCATION_EXPORT_PATH = `/${ARTICLE_ALLOCATION_KEY}-${EXPORT_PATH_KEY}/:usecase`
export const ARTICLE_ALLOCATION_ANALYZE_PATH = `/${ARTICLE_ALLOCATION_KEY}-${ANALYZE_PATH_KEY}/:usecase`

export const REPLENISHMENT_KEY = 'rp'
export const REPLENISHMENT_RESULTS_PATH = `/${REPLENISHMENT_KEY}-${RESULTS_PATH_KEY}/:usecase`
export const REPLENISHMENT_EXPORT_PATH = `/${REPLENISHMENT_KEY}-${EXPORT_PATH_KEY}/:usecase`
export const REPLENISHMENT_ANALYZE_PATH = `/${REPLENISHMENT_KEY}-${ANALYZE_PATH_KEY}/:usecase`
export const REPLENISHMENT_DISCOVER_PATH = `/${REPLENISHMENT_KEY}-${DISCOVER_PATH_KEY}/:usecase`

export const USE_CASE_PIPELINES_PATH = `${PIPELINES_PATH}/:usecase`
export const USE_CASE_PIPELINE_OVERVIEW_PATH = `${PIPELINES_PATH}/:usecase/:pipeline`

export const ANALYZE_PATH = `/${INSIGHTS_ANALYZE_PATH_KEY}/:usecase`
export const CONNECT_PATH = `/${CONNECT_PATH_KEY}/:usecase`

export const ARTICLE_ALLOCATION_SETUP_PATH = CONNECT_PATH
export const REPLENISHMENT_SETUP_PATH = CONNECT_PATH

export const DATA_UPLOAD_PATH = `/${CONNECT_PATH_KEY}/:usecase/:identifier/:version`
export const DATA_FILE_LINK_PATH = `/${CONNECT_PATH_KEY}/:usecase/:identifier/:version/:file`

export const TRADE_OFFS_PATH = `/${TRADE_OFFS_PATH_KEY}/:usecase`
export const MONITOR_PATH = `/${MONITOR_PATH_KEY}/:usecase`
export const MONITOR_BACKTESTING_PATH = `/${MONITOR_PATH_KEY}/:usecase/${MONITOR_BACKTESTING_PATH_KEY}`
export const MONITOR_LIVE_MONITORING_PATH = `/${MONITOR_PATH_KEY}/:usecase/${MONITOR_LIVE_MONITORING_PATH_KEY}`
export const OPTIMIZE_PATH = `/${OPTIMIZE_PATH_KEY}/:usecase`
export const OPTIMIZE_DRILL_DOWN_PATH = `/${OPTIMIZE_PATH_KEY}/:usecase/region/:region/sku/:sku`
export const EXPORT_PATH = `/${EXPORT_PATH_KEY}/:usecase`
export const FILE_MANAGER_PATH = `/${FILE_MANAGER_PATH_KEY}/:usecase`
export const FILE_MANAGER_FOLDER_PATH = `/${FILE_MANAGER_PATH_KEY}/:usecase/folder/*`
export const SNAPSHOTS_PATH = `/${SNAPSHOTS_PATH_KEY}/:usecase`
export const SNAPSHOT_DETAILS_PATH = `/${SNAPSHOTS_PATH_KEY}/:usecase/:snapshot`
export const TRAINING_DATA_OVERVIEW_PATH = `/${TRAINING_DATA_OVERVIEW_PATH_KEY}/:usecase`

export const ROUTES: {
  [key: string]: string
} = {
  /* global */
  [ROOT_PAGE_NAME]: ROOT_PATH,
  [DASHBOARD_PAGE_NAME]: DASHBOARD_PATH,
  [COMPANIES_PAGE_NAME]: COMPANIES_PATH,
  [PIPELINES_PAGE_NAME]: PIPELINES_PATH,
  [USERS_PAGE_NAME]: USERS_PATH,
  [CONNECTORS_PAGE_NAME]: CONNECTORS_PATH,
  [OAUTH_CALLBACK_PAGE_NAME]: OAUTH_CALLBACK_PATH,
  [NOT_FOUND_PAGE_NAME]: NOT_FOUND_PATH,
  [NOTIFICATIONS_PAGE_NAME]: NOTIFICATIONS_PATH,
  [DISPATCHED_NOTIFICATIONS_PAGE_NAME]: DISPATCHED_NOTIFICATIONS_PATH,
  [NOTIFICATIONS_SINKS_PAGE_NAME]: NOTIFICATIONS_SINKS_PATH,
  [NOTIFICATIONS_TEMPLATES_PAGE_NAME]: NOTIFICATIONS_TEMPLATES_PATH,
  [NOTIFICATIONS_GLOBAL_SINKS_PAGE_NAME]: NOTIFICATIONS_GLOBAL_SINKS_PATH,
  /* common use-cases */
  [DATA_UPLOAD_PAGE_NAME]: DATA_UPLOAD_PATH,
  [DATA_FILE_LINK_PAGE_NAME]: DATA_FILE_LINK_PATH,
  [USE_CASE_PIPELINES_PAGE_NAME]: USE_CASE_PIPELINES_PATH,
  [PIPELINE_OVERVIEW_PAGE_NAME]: USE_CASE_PIPELINE_OVERVIEW_PATH,
  [FILE_MANAGER_PAGE_NAME]: FILE_MANAGER_PATH,
  [FILE_MANAGER_FOLDER_PAGE_NAME]: FILE_MANAGER_FOLDER_PATH,
  [SNAPSHOTS_PAGE_NAME]: SNAPSHOTS_PATH,
  [SNAPSHOT_DETAILS_PAGE_NAME]: SNAPSHOT_DETAILS_PATH,
  [TRAINING_DATA_OVERVIEW_PAGE_NAME]: TRAINING_DATA_OVERVIEW_PATH,
  [USE_CASE_NOTIFICATIONS_PAGE_NAME]: USE_CASE_NOTIFICATIONS_PATH,
  [USE_CASE_NOTIFICATIONS_DISPATCHED_PAGE_NAME]: USE_CASE_NOTIFICATIONS_DISPATCHED_PATH,
  [USE_CASE_NOTIFICATIONS_ROUTINGS_PAGE_NAME]: USE_CASE_NOTIFICATIONS_ROUTINGS_PATH,
  [USE_CASE_NOTIFICATIONS_SINKS_PAGE_NAME]: USE_CASE_NOTIFICATIONS_SINKS_PATH,

  /* article allocation use-cases */
  [ARTICLE_ALLOCATION_SETUP_PAGE_NAME]: ARTICLE_ALLOCATION_SETUP_PATH,
  [ARTICLE_ALLOCATION_EXPORT_PAGE_NAME]: ARTICLE_ALLOCATION_EXPORT_PATH,
  [ARTICLE_ALLOCATION_RESULTS_PAGE_NAME]: ARTICLE_ALLOCATION_RESULTS_PATH,
  [ARTICLE_ALLOCATION_ANALYZE_PAGE_NAME]: ARTICLE_ALLOCATION_ANALYZE_PATH,

  /* replenishment use-cases */
  [REPLENISHMENT_SETUP_PAGE_NAME]: REPLENISHMENT_SETUP_PATH,
  [REPLENISHMENT_EXPORT_PAGE_NAME]: REPLENISHMENT_EXPORT_PATH,
  [REPLENISHMENT_RESULTS_PAGE_NAME]: REPLENISHMENT_RESULTS_PATH,
  [REPLENISHMENT_ANALYZE_PAGE_NAME]: REPLENISHMENT_ANALYZE_PATH,
  [REPLENISHMENT_DISCOVER_PAGE_NAME]: REPLENISHMENT_DISCOVER_PATH,

  /* demand forecast use-cases */
  [CONNECT_PAGE_NAME]: CONNECT_PATH,
  [ANALYZE_PAGE_NAME]: ANALYZE_PATH,
  [MONITOR_PAGE_NAME]: MONITOR_PATH,
  [MONITOR_BACKTESTING_PAGE_NAME]: MONITOR_BACKTESTING_PATH,
  [MONITOR_LIVE_MONITORING_PAGE_NAME]: MONITOR_LIVE_MONITORING_PATH,
  [EXPORT_PAGE_NAME]: EXPORT_PATH,
  [TRADE_OFFS_PAGE_NAME]: TRADE_OFFS_PATH,
  [OPTIMIZE_PAGE_NAME]: OPTIMIZE_PATH,
  [OPTIMIZE_DRILL_DOWN_PAGE_NAME]: OPTIMIZE_DRILL_DOWN_PATH,
}
