import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { foldsToChartAnnotations } from '@utils/monitor.utils'
import { getEventsToExclude, getEventsVisibility } from '@redux/modules/insights/insights.selectors'
import { requestEventsVisibilityChangeAction } from '@redux/modules/insights/insights.actions'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { MANAGE_EVENTS_MODAL_NAME } from '@constants/modals.constants'
import {
  DEFAULT_BACKTEST_ABS_DEVIATION_FIELD, DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX,
  DEFAULT_BACKTEST_PREDICTION_FIELD, DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX,
  DEFAULT_BACKTEST_TARGET_FIELD,
} from '@constants/insights.constants'

import {
  getBacktestingAvailableFolds, getBacktestingChart,
  getBacktestingTable, isFetchingBacktestingChart,
} from '@redux/modules/monitor/monitor.selectors'

import InsightsChartComponent from '@components/insights/InsightsChart'

const BacktestingChartContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const backtestingTable = useSelector((state) => getBacktestingTable(state))
  const backtestingChart = useSelector((state) => getBacktestingChart(state))
  const backtestingAvailableFolds = useSelector((state) => getBacktestingAvailableFolds(state))
  const isFetching = useSelector((state) => isFetchingBacktestingChart(state))
  const eventsVisibility = useSelector((state) => getEventsVisibility(state))
  const eventsToExclude = useSelector((state) => getEventsToExclude(state))

  const handleShowEventsToggle = useCallback(() => {
    dispatch(
      requestEventsVisibilityChangeAction(!eventsVisibility),
    )
  }, [dispatch, eventsVisibility])

  const handleManageEvents = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(MANAGE_EVENTS_MODAL_NAME),
    )
  }, [dispatch])

  const chartSettingsActions: Common.ContextMenuAction[] = [{
    name: 'showEvents',
    type: 'toggle',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.showEvents' }),
    disabled: false,
    checked: eventsVisibility,
    handler: handleShowEventsToggle,
  }, {
    name: 'manageEvents',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.manageEvents' }),
    disabled: false,
    handler: handleManageEvents,
  }]

  const {
    gridState,
  } = backtestingTable

  const {
    dataset,
    lines,
    targetName,
    targetUnit,
    events,
  } = backtestingChart

  const annotations: Insights.BaseChartAnnotationItem[] = useMemo(() => {
    if (!gridState) {
      return []
    }

    return foldsToChartAnnotations(intl, gridState.backtestingFold, backtestingAvailableFolds)
  }, [intl, gridState, backtestingAvailableFolds])

  return (
    <Box
      data-testid={BacktestingChartContainer.name}
    >
      <InsightsChartComponent
        dataset={dataset}
        lines={lines}
        annotations={annotations}
        events={events}
        gridState={gridState}
        targetName={targetName}
        targetUnit={targetUnit}
        isFetching={isFetching}
        eventsVisibility={eventsVisibility}
        eventsToExclude={eventsToExclude}
        chartSettingsActions={chartSettingsActions}
        lastYearVisibility={false}
        groupingModeAttributes={{
          predictionPayloadKeyPrefix: DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX,
          absDeviationPayloadKeyPrefix: DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX,
        }}
        regularModeAttributes={{
          targetPayloadKey: DEFAULT_BACKTEST_TARGET_FIELD,
          predictionPayloadKey: DEFAULT_BACKTEST_PREDICTION_FIELD,
          absDeviationPayloadKey: DEFAULT_BACKTEST_ABS_DEVIATION_FIELD,
        }}
      />
    </Box>
  )
}

export default BacktestingChartContainer
