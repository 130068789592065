import React, { useMemo } from 'react'

import { useIntl } from 'react-intl'

import {
  useDropzone, Accept,
  DropEvent, FileRejection,
} from 'react-dropzone'

import {
  Box, SxProps,
  Theme, Typography,
  useTheme,
} from '@mui/material'

import DropzoneProgress from '@base/utils/DropzoneProgress'
import UploadSmallIcon from '@icons/uploadSmall.icon'

import { DEFAULT_BORDER_RADIUS, DEFAULT_BUTTON_TRANSITION, DEFAULT_PADDING } from '@constants/ui.constants'

export interface DropzoneComponentProps {
  /**
   * The accept prop is used to define the types of files that are accepted by the dropzone.
   */
  accept?: Accept,
  /**
   * The disabled prop is used to disable the dropzone.
   */
  disabled?: boolean,
  /**
   * If true, the dropzone will accept multiple files.
   */
  multiple?: boolean,
  /**
   * The activeKey prop is used to define the key for the active message.
   */
  activeKey?: string,
  /**
   * The inactiveKey prop is used to define the key for the inactive message.
   */
  inactiveKey?: string,
  /**
   * If true, the dropzone will show a loading spinner.
   */
  isUploading?: boolean,
  /**
   * Custom height of the dropzone
   */
  height?: string,
  /**
   * The sx prop is used to define the custom styles of the dropzone
   */
  sx?: SxProps<Theme>,
  /**
   * The onDrop prop is used to handle the drop event.
   *
   * @param acceptedFiles List of accepted files
   * @param fileRejections List of rejected files
   * @param event Drop event
   */
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
}

export interface DropzoneThemeProps {
  isDragAccept?: boolean,
  isDragReject?: boolean,
  isFocused?: boolean,
  isDragActive?: boolean,
  isUploading?: boolean,
  disabled?: boolean,
}

export const getStyles = (props: DropzoneThemeProps, theme: Theme): SxProps<Theme> => {
  if (props.disabled) {
    return {
      cursor: 'not-allowed',
      border: `1px solid ${theme.palette.new.business_black_20}`,
    }
  }

  if (props.isUploading) {
    return {
      cursor: 'progress',
      border: `1px solid ${theme.palette.new.business_black_20}`,
    }
  }

  if (props.isDragReject && props.isDragActive) {
    return {
      border: `1px dashed ${theme.palette.new.rebellious_red}`,
    }
  }

  if (props.isDragActive) {
    return {
      border: `1px dashed ${theme.palette.new.business_black_20}`,
    }
  }

  return {
    border: `1px solid ${theme.palette.new.business_black_20}`,
    '&:hover': {
      borderColor: theme.palette.new.business_black,
    },
    '&:active': {
      backgroundColor: theme.palette.new.smokey_silver,
    },
  }
}

const DropzoneComponent: React.FC<DropzoneComponentProps> = ({
  sx,
  accept,
  disabled = false,
  onDrop = () => {},
  multiple = false,
  activeKey = 'common.dropzone.active',
  inactiveKey = 'common.dropzone.inactive',
  isUploading,
  height = '100px',
}) => {
  const theme = useTheme()
  const intl = useIntl()

  const onDropRejected = (fileRejections: FileRejection[], event: DropEvent) => {
    // eslint-disable-next-line no-console
    console.error('Drop rejected:', {
      fileRejections,
      event,
    })
  }

  const onError = (err: Error) => {
    // eslint-disable-next-line no-console
    console.error('Error:', err)
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
  } = useDropzone({
    onDrop,
    onError,
    onDropRejected,
    multiple,
    disabled,
    accept,
  })

  const styles = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
    backgroundColor: theme.palette.new.white,
    minHeight: height,
    transition: DEFAULT_BUTTON_TRANSITION,
    overflow: 'hidden',
    ...getStyles({
      isDragActive,
      isFocused,
      isDragAccept,
      isUploading,
      disabled,
    }, theme),
    ...sx,
  }

  const content = useMemo(() => {
    if (isUploading) {
      return (
        <DropzoneProgress
          isUploading={isUploading}
          height={`calc(${height} - 2px)`}
        />
      )
    }

    if (isDragActive) {
      return (
        <Typography
          variant='button'
          color={theme.palette.new.black}
        >
          {intl.formatMessage({ id: activeKey })}
        </Typography>
      )
    }

    return (
      <Typography
        variant='button'
        color={theme.palette.new.black}
        gap={DEFAULT_PADDING.SMALL}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <UploadSmallIcon />

        {intl.formatMessage({ id: inactiveKey })}
      </Typography>
    )
  }, [
    isUploading, isDragActive,
    activeKey, inactiveKey,
    intl, height, theme,
  ])

  return (
    <Box
      data-testid={DropzoneComponent.name}
      sx={styles as any}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {content}
    </Box>
  )
}

export default DropzoneComponent
