import React from 'react'

const NotificationIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={NotificationIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M7.07145 15.8529C7.18259 16.2797 7.43223 16.6577 7.78124 16.9275C8.13025 17.1973 8.5589 17.3436 9.00003 17.3436C9.44115 17.3436 9.8698 17.1973 10.2188 16.9275C10.5678 16.6577 10.8175 16.2797 10.9286 15.8529M9.00003 0.642853C10.4629 0.642853 11.8658 1.22397 12.9002 2.25837C13.9346 3.29277 14.5157 4.69571 14.5157 6.15857C14.5157 12.2914 16.7529 13.5 17.3572 13.5H0.642883C1.26003 13.5 3.48431 12.2786 3.48431 6.15857C3.48431 4.69571 4.06543 3.29277 5.09983 2.25837C6.13422 1.22397 7.53717 0.642853 9.00003 0.642853Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default NotificationIcon
