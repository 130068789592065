import React, { useLayoutEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Card } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { useDispatch, useSelector } from '@redux/hooks'
import { useRouteMatch } from 'react-router-dom'

import {
  generateBooleanColumnDefinition,
  generateChipColumnDefinition,
  generateCopyButtonColumnDefinition,
  generateDateTimeColumnDefinition,
} from '@utils/data-grid-cells.utils'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import useGridInitialState from '@hooks/useGridInitialState.hook'
import NotificationsSendModalContainer from '@containers/modals/notifications/notifications-send-modal'

import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { requestDispatchedNotificationsAction } from '@redux/modules/notifications/notifications.actions'
import { isFetchingDispatchedNotifications, getDispatchedNotifications } from '@redux/modules/notifications/notifications.selectors'
import {
  mapNotificationSeverityToChipBgColor,
  mapNotificationSeverityToChipColor,
  mapNotificationStatusToChipBgColor,
  mapNotificationStatusToChipColor,
} from '@utils/notifications.utils'

export interface DispatchedNotificationsContainerProps {}

const DispatchedNotificationsContainer: React.FC<DispatchedNotificationsContainerProps> = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const selectedCompanyId = useSelector(getSelectedCompanyId)
  const isFetching = useSelector(isFetchingDispatchedNotifications)
  const list = useSelector(getDispatchedNotifications)
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const tableId = getDataGridId(DATA_GRIDS.DISPATCHED_NOTIFICATIONS_TABLE, 1)
  const initialState = useGridInitialState(tableId, {
    sorting: {
      sortModel: [{ field: 'dispatchedAt', sort: 'desc' }],
    },
  })

  useLayoutEffect(() => {
    dispatch(
      requestDispatchedNotificationsAction({ companyId: selectedCompanyId, useCaseId: usecase, page: 1 }),
    )
  }, [dispatch, usecase, selectedCompanyId])

  const columns = useMemo<GridColDef[]>(() => {
    const columnsList = [
      generateCopyButtonColumnDefinition({
        field: 'id',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.id' }),
        gridColDefOverrides: {
          minWidth: 100,
        },
      }),
      generateChipColumnDefinition({
        intl,
        field: 'status',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.status' }),
        uppercase: true,
        getColorByValue: mapNotificationStatusToChipColor,
        getBgColorByValue: mapNotificationStatusToChipBgColor,
        gridColDefOverrides: {
          minWidth: 100,
        },
      }),
      generateChipColumnDefinition({
        intl,
        field: 'platform',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.platform' }),
        uppercase: true,
        naLabel: intl.formatMessage({ id: 'common.na' }),
        gridColDefOverrides: {
          minWidth: 100,
          valueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.sink?.platform
          },
          groupingValueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.sink?.platform
          },
        },
      }),
      generateChipColumnDefinition({
        intl,
        field: 'type',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.type' }),
        uppercase: true,
        gridColDefOverrides: {
          minWidth: 100,
          valueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.notification?.type
          },
          groupingValueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.notification?.type
          },
        },
      }),
      generateChipColumnDefinition({
        intl,
        field: 'severity',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.severity' }),
        uppercase: true,
        getBgColorByValue: mapNotificationSeverityToChipBgColor,
        getColorByValue: mapNotificationSeverityToChipColor,
        gridColDefOverrides: {
          minWidth: 100,
          valueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.notification?.severity
          },
          groupingValueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.notification?.severity
          },
        },
      }),
      generateBooleanColumnDefinition({
        intl,
        field: 'dismissed',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.dismissed' }),
        activeLabel: intl.formatMessage({ id: 'common.yes' }),
        inactiveLabel: intl.formatMessage({ id: 'common.no' }),
        gridColDefOverrides: {
          minWidth: 100,
        },
      }),
      generateBooleanColumnDefinition({
        intl,
        field: 'read',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.read' }),
        activeLabel: intl.formatMessage({ id: 'common.yes' }),
        inactiveLabel: intl.formatMessage({ id: 'common.no' }),
        gridColDefOverrides: {
          minWidth: 100,
        },
      }),
      generateCopyButtonColumnDefinition({
        field: 'sinkName',
        naLabel: intl.formatMessage({ id: 'common.na' }),
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.sinkName' }),
        gridColDefOverrides: {
          valueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.sink?.name
          },
          groupingValueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.sink?.name
          },
        },
      }),
      generateCopyButtonColumnDefinition({
        field: 'sinkId',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.sinkId' }),
        naLabel: intl.formatMessage({ id: 'common.na' }),
        gridColDefOverrides: {
          valueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.sink?.id
          },
          groupingValueGetter: (params: GridCellParams, row: Notifications.DispatchedNotificationItem) => {
            return row?.sink?.id
          },
        },
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'dispatchedAt',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.dispatchedAt' }),
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'updatedAt',
        headerName: intl.formatMessage({ id: 'notifications.dispatched.fields.updatedAt' }),
      }),
    ]

    return columnsList
  }, [
    intl,
  ])

  return (
    <Card
      data-testid={DispatchedNotificationsContainer.name}
      elevation={0}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.DISPATCHED_NOTIFICATIONS_TABLE}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={(row) => row.id}
        initialState={initialState}
      />

      <NotificationsSendModalContainer />
    </Card>
  )
}

export default DispatchedNotificationsContainer
