import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'

import {
  trackEvent,
  TRACKING_ACTIONS,
  TrackingEventNameProps,
} from '@utils/tracking.utils'

import { DEFAULT_BUTTON_TRANSITION, DEFAULT_PADDING, PARETOS_GRADIENT } from '@constants/ui.constants'

export interface TabComponentProps {
  /**
   * Label to be displayed
   */
  label: string,
  /**
   * If 'true' the tab is active
   */
  active?: boolean,
  /**
   * If 'true' the tab is disabled
   */
  disabled?: boolean,
  /**
   * Handle click event
   */
  onClick: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent) => void,
  /**
   * Tracking props to be passed to the tracking event
   */
  trackingProps?: Partial<TrackingEventNameProps>
}

const TabComponent: React.FC<TabComponentProps> = ({
  label,
  active,
  disabled,
  onClick,
  trackingProps,
}) => {
  const theme = useTheme()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const handleClick = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent) => {
    if (disabled || active) {
      return
    }

    trackEvent({
      componentName: 'tabBar',
      actionName: TRACKING_ACTIONS.CLICK,
      ...trackingProps,
    }, {
      label,
      router: params,
    })

    onClick(e)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()

      handleClick(event)
    }
  }

  return (
    <Box
      data-testid={TabComponent.name}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role='tab'
      tabIndex={(disabled || active) ? -1 : 0}
      sx={{
        outline: 'none',
        cursor: disabled ? 'not-allowed' : 'pointer',
        pointerEvents: (disabled || active) ? 'none' : 'auto',
        p: '5px 2px',

        '.tabItem': {
          background: theme.palette.new.transparent,
          ...(active ? {
            background: PARETOS_GRADIENT,
          } : {}),
          ...(disabled ? {
            background: theme.palette.new.smokey_silver,
          } : {}),
        },
        '&:hover > .tabItem': {
          background: theme.palette.new.business_black_10,
        },
        '&:active > .tabItem': {
          background: theme.palette.new.business_black_20,
        },
        '&:focus-visible > .tabItem': {
          background: active ? PARETOS_GRADIENT : theme.palette.new.business_black_10,
        },
      }}
    >
      <Box
        className='tabItem'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content',
          height: '26px',
          borderRadius: '36px',
          transition: DEFAULT_BUTTON_TRANSITION,
          px: DEFAULT_PADDING.MEDIUM,
        }}
      >
        <Typography
          variant='button'
          noWrap={true}
          sx={{
            color: theme.palette.new.business_black,
            ...(active ? {
              color: theme.palette.new.white,
            } : {}),
            ...(disabled ? {
              color: theme.palette.new.business_black_20,
            } : {}),
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

export default TabComponent
