import React from 'react'
import classNames from 'classnames'

import { FormControl, FormControlLabel, styled } from '@mui/material'
import {
  DEFAULT_BORDER_RADIUS, CHECKBOX_BASE_ICONS_HOVER_STYLES,
  DEFAULT_BUTTON_FONT_SIZE, DEFAULT_PADDING,
} from '@constants/ui.constants'

import CheckboxBaseComponent, { CheckboxBaseComponentProps } from '@base/forms/CheckboxBase/CheckboxBase.component'
import IconButtonComponent, { IconButtonComponentProps } from '@base/buttons/IconButton/IconButton.component'

export const createTestId = (name: string) => {
  return `${name || 'checkbox'}_field`
}

export const CheckboxFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '36px',
  width: '100%',
  borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
  border: '1px solid',
  borderColor: theme.palette.new.business_black_20,
  paddingTop: 0,
  paddingBottom: 0,

  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),

  '&.useLabelPlaceholder': {
    marginTop: '19px',
  },

  '&:hover:not(.disabled)': {
    backgroundColor: theme.palette.new.smokey_silver,
    borderColor: theme.palette.new.business_black_20,

    ...CHECKBOX_BASE_ICONS_HOVER_STYLES,
  },
  '&:focus-within': {
    backgroundColor: theme.palette.new.smokey_silver,
    borderColor: theme.palette.new.business_black_20,
    outline: 'none',
    ...CHECKBOX_BASE_ICONS_HOVER_STYLES,
  },

  '&.disabled': {
    backgroundColor: theme.palette.new.smokey_silver,
    '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
      color: theme.palette.new.business_black,
      opacity: 0.2,
    },
  },
}))

export const CheckboxFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  gap: DEFAULT_PADDING.SMALL,
  padding: `0px ${DEFAULT_PADDING.MEDIUM}`,
  margin: 0,
  height: '100%',
  whiteSpace: 'nowrap',
  width: '100%',
  justifyContent: 'flex-start',

  '& .MuiFormControlLabel-label': {
    fontSize: DEFAULT_BUTTON_FONT_SIZE,
  },
}))

export interface CheckboxFieldComponentProps extends CheckboxBaseComponentProps {
  /**
   * The name of the checkbox
   */
  name: string,
  /**
   * Label of the checkbox
   */
  label: string | React.ReactNode,
  /**
   * If true, the label placeholder will be used
   * @default false
   */
  useLabelPlaceholder?: boolean,
  /**
   * Button props
   */
  buttonProps?: IconButtonComponentProps
}

const CheckboxFieldComponent: React.FC<CheckboxFieldComponentProps> = ({
  label,
  name,
  disabled,
  useLabelPlaceholder = false,
  sx,
  buttonProps,
  ...props
}) => {
  const testId = createTestId(name)

  /**
   * In checkbox contains a button:
   *  - when the form control is clicked, the button click is triggered
   *  - when the button is clicked, the button click is triggered
   *  - when the checkbox is clicked, the checkbox click is triggered
   */
  const onClick = buttonProps && buttonProps.onClick ? (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      ((e.target as HTMLInputElement).classList.contains('MuiFormControlLabel-root')) ||
      ((e.target as HTMLInputElement).classList.contains('MuiFormControlLabel-label'))
    ) {
      e.stopPropagation()
      e.preventDefault()

      buttonProps.onClick?.(e as any)
    }
  } : undefined

  return (
    <CheckboxFormControl
      className={classNames({
        useLabelPlaceholder,
        disabled,
      })}
      sx={sx}
      onClick={onClick}
    >
      <CheckboxFormControlLabel
        data-testid={testId}
        labelPlacement='end'
        label={label}
        control={(
          <CheckboxBaseComponent
            disabled={disabled}
            name={name}
            {...props}
          />
        )}
      />

      {
        buttonProps ? (
          <IconButtonComponent
            {...buttonProps}
            color='tertiary'
          />
        ) : (
          null
        )
      }
    </CheckboxFormControl>
  )
}

export default CheckboxFieldComponent
