import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, match as Match } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { injectIntl, IntlShape } from 'react-intl'
import { History } from 'history'
import { Typography, Box } from '@mui/material'

import TopBarAutocompleteComponent from '@base/topbar/TopBarAutocomplete'
import { DASHBOARD_PATH } from '@constants/routes.constants'
import { fetchAllUseCasesAction } from '@redux/modules/use-case/use-case.actions'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { changeCompanyIdAction, requestCompaniesListAction } from '@redux/modules/customer/customer.actions'
import { ChangeCompanyIdActionPayload } from '@redux/modules/customer/customer.types'
import { FetchAllUseCasesActionActionPayload } from '@redux/modules/use-case/use-case.types'
import { PARETOS_COMPANY_ID } from '@constants/env-replacements.constants'
import { State } from '@redux/modules/types'
import { TRACKING_ACTIONS, TRACKING_MODULES, trackEvent } from '@utils/tracking.utils'
import { DEFAULT_PADDING } from '@constants/ui.constants'

import {
  getIsAdmin,
  isFetching as isFetchingList,
  getSortedCompaniesList,
  getSelectedCompanyId,
  getCompanyDetails,
} from '@redux/modules/customer/customer.selectors'

export interface CompanySelectorContainerProps {
  isAdmin: boolean
  selectedCompanyId: string
  match: Match<Common.RouterMatch>
  companiesList: Customer.CompanyItem[]
  intl: IntlShape
  isFetching: boolean
  history: History
  companyDetails: Customer.CompanyItem

  changeCompanyId(payload: ChangeCompanyIdActionPayload): any
  requestCompaniesList(): any
  fetchAllUseCases(payload: FetchAllUseCasesActionActionPayload): any
}

export interface CompanySelectorContainerState {
  value: null | Customer.CompanyItem,
}

class CompanySelectorContainer extends React.Component<CompanySelectorContainerProps, CompanySelectorContainerState> {
  static defaultProps: any

  static propTypes: any

  constructor(props: CompanySelectorContainerProps) {
    super(props)

    const selectedCompany = props.companiesList.find((item) => String(item.companyId) === String(props.selectedCompanyId))

    this.state = selectedCompany ? {
      value: selectedCompany,
    } : {
      value: null,
    }
  }

  componentDidMount() {
    const { isAdmin, requestCompaniesList } = this.props

    if (isAdmin) {
      requestCompaniesList()
    }
  }

  componentDidUpdate(prevProps: CompanySelectorContainerProps) {
    const {
      isAdmin,
      companiesList,
      changeCompanyId,
      selectedCompanyId,
      fetchAllUseCases,
      match: { path },
    } = this.props

    const {
      companiesList: prevCompaniesList,
      selectedCompanyId: prevSelectedCompanyId,
    } = prevProps

    const {
      value: prevSelectedCompany,
    } = this.state

    if (
      isAdmin &&
      companiesList.length &&
      ((companiesList.length !== prevCompaniesList.length) || (selectedCompanyId !== prevSelectedCompany?.companyId))
    ) {
      const selectedCompany = companiesList.find((item) => String(item.companyId) === String(selectedCompanyId))

      if (selectedCompany && selectedCompany?.companyId !== prevSelectedCompany?.companyId) {
        this.setState({
          value: selectedCompany!,
        }, () => {
          if (path !== DASHBOARD_PATH) {
            /**
             * If we are on the dashboard page, we don't need to fetch use cases.
             * They are already fetched on the page load. (dashboard.container.tsx)
             */
            fetchAllUseCases({})
          } else if (!prevSelectedCompany && (prevSelectedCompanyId !== selectedCompanyId) && (selectedCompanyId === PARETOS_COMPANY_ID)) {
            /**
             * Special case for Admins. When selected company is not found in the list (e.g. after removal)
             * we need to change the company to the default one (Paretos).
             */
            fetchAllUseCases({
              companyId: PARETOS_COMPANY_ID,
              includeForecast: true,
            })
          }
        })
      } else if (!selectedCompany && (selectedCompanyId !== PARETOS_COMPANY_ID)) {
        /**
         * Special case for Admins. When selected company is not found in the list (e.g. after removal)
         * we need to change the company to the default one (Paretos).
         */
        changeCompanyId({
          companyId: PARETOS_COMPANY_ID,
          saveToLs: true,
        })
      }
    }
  }

  getSelectedCompanyName = () => {
    const { intl, isFetching } = this.props
    const { value } = this.state

    if (value && value.name) {
      return value.name
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'common.layout.header.loading' })
    }

    return intl.formatMessage({ id: 'common.layout.header.company.title' })
  }

  handleChange = (e: React.SyntheticEvent, value: Customer.CompanyItem) => {
    const {
      history,
      changeCompanyId,
      fetchAllUseCases,
      match: { params: { usecase } },
    } = this.props

    if (value) {
      trackEvent({
        moduleName: TRACKING_MODULES.TOP_BAR,
        componentName: 'companySelectorListItem',
        actionName: TRACKING_ACTIONS.CLICK,
      }, {
        companyId: value.companyId,
      })

      this.setState({
        value,
      }, () => {
        changeCompanyId({
          companyId: value.companyId,
          saveToLs: true,
        })

        fetchAllUseCases({
          companyId: value.companyId,
          includeForecast: true,
        })

        if (usecase) {
          history.push('/')
        }
      })
    }
  }

  render() {
    const {
      companiesList,
      intl,
      isAdmin,
      companyDetails,
    } = this.props

    const { value } = this.state

    const companyName = companyDetails?.name || ''

    if (!isAdmin) {
      return (
        <Box
          data-testid={CompanySelectorContainer.name}
          sx={{
            marginLeft: DEFAULT_PADDING.MEDIUM,
            marginRight: DEFAULT_PADDING.MEDIUM,
            maxWidth: '200px',
          }}
        >
          <Typography variant='body1' noWrap={true}>
            {companyName || intl.formatMessage({ id: 'common.layout.header.loading' })}
          </Typography>
        </Box>
      )
    }

    const selectedCompanyName = this.getSelectedCompanyName()

    return (
      <TopBarAutocompleteComponent
        name='companySelectorButton'
        dataTestId={CompanySelectorContainer.name}
        buttonAriaLabel={intl.formatMessage({ id: 'common.layout.header.companySelectorAriaLabel' }, { name: selectedCompanyName })}
        buttonLabel={selectedCompanyName}
        inputPlaceholder={intl.formatMessage({ id: 'common.layout.header.search.placeholder_company' })}
        noOptionsText={intl.formatMessage({ id: 'common.layout.header.search.not_found_company' })}
        isOptionEqualToValue={(option: Customer.CompanyItem, selectedValue: Customer.CompanyItem) => {
          return option.companyId === selectedValue?.companyId
        }}
        getOptionLabel={(option) => {
          return option?.name || ''
        }}
        onChange={this.handleChange}
        options={companiesList}
        value={value}
      />
    )
  }
}

CompanySelectorContainer.propTypes = {
  companiesList: PropTypes.array,
  requestCompaniesList: PropTypes.func,
  intl: PropTypes.object,
  isFetching: PropTypes.bool,
  changeCompanyId: PropTypes.func,
  history: PropTypes.object,
  fetchAllUseCases: PropTypes.func,
  selectedCompanyId: PropTypes.string,
  match: PropTypes.object,
  isAdmin: PropTypes.bool,
  companyDetails: PropTypes.object,
}

CompanySelectorContainer.defaultProps = {
  companiesList: [],
}

export const mapStateToProps = (state: State) => {
  return {
    companiesList: getSortedCompaniesList(state),
    isFetching: isFetchingList(state),
    modalPageName: getOpenedModal(state),
    isAdmin: getIsAdmin(state),
    selectedCompanyId: getSelectedCompanyId(state),
    companyDetails: getCompanyDetails(state),
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const requestCompaniesList = bindActionCreators(requestCompaniesListAction, dispatch)
  const changeCompanyId = bindActionCreators(changeCompanyIdAction, dispatch)
  const fetchAllUseCases = bindActionCreators(fetchAllUseCasesAction, dispatch)

  return {
    requestCompaniesList,
    changeCompanyId,
    fetchAllUseCases,
  }
}

// @ts-ignore-next-line
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(CompanySelectorContainer)))
