import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

const validation = (intl: IntlShape) => {
  const shape = Yup.object().shape({
    internalConfig: Yup.array()
      .of(
        Yup.object().shape({
          key: Yup.string().required(),
          value: Yup.string()
            .required()
            .when('key', {
              is: (key: string) => !!key,
              then: (schema) => schema.min(1, 'Value cannot be empty'),
            }),
        }),
      )
      .test('unique-keys', 'Duplicate keys are not allowed', (value) => {
        if (!value) return true

        const keys = value.map((item) => item.key)
        const uniqueKeys = new Set(keys)

        return keys.length === uniqueKeys.size
      }),
  })

  return shape
}

export default validation
