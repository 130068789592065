import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik, FormikProvider, FieldArray } from 'formik'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { isSubmittingNotificationsTemplates } from '@redux/modules/notifications/notifications.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { NOTIFICATIONS_TEMPLATES_MODAL_NAME } from '@constants/modals.constants'
import { arrayBasedConfigToMapBasedConfig, mapBasedConfigToArrayBasedConfig } from '@utils/notifications.utils'

import KeyValueMapField from '@base/forms/KeyValueMapField'
import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import AutocompleteChipsFieldComponent from '@base/autocomplete/AutocompleteChipsField'

import { createNotificationsTemplateAction, updateNotificationsTemplateAction } from '@redux/modules/notifications/notifications.actions'

import {
  NOTIFICATIONS_PLATFORMS,
  NOTIFICATIONS_TYPES_LIST,
  NOTIFICATIONS_PLATFORMS_LIST,
} from '@constants/notifications.constants'

import validations from './NotificationsTemplatesModal.validations'

export interface NotificationsTemplatesModalDetails extends Common.ModalDetails {
  template: Notifications.NotificationTemplateItem
}

export interface InternalNotificationTemplateItem extends Notifications.NotificationTemplateItem {
  internalConfig: {
    key: string
    value: string
  }[]
}

const NotificationsTemplatesModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const isSubmitting = useSelector(isSubmittingNotificationsTemplates)
  const modalPageName = useSelector(getOpenedModal)

  const { template } = useSelector((state) => getModalDetails<NotificationsTemplatesModalDetails>(state))

  const open = modalPageName === NOTIFICATIONS_TEMPLATES_MODAL_NAME
  const isEdit = Boolean(template?.id)

  const initialValues = useMemo(() => {
    if (template) {
      return {
        ...template,
        internalConfig: mapBasedConfigToArrayBasedConfig(template.config),
      }
    }

    return {
      id: '',
      notificationTypes: [],
      platform: NOTIFICATIONS_PLATFORMS.EMAIL,
      content: '',
      description: '',
      fields: [],
      internalConfig: [],
      config: {},
    }
  }, [template])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: InternalNotificationTemplateItem) => {
    if (isEdit) {
      dispatch(
        updateNotificationsTemplateAction({
          id: values.id!,
          notificationTypes: values.notificationTypes,
          description: values.description,
          fields: values.fields,
          config: arrayBasedConfigToMapBasedConfig(values.internalConfig),
          platform: values.platform,
        }),
      )
    } else {
      dispatch(
        createNotificationsTemplateAction({
          notificationTypes: values.notificationTypes,
          platform: values.platform,
          description: values.description || '',
          fields: values.fields,
          config: arrayBasedConfigToMapBasedConfig(values.internalConfig),
        }),
      )
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: validations(intl),
  })

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <SidePanelComponent
      open={open && isAdmin}
      title={intl.formatMessage({ id: isEdit ? 'notifications.templates.edit_template' : 'notifications.templates.add_template' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer>
                <FormLayoutItem xs={12} hidden={!isEdit}>
                  <TextFieldComponent
                    name='id'
                    touched={touched.id}
                    errors={errors.id}
                    value={values.id}
                    label={intl.formatMessage({ id: 'notifications.templates.fields.id' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <SelectFieldComponent
                    name='platform'
                    label={intl.formatMessage({ id: 'notifications.templates.fields.platform' })}
                    value={values.platform}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    options={NOTIFICATIONS_PLATFORMS_LIST}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <AutocompleteChipsFieldComponent<Notifications.NotificationType>
                    name='notificationTypes'
                    label={intl.formatMessage({ id: 'notifications.templates.fields.notificationTypes' })}
                    floatingHelp={intl.formatMessage({ id: 'notifications.templates.fields.notificationTypes.help' })}
                    placeholder={intl.formatMessage({ id: 'notifications.templates.fields.notificationTypes.placeholder' })}
                    value={values.notificationTypes}
                    options={NOTIFICATIONS_TYPES_LIST.map((item) => item.value)}
                    multiple={true}
                    getOptionLabel={(item: string) => item}
                    handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                      handleChange({
                        target: {
                          name: 'notificationTypes',
                          value: newValue,
                        },
                      })
                    }}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='description'
                    label={intl.formatMessage({ id: 'notifications.templates.fields.description' })}
                    touched={touched.description}
                    errors={errors.description}
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline={true}
                    rows={4}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <AutocompleteChipsFieldComponent<string>
                    name='fields'
                    label={intl.formatMessage({ id: 'notifications.templates.fields.fields' })}
                    floatingHelp={intl.formatMessage({ id: 'notifications.templates.fields.fields.help' })}
                    placeholder={intl.formatMessage({ id: 'notifications.templates.fields.fields.placeholder' })}
                    value={values.fields}
                    freeSolo={true}
                    options={[]}
                    getOptionLabel={(item: string) => item}
                    handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                      handleChange({
                        target: {
                          name: 'fields',
                          value: newValue,
                        },
                      })
                    }}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'notifications.templates.fields.config' })}
              >
                <FormikProvider value={formik}>
                  <FieldArray
                    name='internalConfig'
                    component={KeyValueMapField as any}
                  />
                </FormikProvider>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='notificationsTemplatesModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='notificationsTemplatesModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid || !dirty}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default NotificationsTemplatesModalContainer
