import React from 'react'
import { Box, useTheme } from '@mui/material'
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom'

import TabComponent from '@base/pagebar/Tab'
import { TrackingEventNameProps } from '@utils/tracking.utils'

export interface TabBarComponentProps {
  /**
   * Tab options
   */
  options?: Common.TabOption[]
  /**
   * Callback to be called when tab is changed
   * @param tab - Selected tab option
   */
  onTabChange?: (tab: Common.TabOption) => void,
  /**
   * Tracking props to be passed to the tracking event
   */
  trackingProps?: Partial<TrackingEventNameProps>
}

const TabBarComponent: React.FC<TabBarComponentProps> = ({
  options = [],
  onTabChange,
  trackingProps,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const match = useRouteMatch<Common.RouterMatch>()
  const handleClick = (option: Common.TabOption) => {
    if (option.path) {
      history.push(generatePath(option.path, match.params))
    }

    if (onTabChange) {
      onTabChange(option)
    }
  }

  if (!options.length) {
    return null
  }

  return (
    <Box
      data-testid={TabBarComponent.name}
      role='tablist'
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        borderRadius: '36px',
        outline: 'none',
        p: '0px 3px',
        backgroundColor: theme.palette.new.smokey_silver,
      }}
    >
      {
        options.map((option, index) => {
          const isActive = option.path ? (match.path === option.path) : option.active

          return (
            <TabComponent
              key={index}
              label={option.label}
              active={isActive}
              onClick={() => handleClick(option)}
              trackingProps={trackingProps}
            />
          )
        })
      }
    </Box>
  )
}

export default TabBarComponent
