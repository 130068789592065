import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { useIntl } from 'react-intl'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { NOTIFICATIONS_OVERVIEW_MODAL_NAME } from '@constants/modals.constants'
import { TRACKING_MODULES } from '@utils/tracking.utils'
import { requestUnreadNotificationsCountAction } from '@redux/modules/notifications/notifications.actions'
import { getGlobalUnreadCount } from '@redux/modules/notifications/notifications.selectors'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'

import TopBarButtonComponent from '@base/topbar/TopBarButton/TopBarButton.component'
import NotificationIcon from '@icons/notification.icon'

export interface NotificationsProps {}

const Notifications: React.FC<NotificationsProps> = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const selectedCompanyId = useSelector((state) => getSelectedCompanyId(state))
  const unreadNotificationsCount = useSelector(getGlobalUnreadCount)

  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  useEffect(() => {
    dispatch(
      requestUnreadNotificationsCountAction({
        companyId: selectedCompanyId,
      }),
    )
  }, [
    dispatch,
    usecase,
    selectedCompanyId,
  ])

  const onButtonClick = () => {
    dispatch(
      setPrimaryModalPageName(NOTIFICATIONS_OVERVIEW_MODAL_NAME),
    )
  }

  return (
    <TopBarButtonComponent
      name='notificationsButton'
      IconComponent={NotificationIcon}
      onClick={onButtonClick}
      trackingProps={{ moduleName: TRACKING_MODULES.TOP_BAR }}
      label={intl.formatMessage({ id: 'common.layout.button.notifications' })}
      badgeProps={{
        showZero: false,
        badgeContent: unreadNotificationsCount,
      }}
    />
  )
}

export default Notifications
