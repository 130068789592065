import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import { useIntl } from 'react-intl'
import { Box, Card } from '@mui/material'
import { GRID_ACTIONS_COLUMN_TYPE, GridColDef, GridRowParams } from '@mui/x-data-grid-premium'
import { useDispatch, useSelector } from '@redux/hooks'

import {
  generateActionsColumnDefinition,
  generateChipColumnDefinition,
  generateCopyButtonColumnDefinition,
  generateDateTimeColumnDefinition,
  generateRegularColumnDefinition,
} from '@utils/data-grid-cells.utils'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'
import { getBgColorByPlatform } from '@utils/notifications.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import useGridInitialState from '@hooks/useGridInitialState.hook'

import { removeNotificationsTemplateAction, requestNotificationsTemplatesAction } from '@redux/modules/notifications/notifications.actions'
import { isFetchingNotificationsTemplates, getNotificationsTemplates } from '@redux/modules/notifications/notifications.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { NOTIFICATIONS_TEMPLATES_MODAL_NAME } from '@constants/modals.constants'

import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog/TextConfirmationDialog.component'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import EditIcon from '@icons/edit.icon'
import DeleteIcon from '@icons/delete.icon'
import NotificationsTemplatesModalContainer from '@containers/modals/notifications/notifications-templates-modal'

export interface NotificationsTemplatesContainerProps {}

const NotificationsTemplatesContainer: React.FC<NotificationsTemplatesContainerProps> = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const isFetching = useSelector(isFetchingNotificationsTemplates)
  const list = useSelector(getNotificationsTemplates)

  const [dialogState, setDialogState] = useState({
    open: false,
    id: '',
  })

  const tableId = getDataGridId(DATA_GRIDS.NOTIFICATIONS_TEMPLATES_TABLE, 1)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'updatedAt', sort: 'desc' }],
    },
  })

  useLayoutEffect(() => {
    dispatch(
      requestNotificationsTemplatesAction({ page: 1 }),
    )
  }, [dispatch])

  const handleNotificationsTemplateDelete = useCallback((record: Notifications.NotificationTemplateItem) => {
    setDialogState({
      open: true,
      id: record.id!,
    })
  }, [])

  const handleNotificationsTemplateEdit = useCallback((record: Notifications.NotificationTemplateItem) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: NOTIFICATIONS_TEMPLATES_MODAL_NAME,
        modalDetails: {
          returnTo: '',
          template: record,
        },
      }),
    )
  }, [dispatch])

  const handleDelete = (id: string) => {
    dispatch(
      removeNotificationsTemplateAction({
        id,
      }),
    )

    setDialogState({
      open: false,
      id: '',
    })
  }

  const getActionItems = useCallback((params: GridRowParams<Notifications.NotificationTemplateItem>) => {
    return [
      <DataGridActionButtonComponent
        name='notificationsTemplateEdit'
        icon={<EditIcon />}
        onClick={() => handleNotificationsTemplateEdit(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.edit' })}
        id={params.id}
      />,
      <DataGridActionButtonComponent
        name='notificationsTemplateDelete'
        icon={<DeleteIcon />}
        onClick={() => handleNotificationsTemplateDelete(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
        id={params.id}
      />,
    ]
  }, [intl, handleNotificationsTemplateEdit, handleNotificationsTemplateDelete])

  const columns = useMemo<GridColDef[]>(() => {
    const columnsList = [
      generateCopyButtonColumnDefinition({
        field: 'id',
        headerName: intl.formatMessage({ id: 'notifications.templates.fields.id' }),
      }),
      generateChipColumnDefinition({
        intl,
        field: 'platform',
        headerName: intl.formatMessage({ id: 'notifications.templates.fields.platform' }),
        uppercase: true,
        getBgColorByValue: getBgColorByPlatform,
        gridColDefOverrides: {
          minWidth: 100,
        },
      }),
      generateRegularColumnDefinition({
        intl,
        field: 'description',
        headerName: intl.formatMessage({ id: 'notifications.templates.fields.description' }),
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'createdAt',
        headerName: intl.formatMessage({ id: 'notifications.templates.fields.createdAt' }),
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'updatedAt',
        headerName: intl.formatMessage({ id: 'notifications.templates.fields.updatedAt' }),
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 2,
      }),
    ]

    return columnsList
  }, [
    intl,
    getActionItems,
  ])

  return (
    <Card
      data-testid={NotificationsTemplatesContainer.name}
      elevation={0}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.NOTIFICATIONS_TEMPLATES_TABLE}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={(row) => row.id}
        initialState={initialState}
      />

      <TextConfirmationDialogComponent
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onSubmit={() => handleDelete(dialogState.id)}
        confirmationText={dialogState.id}
        confirmationInputLabel={intl.formatMessage({ id: 'notifications.templates.table.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'notifications.templates.table.dialog.content' }, {
            name: <Box component='strong'>{dialogState.id}</Box>,
          })
        }
      />

      <NotificationsTemplatesModalContainer />
    </Card>
  )
}

export default NotificationsTemplatesContainer
