export enum FEDERAL_STATES_DE {
  BADEN_WUERTTEMBERG = 'BADEN_WUERTTEMBERG',
  BAVARIA = 'BAVARIA',
  BERLIN = 'BERLIN',
  BRANDENBURG = 'BRANDENBURG',
  BREMEN = 'BREMEN',
  HAMBURG = 'HAMBURG',
  HESSEN = 'HESSEN',
  LOWER_SAXONY = 'LOWER_SAXONY',
  MECKLENBURG_VORPOMMERN = 'MECKLENBURG_VORPOMMERN',
  NORTH_RHINE_WESTPHALIA = 'NORTH_RHINE_WESTPHALIA',
  RHINELAND_PALATINATE = 'RHINELAND_PALATINATE',
  SAARLAND = 'SAARLAND',
  SAXONY = 'SAXONY',
  SAXONY_ANHALT = 'SAXONY_ANHALT',
  SCHLESWIG_HOLSTEIN = 'SCHLESWIG_HOLSTEIN',
  THURINGIA = 'THURINGIA',
}

export const FEDERAL_STATES_DE_TO_LABELS_MAP: { [key in FEDERAL_STATES_DE]: string } = {
  [FEDERAL_STATES_DE.BADEN_WUERTTEMBERG]: 'common.events.regions.de.baden_wuerttemberg',
  [FEDERAL_STATES_DE.BAVARIA]: 'common.events.regions.de.bavaria',
  [FEDERAL_STATES_DE.BERLIN]: 'common.events.regions.de.berlin',
  [FEDERAL_STATES_DE.BRANDENBURG]: 'common.events.regions.de.brandenburg',
  [FEDERAL_STATES_DE.BREMEN]: 'common.events.regions.de.bremen',
  [FEDERAL_STATES_DE.HAMBURG]: 'common.events.regions.de.hamburg',
  [FEDERAL_STATES_DE.HESSEN]: 'common.events.regions.de.hessen',
  [FEDERAL_STATES_DE.LOWER_SAXONY]: 'common.events.regions.de.lower_saxony',
  [FEDERAL_STATES_DE.MECKLENBURG_VORPOMMERN]: 'common.events.regions.de.mecklenburg_vorpommern',
  [FEDERAL_STATES_DE.NORTH_RHINE_WESTPHALIA]: 'common.events.regions.de.north_rhine_westphalia',
  [FEDERAL_STATES_DE.RHINELAND_PALATINATE]: 'common.events.regions.de.rhineland_palatinate',
  [FEDERAL_STATES_DE.SAARLAND]: 'common.events.regions.de.saarland',
  [FEDERAL_STATES_DE.SAXONY]: 'common.events.regions.de.saxony',
  [FEDERAL_STATES_DE.SAXONY_ANHALT]: 'common.events.regions.de.saxony_anhalt',
  [FEDERAL_STATES_DE.SCHLESWIG_HOLSTEIN]: 'common.events.regions.de.schleswig_holstein',
  [FEDERAL_STATES_DE.THURINGIA]: 'common.events.regions.de.thuringia',
}

export const FEDERAL_STATES_TO_SHORT_LABELS_MAP: { [key in FEDERAL_STATES_DE]: string } = {
  [FEDERAL_STATES_DE.BADEN_WUERTTEMBERG]: 'common.events.regions.de.short.bw',
  [FEDERAL_STATES_DE.BAVARIA]: 'common.events.regions.de.short.by',
  [FEDERAL_STATES_DE.BERLIN]: 'common.events.regions.de.short.be',
  [FEDERAL_STATES_DE.BRANDENBURG]: 'common.events.regions.de.short.bb',
  [FEDERAL_STATES_DE.BREMEN]: 'common.events.regions.de.short.hb',
  [FEDERAL_STATES_DE.HAMBURG]: 'common.events.regions.de.short.hh',
  [FEDERAL_STATES_DE.HESSEN]: 'common.events.regions.de.short.he',
  [FEDERAL_STATES_DE.LOWER_SAXONY]: 'common.events.regions.de.short.ni',
  [FEDERAL_STATES_DE.MECKLENBURG_VORPOMMERN]: 'common.events.regions.de.short.mv',
  [FEDERAL_STATES_DE.NORTH_RHINE_WESTPHALIA]: 'common.events.regions.de.short.nw',
  [FEDERAL_STATES_DE.RHINELAND_PALATINATE]: 'common.events.regions.de.short.rp',
  [FEDERAL_STATES_DE.SAARLAND]: 'common.events.regions.de.short.sl',
  [FEDERAL_STATES_DE.SAXONY]: 'common.events.regions.de.short.sn',
  [FEDERAL_STATES_DE.SAXONY_ANHALT]: 'common.events.regions.de.short.st',
  [FEDERAL_STATES_DE.SCHLESWIG_HOLSTEIN]: 'common.events.regions.de.short.sh',
  [FEDERAL_STATES_DE.THURINGIA]: 'common.events.regions.de.short.th',
}

export const FEDERAL_STATES_DE_LIST = Object.keys(FEDERAL_STATES_DE) as FEDERAL_STATES_DE[]
