import { IntlShape } from 'react-intl'

import * as Yup from 'yup'

const validation = (intl: IntlShape) => {
  const shape = Yup.object().shape({
    type: Yup.string().required(),
    severity: Yup.string().required(),
    origin: Yup.string().required(),
    companyId: Yup.string().required(),
  })

  return shape
}

export default validation
