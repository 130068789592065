import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from '@redux/hooks'
import { useHistory } from 'react-router-dom'
import { Card } from '@mui/material'

import EditIcon from '@icons/edit.icon'
import CloneIcon from '@icons/clone.icon'
import DeleteIcon from '@icons/delete.icon'

import {
  GRID_ACTIONS_COLUMN_TYPE,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium'

import { changeCompanyIdAction } from '@redux/modules/customer/customer.actions'
import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'

import {
  generateActionsColumnDefinition, generateClickableLinkColumnDefinition,
  generateDateTimeColumnDefinition, generateCopyButtonColumnDefinition,
} from '@utils/data-grid-cells.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import useGridInitialState from '@hooks/useGridInitialState.hook'

export interface CompaniesOverviewComponentProps {
  list: Customer.CompanyItem[],
  handleClone: {
    (record: Customer.CompanyItem): any,
  },
  handleEdit: {
    (record: Customer.CompanyItem): any,
  },
  handleDelete: {
    (record: Customer.CompanyItem): any,
  },
  isFetching?: boolean,
}

const CompaniesOverviewComponent: React.FC<CompaniesOverviewComponentProps> = (props) => {
  const {
    list = [],
    handleEdit,
    handleDelete,
    handleClone,
    isFetching,
  } = props

  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()

  const tableId = getDataGridId(DATA_GRIDS.COMPANIES_TABLE, 2)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'updatedAt', sort: 'desc' }],
    },
  })

  const getOnCompanyClickHandler = useCallback((params: GridRenderCellParams<Customer.CompanyItem>) => {
    return () => {
      dispatch(
        changeCompanyIdAction({
          companyId: params.row.companyId,
          saveToLs: true,
        }),
      )

      history.push('/')
    }
  }, [dispatch, history])

  const getActionItems = useCallback((params: GridRowParams<Customer.CompanyItem>) => {
    return [
      <DataGridActionButtonComponent
        name='companyClone'
        icon={<CloneIcon />}
        onClick={() => handleClone(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.clone' })}
        id={params.id}
      />,
      <DataGridActionButtonComponent
        name='companyEdit'
        icon={<EditIcon />}
        onClick={() => handleEdit(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.edit' })}
        id={params.id}
      />,
      <DataGridActionButtonComponent
        name='companyDelete'
        icon={<DeleteIcon />}
        onClick={() => handleDelete(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
        id={params.id}
      />,
    ]
  }, [intl, handleClone, handleEdit, handleDelete])

  const columns = useMemo<GridColDef[]>(() => {
    return [
      generateClickableLinkColumnDefinition({
        field: 'name',
        headerName: intl.formatMessage({ id: 'companies.table.name' }),
        getOnClick: getOnCompanyClickHandler,
      }),
      generateCopyButtonColumnDefinition({
        field: 'companyId',
        headerName: intl.formatMessage({ id: 'companies.table.id' }),
      }),
      generateCopyButtonColumnDefinition({
        field: 'slug',
        headerName: intl.formatMessage({ id: 'companies.table.slug' }),
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'updatedAt',
        headerName: intl.formatMessage({ id: 'companies.table.updatedAt' }),
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 3,
      }),
    ]
  }, [
    intl,
    getOnCompanyClickHandler,
    getActionItems,
  ])

  return (
    <Card
      data-testid={CompaniesOverviewComponent.name}
      elevation={0}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.COMPANIES_TABLE}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={(row) => row.companyId}
        initialState={initialState}
        disableRowGrouping={true}
      />
    </Card>
  )
}

export default CompaniesOverviewComponent
