import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { useRouteMatch } from 'react-router-dom'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getNotificationsSinks, isFetchingNotificationsSinks, isSubmittingNotificationsTemplates } from '@redux/modules/notifications/notifications.selectors'
import { getIsAdmin, getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { NOTIFICATIONS_ROUTINGS_MODAL_NAME } from '@constants/modals.constants'
import { createNotificationsRoutingsAction, requestNotificationsSinksAction, updateNotificationsRoutingsAction } from '@redux/modules/notifications/notifications.actions'
import { getSinkOptionLabel, isSinkOptionEqualToValue } from '@utils/notifications.utils'

import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'

import {
  NOTIFICATIONS_TYPES_LIST,
  NOTIFICATIONS_SEVERITIES_LIST,
  NOTIFICATIONS_TYPES,
  NOTIFICATIONS_SEVERITIES,
} from '@constants/notifications.constants'

import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField/AutocompleteSelectField.component'
import SwitchFieldComponent from '@base/forms/SwitchField'

import validations from './NotificationsRoutingsModal.validations'

export interface NotificationsRoutingsModalDetails extends Common.ModalDetails {
  routing: Notifications.NotificationRoutingItem
}

export interface InternalNotificationRoutingItem extends Omit<Notifications.NotificationRoutingItem, 'sink'> {
  sinkId: string
  sink: Notifications.NotificationSinkItem | null
}

const NotificationsRoutingsModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const isSubmitting = useSelector(isSubmittingNotificationsTemplates)
  const isFetchingSinks = useSelector(isFetchingNotificationsSinks)
  const sinksList = useSelector(getNotificationsSinks)
  const modalPageName = useSelector(getOpenedModal)
  const selectedCompanyId = useSelector(getSelectedCompanyId)

  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()
  const { routing } = useSelector((state) => getModalDetails<NotificationsRoutingsModalDetails>(state))

  const open = modalPageName === NOTIFICATIONS_ROUTINGS_MODAL_NAME
  const isEdit = Boolean(routing?.id)

  const sinksListForRouting = useMemo(() => {
    return sinksList.filter((sink) => {
      if (!routing) {
        return ((!sink.companyId || sink.companyId === selectedCompanyId) && (!sink.useCaseId || sink.useCaseId === usecase))
      }

      return (
        (!sink.companyId || routing.companyId === sink.companyId) &&
        (!sink.useCaseId || routing.useCaseId === sink.useCaseId)
      )
    })
  }, [sinksList, routing, selectedCompanyId, usecase])

  const initialValues = useMemo(() => {
    if (routing) {
      const sinkForRoute = sinksList.find((sink) => sink.id === routing.sink?.id)

      return {
        ...routing,
        sinkId: routing.sink?.id,
        sink: sinkForRoute,
      } as InternalNotificationRoutingItem
    }

    return {
      id: '',
      notificationType: NOTIFICATIONS_TYPES.DATA_UPLOAD,
      severity: NOTIFICATIONS_SEVERITIES.INFO,
      enabled: false,
      sinkId: '',
      useCaseId: usecase,
      companyId: selectedCompanyId,
      sink: null,
    } as InternalNotificationRoutingItem
  }, [
    routing,
    sinksList,
    selectedCompanyId,
    usecase,
  ])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: InternalNotificationRoutingItem) => {
    if (isEdit) {
      dispatch(
        updateNotificationsRoutingsAction({
          id: values.id!,
          notificationType: values.notificationType,
          severity: values.severity,
          enabled: values.enabled,
          sinkId: values.sinkId,
          useCaseId: usecase,
          companyId: selectedCompanyId,
        }),
      )
    } else {
      dispatch(
        createNotificationsRoutingsAction({
          notificationType: values.notificationType,
          severity: values.severity,
          enabled: values.enabled,
          sinkId: values.sinkId,
          useCaseId: usecase,
          companyId: selectedCompanyId,
        }),
      )
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: validations(intl),
  })

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    } else if (open) {
      dispatch(
        requestNotificationsSinksAction({ page: 1 }),
      )
    }
  }, [open, dispatch, resetForm])

  return (
    <SidePanelComponent
      open={open && isAdmin}
      title={intl.formatMessage({ id: isEdit ? 'notifications.routings.edit' : 'notifications.routings.add' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer>
                <FormLayoutItem xs={12} hidden={!isEdit}>
                  <TextFieldComponent
                    name='id'
                    touched={touched.id}
                    errors={errors.id}
                    value={values.id}
                    label={intl.formatMessage({ id: 'notifications.routings.fields.id' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='companyId'
                      label={intl.formatMessage({ id: 'notifications.routings.fields.companyId' })}
                      value={values.companyId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.companyId}
                      touched={touched.companyId}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='useCaseId'
                      label={intl.formatMessage({ id: 'notifications.routings.fields.useCaseId' })}
                      value={values.useCaseId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.useCaseId}
                      touched={touched.useCaseId}
                      disabled={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={6}>
                    <SelectFieldComponent
                      name='severity'
                      label={intl.formatMessage({ id: 'notifications.routings.fields.severity' })}
                      value={values.severity}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={NOTIFICATIONS_SEVERITIES_LIST}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SwitchFieldComponent
                      name='enabled'
                      label={intl.formatMessage({ id: 'notifications.routings.fields.enabled' })}
                      value={values.enabled}
                      checked={values.enabled}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      useLabelPlaceholder={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <AutocompleteSelectFieldComponent<Notifications.NotificationType>
                    name='notificationType'
                    label={intl.formatMessage({ id: 'notifications.routings.fields.notificationType' })}
                    floatingHelp={intl.formatMessage({ id: 'notifications.routings.fields.notificationType.help' })}
                    placeholder={intl.formatMessage({ id: 'notifications.routings.fields.notificationType.placeholder' })}
                    value={values.notificationType}
                    options={NOTIFICATIONS_TYPES_LIST.map((item) => item.value)}
                    getOptionLabel={(item: string) => item}
                    handleChangeCallback={(e: React.SyntheticEvent, selectedValue: string) => {
                      setFieldValue('notificationType', selectedValue)
                    }}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <AutocompleteSelectFieldComponent<Notifications.NotificationSinkItem>
                    name='sink'
                    label={intl.formatMessage({ id: 'notifications.sinks.fields.sink' })}
                    value={values.sink}
                    options={sinksListForRouting}
                    getOptionLabel={getSinkOptionLabel}
                    isOptionEqualToValue={isSinkOptionEqualToValue}
                    handleChangeCallback={(e: React.SyntheticEvent, selectedValue: Notifications.NotificationSinkItem) => {
                      if (selectedValue) {
                        setFieldValue('sink', selectedValue)
                        setFieldValue('sinkId', selectedValue.id)
                      } else {
                        setFieldValue('sink', null)
                        setFieldValue('sinkId', '')
                      }
                    }}
                    loading={isFetchingSinks}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='notificationsRoutingsModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='notificationsRoutingsModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid || !dirty}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default NotificationsRoutingsModalContainer
