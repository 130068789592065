import React from 'react'

import { Position } from '@xyflow/react'
import { Box, Typography, useTheme } from '@mui/material'

import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'

export interface ReactFlowNotificationTypeNodeComponentProps {
  /**
   * Node's data
   */
  data: {
    label: string
    backgroundColor?: string
    color?: string
    hasParent?: boolean
  }
}

const ReactFlowNotificationTypeNodeComponent: React.FC<ReactFlowNotificationTypeNodeComponentProps> = ({ data }) => {
  const theme = useTheme()

  const {
    backgroundColor,
    label,
    color,
    hasParent = false,
  } = data

  return (
    <Box
      data-testid={ReactFlowNotificationTypeNodeComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '250px',
        height: '90px',
        background: theme.palette.new.white,
        border: '1px solid',
        borderColor: theme.palette.new.grey_a,
        borderRadius: theme.spacing(0.5),
        position: 'relative',
        cursor: 'default',
      }}
    >
      {
        hasParent && (
          <ReactFlowHandleComponent
            type='target'
            position={Position.Top}
          />
        )
      }

      <Typography
        variant='h6'
        noWrap={true}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          maxWidth: '250px',
          textTransform: 'uppercase',
          borderRadius: theme.spacing(0.5),
          backgroundColor: backgroundColor || theme.palette.new.white,
          color: color || theme.palette.new.black,
        }}
      >
        {label}
      </Typography>

      <ReactFlowHandleComponent
        type='source'
        position={Position.Bottom}
      />
    </Box>
  )
}

export default ReactFlowNotificationTypeNodeComponent
