import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import { useIntl } from 'react-intl'
import { Box, Card } from '@mui/material'
import { GRID_ACTIONS_COLUMN_TYPE, GridColDef, GridRowParams } from '@mui/x-data-grid-premium'
import { useDispatch, useSelector } from '@redux/hooks'

import {
  generateActionsColumnDefinition,
  generateBooleanColumnDefinition,
  generateChipColumnDefinition,
  generateCopyButtonColumnDefinition,
  generateDateTimeColumnDefinition,
  generateRegularColumnDefinition,
} from '@utils/data-grid-cells.utils'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import useGridInitialState from '@hooks/useGridInitialState.hook'

import { removeNotificationsSinkAction, requestNotificationsSinksAction } from '@redux/modules/notifications/notifications.actions'
import { isFetchingNotificationsSinks, getNotificationsGlobalSinks } from '@redux/modules/notifications/notifications.selectors'
import { getBgColorByPlatform } from '@utils/notifications.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { NOTIFICATIONS_SINKS_MODAL_NAME, NOTIFICATIONS_SINKS_TEST_MODAL_NAME } from '@constants/modals.constants'

import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import DeleteIcon from '@icons/delete.icon'
import EditIcon from '@icons/edit.icon'
import PlayIcon from '@icons/play.icon'
import NotificationsSinksModalContainer from '@containers/modals/notifications/notifications-sinks-modal'
import NotificationsSinkTestModalContainer from '@containers/modals/notifications/notifications-sink-test-modal'

const NotificationsGlobalSinksContainer: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const isFetching = useSelector(isFetchingNotificationsSinks)
  const list = useSelector(getNotificationsGlobalSinks)

  const [dialogState, setDialogState] = useState({
    open: false,
    id: '',
  })

  const tableId = getDataGridId(DATA_GRIDS.NOTIFICATIONS_SINKS_TABLE_GLOBAL, 1)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'updatedAt', sort: 'desc' }],
    },
  })

  useLayoutEffect(() => {
    dispatch(
      requestNotificationsSinksAction({ page: 1 }),
    )
  }, [dispatch])

  const handleNotificationsSinksEdit = useCallback((row: Notifications.NotificationSinkItem) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: NOTIFICATIONS_SINKS_MODAL_NAME,
        modalDetails: {
          returnTo: '',
          sink: row,
        },
      }),
    )
  }, [dispatch])

  const handleNotificationsSinksDelete = useCallback((row: Notifications.NotificationSinkItem) => {
    setDialogState({
      open: true,
      id: row.id!,
    })
  }, [])

  const handleNotificationsSinksTest = useCallback((row: Notifications.NotificationSinkItem) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: NOTIFICATIONS_SINKS_TEST_MODAL_NAME,
        modalDetails: {
          returnTo: '',
          sink: row,
        },
      }),
    )
  }, [dispatch])

  const handleDelete = (id: string) => {
    dispatch(
      removeNotificationsSinkAction({
        id,
      }),
    )

    setDialogState({
      open: false,
      id: '',
    })
  }

  const getActionItems = useCallback((params: GridRowParams<Notifications.NotificationSinkItem>) => {
    return [
      <DataGridActionButtonComponent
        name='notificationsSinksTest'
        icon={<PlayIcon />}
        onClick={() => handleNotificationsSinksTest(params.row)}
        label={intl.formatMessage({ id: 'notifications.sinks.table.action.test' })}
        id={params.id}
      />,
      <DataGridActionButtonComponent
        name='notificationsSinksEdit'
        icon={<EditIcon />}
        onClick={() => handleNotificationsSinksEdit(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.edit' })}
        id={params.id}
      />,
      <DataGridActionButtonComponent
        name='notificationsSinksDelete'
        icon={<DeleteIcon />}
        onClick={() => handleNotificationsSinksDelete(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
        id={params.id}
      />,
    ]
  }, [
    intl,
    handleNotificationsSinksEdit,
    handleNotificationsSinksDelete,
    handleNotificationsSinksTest,
  ])

  const columns = useMemo<GridColDef[]>(() => {
    const columnsList = [
      generateCopyButtonColumnDefinition({
        field: 'id',
        headerName: intl.formatMessage({ id: 'notifications.sinks.fields.id' }),
      }),
      generateRegularColumnDefinition({
        intl,
        field: 'name',
        headerName: intl.formatMessage({ id: 'notifications.sinks.fields.name' }),
      }),
      generateChipColumnDefinition({
        intl,
        field: 'platform',
        headerName: intl.formatMessage({ id: 'notifications.sinks.fields.platform' }),
        uppercase: true,
        getBgColorByValue: getBgColorByPlatform,
        gridColDefOverrides: {
          minWidth: 100,
        },
      }),
      generateBooleanColumnDefinition({
        intl,
        field: 'enabled',
        headerName: intl.formatMessage({ id: 'notifications.sinks.fields.status' }),
        gridColDefOverrides: {
          minWidth: 100,
        },
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'updatedAt',
        headerName: intl.formatMessage({ id: 'notifications.sinks.fields.updatedAt' }),
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 3,
      }),
    ]

    return columnsList
  }, [
    intl,
    getActionItems,
  ])

  return (
    <Card
      data-testid={NotificationsGlobalSinksContainer.name}
      elevation={0}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.NOTIFICATIONS_SINKS_TABLE_GLOBAL}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={(row) => row.id}
        initialState={initialState}
      />

      <TextConfirmationDialogComponent
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onSubmit={() => handleDelete(dialogState.id)}
        confirmationText={dialogState.id}
        confirmationInputLabel={intl.formatMessage({ id: 'notifications.sinks.table.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'notifications.sinks.table.dialog.content' }, {
            name: <Box component='strong'>{dialogState.id}</Box>,
          })
        }
      />

      <NotificationsSinksModalContainer global={true} />

      <NotificationsSinkTestModalContainer />
    </Card>
  )
}

export default NotificationsGlobalSinksContainer
