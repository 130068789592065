import Paper from '@mui/material/Paper'
import { GridColDef, gridClasses } from '@mui/x-data-grid-premium'
import { DATA_GRIDS } from '@constants/data-grid.constants'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import DataGridComponent from '@base/datagrid/data-grid'
import useGridInitialState from '@hooks/useGridInitialState.hook'
import { FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX } from '@constants/files.constants'
import DropdownMenuComponent from '@base/dropdowns/DropdownMenu/DropdownMenu.component'
import SwitchFieldComponent from '@base/forms/SwitchField/SwitchField.component'
import { getFilePreviewDelimiterOptions } from '@utils/files.utils'

export interface FileBrowserPreviewTableComponentProps {
  /**
   * Columns definitions
   */
  columns: GridColDef[]
  /**
   * Data to display
   */
  data: any[]
  /**
   * If true, the preview will use dynamic typing
   */
  dynamicTyping?: boolean
  /**
   * The delimiter to use
   */
  delimiter?: string
  /**
   * If the user is admin
   */
  isAdmin?: boolean
  /**
   * The function to set the delimiter
   */
  setDelimiter: (delimiter: string) => void
  /**
   * The function to set the dynamic typing
   */
  setDynamicTyping: (dynamicTyping: boolean) => void
}

const FileBrowserPreviewTableComponent: React.FC<FileBrowserPreviewTableComponentProps> = ({
  columns,
  data,
  dynamicTyping = false,
  delimiter = 'auto',
  isAdmin,
  setDelimiter,
  setDynamicTyping,
}) => {
  const theme = useTheme()
  const intl = useIntl()

  const [containerMaxHeight, setContainerMaxHeight] = useState(0)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const delimiterOptions = useMemo(() => getFilePreviewDelimiterOptions(intl), [intl])

  const initialState = useGridInitialState(DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE, {
    pagination: {
      paginationModel: {
        pageSize: 50,
      },
    },
  })

  const sx = {
    [`& .${gridClasses.toolbarContainer}`]: {
      borderTop: 'none',
    },
    [`& .${gridClasses.main}`]: {
      borderRadius: '0px',
    },
    [`& .${gridClasses.columnHeader}[data-field^="${FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX}"]`]: {
      backgroundColor: theme.palette.new.rebellious_red_10,
      color: theme.palette.new.rebellious_red,
    },
  }

  const handleResize = useCallback(() => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return
    }

    setContainerMaxHeight((tableContainerRef.current.parentElement?.clientHeight || 0))
  }, [tableContainerRef])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return
    }

    handleResize()
  }, [handleResize])

  const customToolbarChildren = useMemo(() => {
    return (
      <Box
        display='flex'
        alignItems='center'
        gap={1}
      >
        <DropdownMenuComponent
          name='delimiter'
          onChange={(value) => setDelimiter(value as string)}
          value={delimiter}
          multiple={false}
          options={delimiterOptions}
          minWidth='280px'
        />

        {
          isAdmin ? (
            <SwitchFieldComponent
              name='dynamicTyping'
              onChange={(e) => setDynamicTyping(e.target.checked)}
              value={dynamicTyping}
              checked={dynamicTyping}
              label={intl.formatMessage({ id: 'fileManager.preview.dialog.dynamicTyping' })}
            />
          ) : (
            null
          )
        }
      </Box>
    )
  }, [
    delimiter,
    delimiterOptions,
    dynamicTyping,
    setDelimiter,
    setDynamicTyping,
    isAdmin,
    intl,
  ])

  const gridSlotConfig = useMemo(() => ({
    toolbar: {
      withExport: false,
      customToolbarChildren,
    },
    pagination: {
      nextIconButtonProps: {
        enableGoToLastPage: true,
      },
      backIconButtonProps: {
        enableGoToFirstPage: true,
      },
    } as any,
  }), [customToolbarChildren])

  return (
    <Paper
      data-testid={FileBrowserPreviewTableComponent.name}
      sx={{ width: '100%', overflow: 'hidden' }}
      ref={tableContainerRef}
      elevation={0}
    >
      <DataGridComponent
        id={DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE}
        name={DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE}
        columns={columns}
        rows={data}
        height={`${containerMaxHeight}px`}
        rounded={false}
        enablePersistence={false}
        slotProps={gridSlotConfig}
        initialState={initialState}
        sx={sx}
      />
    </Paper>
  )
}

export default FileBrowserPreviewTableComponent
