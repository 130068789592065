import React, { Fragment } from 'react'
import get from 'lodash.get'
import { createId } from '@utils/common.utils'

import ChartTooltipLegendItemComponent from '@components/charts/tooltips/ChartTooltipLegendItem'
import ChartTooltipValueComponent from '@components/charts/tooltips/ChartTooltipValue'
import ChartTooltipTableCellComponent from '@components/charts/tooltips/ChartTooltipTableCell'
import ChartTooltipTableRowComponent from '@components/charts/tooltips/ChartTooltipTableRow'
import ChartTooltipTableDividerComponent from '@components/charts/tooltips/ChartTooltipTableDivider'

export interface ChartTooltipGroupBlockComponentProps<T> {
  data: T
  showDivider?: boolean
  groupRows: Common.TooltipRow<T>[]
}

const ChartTooltipGroupBlockComponent = <T extends {}> ({
  data,
  showDivider = false,
  groupRows,
}: ChartTooltipGroupBlockComponentProps<T>) => {
  const hasSecondaryValue = groupRows.some((row) => row.secondaryKey || row.secondaryValueGetter)
  const nodes = groupRows.map((row, rowIndex) => {
    const value = get(data, row.key, 0) as number | string
    const secondaryValueFromKey = row.secondaryKey ? get(data, row.secondaryKey, 0) as number | string : undefined
    const secondaryValueFromGetter = row.secondaryValueGetter ? row.secondaryValueGetter(data) : undefined
    const secondaryValue = row.secondaryValueGetter ? secondaryValueFromGetter : secondaryValueFromKey

    const formattedValue = row.valueFormatter ? row.valueFormatter(value, data) : value
    const formattedSecondaryValue = row.secondaryValueFormatter ? row.secondaryValueFormatter(secondaryValue, data) : secondaryValue

    const legendColor = row.legendColor ? (typeof row.legendColor === 'function' ? row.legendColor(data) : row.legendColor) : undefined

    const isLastRowInGroup = rowIndex === groupRows.length - 1

    return (
      <Fragment
        key={createId(row.label, rowIndex)}
      >
        <ChartTooltipTableRowComponent sx={row.sx}>
          <ChartTooltipTableCellComponent
            sx={{ textAlign: 'left' }}
          >
            <ChartTooltipLegendItemComponent
              color={legendColor}
              type={row.legendType}
              label={row.label}
            />
          </ChartTooltipTableCellComponent>

          <ChartTooltipTableCellComponent
            sx={{ textAlign: 'right' }}
          >
            <ChartTooltipValueComponent
              color={legendColor}
              value={formattedValue}
              variant={row.valueVariant}
            />
          </ChartTooltipTableCellComponent>

          {
            hasSecondaryValue ? (
              <ChartTooltipTableCellComponent
                sx={{ textAlign: 'right' }}
              >
                {
                  secondaryValue !== undefined ? (
                    <ChartTooltipValueComponent
                      color={row.secondaryValueColor}
                      value={formattedSecondaryValue}
                      variant='contained'
                    />
                  ) : (
                    null
                  )
                }
              </ChartTooltipTableCellComponent>
            ) : (
              null
            )
          }
        </ChartTooltipTableRowComponent>

        <ChartTooltipTableDividerComponent
          show={(showDivider && isLastRowInGroup)}
        />
      </Fragment>
    )
  })

  return (
    <>
      {nodes}
    </>
  )
}

export default ChartTooltipGroupBlockComponent
