import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

const validation = (intl: IntlShape) => {
  const shape = Yup.object().shape({
  })

  return shape
}

export default validation
