import React, { Fragment, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'

import {
  Box,
  Collapse,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import FormGroupComponent from '@base/forms/FormGroup'
import CheckboxFieldComponent from '@base/forms/CheckboxField'

import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { MANAGE_EVENTS_MODAL_NAME } from '@constants/modals.constants'
import { getEventsToExclude, isSubmittingPromotionSettings } from '@redux/modules/insights/insights.selectors'
import { requestEventsSettingsChangeAction } from '@redux/modules/insights/insights.actions'

import {
  ALL_EVENT_ITEMS_IDS,
  GROUPED_EVENTS,
  GroupedEventItem,
  GroupedEvents,
} from '@utils/events.utils'

import ExpandMoreIcon from '@icons/expandMore.icon'
import ExpandLessIcon from '@icons/expandLess.icon'

/**
 * Helper to calculate the selection state for a group of items.
 */
const calculateSelectionState = (items: string[], excludedIds: string[]) => ({
  allChecked: items.every((id) => !excludedIds.includes(id)),
  someChecked: items.some((id) => !excludedIds.includes(id)) && !items.every((id) => !excludedIds.includes(id)),
})

/**
 * Helper to update the checked state for a field.
 */
const updateCheckedState = (
  isChecked: boolean,
  eventsToExclude: string[],
  itemId: string,
  setFieldValue: (field: string, value: any) => void,
) => {
  setFieldValue(
    'eventsToExclude',
    isChecked ? [...eventsToExclude, itemId] : eventsToExclude.filter((excludedId) => excludedId !== itemId),
  )
}

/**
 * GroupItem component to handle rendering of individual items and subitems.
 */
const GroupItem: React.FC<{
  group: GroupedEvents
  values: { eventsToExclude: string[] }
  setFieldValue: (field: string, value: any) => void;
  expandedGroups: { [key: string]: boolean };
  toggleGroup: (key: string) => void;
}> = ({
  group,
  values,
  setFieldValue,
  expandedGroups,
  toggleGroup,
}) => {
  const intl = useIntl()
  const groupItemsIds = group.items.map((item) => item.id)
  const allSubItemsIds = group.items.flatMap((item) => item.subItems?.map((subItem) => subItem.id) || [])
  const hasSubgroups = group.items.some((item) => item.subItems?.length > 0)
  const isExpanded = expandedGroups[group.key]

  const { allChecked, someChecked } = calculateSelectionState(
    hasSubgroups ? [...groupItemsIds, ...allSubItemsIds] : groupItemsIds,
    values.eventsToExclude,
  )

  const handleGroupToggle = () => {
    if (allChecked) {
      setFieldValue('eventsToExclude', values.eventsToExclude.concat(groupItemsIds, allSubItemsIds))
    } else {
      setFieldValue(
        'eventsToExclude',
        values.eventsToExclude.filter((id) => !groupItemsIds.includes(id) && !allSubItemsIds.includes(id)),
      )
    }
  }

  return (
    <FormLayoutItem xs={12} key={group.key}>
      <FormGroupComponent label={intl.formatMessage({ id: group.labelKey })}>
        <CheckboxFieldComponent
          key={`${group.key}-all`}
          label={intl.formatMessage({ id: group.groupLabelKey })}
          name={`${group.key}-all`}
          checked={allChecked}
          indeterminate={someChecked}
          onChange={handleGroupToggle}
          sx={isExpanded ? {} : {
            '&.MuiFormControl-root': {
              borderBottom: 'none',
            },
          }}
          buttonProps={{
            onClick: () => toggleGroup(group.key),
            name: `${group.key}-expand-button`,
            label: intl.formatMessage({ id: isExpanded ? 'common.collapse' : 'common.expand' }),
            IconComponent: isExpanded ? ExpandLessIcon : ExpandMoreIcon,
          }}
        />

        <Collapse in={isExpanded}>
          {
            group.items.map((item, index) => {
              return item.subItems?.length > 0 ? (
                <GroupSubItem
                  key={item.id}
                  item={item}
                  values={values}
                  setFieldValue={setFieldValue}
                  expandedGroups={expandedGroups}
                  toggleGroup={toggleGroup}
                  isLastItem={index === group.items.length - 1}
                />
              ) : (
                <CheckboxFieldComponent
                  key={item.id}
                  label={intl.formatMessage({ id: item.labelKey })}
                  name={item.id}
                  checked={!values.eventsToExclude.includes(item.id)}
                  onChange={(e) => {
                    updateCheckedState(
                      !e.target.checked,
                      values.eventsToExclude,
                      item.id,
                      setFieldValue,
                    )
                  }}
                />
              )
            })
          }
        </Collapse>
      </FormGroupComponent>
    </FormLayoutItem>
  )
}

/**
 * GroupSubItem component for handling subitems within a group.
 */
const GroupSubItem: React.FC<{
  item: GroupedEventItem
  values: { eventsToExclude: string[] }
  setFieldValue: (field: string, value: any) => void
  expandedGroups: { [key: string]: boolean }
  toggleGroup: (key: string) => void
  isLastItem: boolean
}> = ({
  item,
  values,
  setFieldValue,
  expandedGroups,
  toggleGroup,
  isLastItem,
}) => {
  const intl = useIntl()
  const isExpanded = expandedGroups[item.id]
  const subItemsIds = item.subItems.map((subItem) => subItem.id)

  const { allChecked, someChecked } = calculateSelectionState(subItemsIds, values.eventsToExclude)

  const handleToggle = () => {
    if (allChecked) {
      setFieldValue('eventsToExclude', values.eventsToExclude.concat(subItemsIds))
    } else {
      setFieldValue('eventsToExclude', values.eventsToExclude.filter((id) => !subItemsIds.includes(id)))
    }
  }

  return (
    <>
      <CheckboxFieldComponent
        key={item.id}
        label={intl.formatMessage({ id: item.labelKey })}
        name={item.id}
        checked={allChecked}
        indeterminate={someChecked}
        onChange={handleToggle}
        buttonProps={{
          onClick: () => toggleGroup(item.id),
          name: `${item.id}-expand-button`,
          label: intl.formatMessage({ id: isExpanded ? 'common.collapse' : 'common.expand' }),
          IconComponent: isExpanded ? ExpandLessIcon : ExpandMoreIcon,
        }}
        sx={!isExpanded && isLastItem ? {
          '&.MuiFormControl-root': {
            borderBottom: 'none',
          },
        } : {}}
      />

      <Collapse in={isExpanded}>
        <FormGroupComponent groupInGroup={true}>
          {item.subItems.map((subItem, index) => {
            const isChecked = !values.eventsToExclude.includes(subItem.id)

            return (
              <CheckboxFieldComponent
                key={subItem.id}
                label={intl.formatMessage({ id: subItem.labelKey })}
                name={subItem.id}
                checked={isChecked}
                onChange={() => updateCheckedState(isChecked, values.eventsToExclude, subItem.id, setFieldValue)}
              />
            )
          })}
        </FormGroupComponent>
      </Collapse>
    </>
  )
}

const ManageEventsModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isSubmitting = useSelector(isSubmittingPromotionSettings)
  const eventsToExclude = useSelector(getEventsToExclude)
  const modalPageName = useSelector(getOpenedModal)

  const [expandedGroups, setExpandedGroups] = React.useState<{ [key: string]: boolean }>({})
  const open = modalPageName === MANAGE_EVENTS_MODAL_NAME

  const handleCloseAction = () => {
    dispatch(setPrimaryModalPageName(''))
  }

  const handleSubmitAction = (values: {
    eventsToExclude: string[]
  }) => {
    dispatch(
      requestEventsSettingsChangeAction(values.eventsToExclude),
    )
  }

  const {
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    isValid,
    dirty,
  } = useFormik({
    initialValues: { eventsToExclude },
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (!open) {
      resetForm()
      setExpandedGroups({})
    }
  }, [open, resetForm])

  const handleSelectAll = () => {
    setFieldValue(
      'eventsToExclude',
      values.eventsToExclude.length === 0 ? ALL_EVENT_ITEMS_IDS : [],
    )
  }

  const toggleGroup = (key: string) => {
    setExpandedGroups((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'common.events.manage' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={isSubmitting}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer divider={false} spacing={2}>
                <FormLayoutItem xs={12}>
                  <CheckboxFieldComponent
                    label={intl.formatMessage({ id: 'common.all' })}
                    name='all'
                    checked={values.eventsToExclude.length === 0}
                    indeterminate={
                      values.eventsToExclude.length > 0 &&
                      values.eventsToExclude.length < ALL_EVENT_ITEMS_IDS.length
                    }
                    onChange={handleSelectAll}
                  />
                </FormLayoutItem>

                {
                  GROUPED_EVENTS.map((group) => (
                    <GroupItem
                      key={group.key}
                      group={group}
                      values={values}
                      setFieldValue={setFieldValue}
                      expandedGroups={expandedGroups}
                      toggleGroup={toggleGroup}
                    />
                  ))
                }
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>
          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='manageEventsModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />
            <ModalButtonComponent
              name='manageEventsModalSubmitButton'
              loading={isSubmitting}
              onClick={(e) => handleSubmitAction(values)}
              disabled={isSubmitting || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ManageEventsModalContainer
