import React from 'react'
import { Box, useTheme } from '@mui/material'

import { DEFAULT_BORDER_RADIUS, DEFAULT_BUTTON_TRANSITION } from '@constants/ui.constants'
import { trackEvent, TRACKING_ACTIONS } from '@utils/tracking.utils'

import HeaderBaseTypography from './HeaderBaseTypography.component'

export interface ActionButtonComponentProps {
  name: string
  notification: Notifications.NotificationItem
  color: string
  hoverColor: string
  label: string
  disabled: boolean
  onClick: (notification: Notifications.NotificationItem) => void
}

export const ActionButtonComponent: React.FC<ActionButtonComponentProps> = ({
  name,
  color,
  hoverColor,
  label,
  disabled,
  onClick,
  notification,
}) => {
  const theme = useTheme()
  const hoverColorToUse = hoverColor || theme.palette.new.versatile_violet_5
  const colorToUse = color || theme.palette.new.versatile_violet

  const handleClick = () => {
    if (!disabled) {
      trackEvent({
        componentName: name,
        actionName: TRACKING_ACTIONS.CLICK,
      }, {
        notification,
      })

      onClick(notification)
    }
  }

  return (
    <Box
      data-testid={name}
      display='flex'
      flexDirection='row'
      alignItems='center'
      gap='5px'
      onClick={handleClick}
      role='button'
      tabIndex={0}
      sx={disabled ? {
        cursor: 'not-allowed',
      } : {
        cursor: 'pointer',
        borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
        transition: DEFAULT_BUTTON_TRANSITION,
        padding: '3px',
        '&:hover': {
          backgroundColor: hoverColorToUse,
        },
      }}
    >
      <HeaderBaseTypography
        color={disabled ? theme.palette.new.business_black_40 : colorToUse}
      >
        {label}
      </HeaderBaseTypography>

      <Box
        sx={{
          width: '8px',
          height: '8px',
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,

          ...(disabled ? {
            border: '1px solid',
            borderColor: theme.palette.new.business_black_20,
          } : {
            backgroundColor: colorToUse,
          }),
        }}
      />
    </Box>
  )
}

export default ActionButtonComponent
