import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { useRouteMatch } from 'react-router-dom'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin, getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { NOTIFICATIONS_SINKS_MODAL_NAME } from '@constants/modals.constants'

import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import SwitchFieldComponent from '@base/forms/SwitchField'
import AutocompleteChipsFieldComponent from '@base/autocomplete/AutocompleteChipsField'

import { updateNotificationsSinkAction, createNotificationsSinkAction } from '@redux/modules/notifications/notifications.actions'
import { isSubmittingNotificationsSinks } from '@redux/modules/notifications/notifications.selectors'

import {
  DEFAULT_NOTIFICATION_SINK_CONFIG,
  NOTIFICATIONS_PLATFORMS,
  NOTIFICATIONS_PLATFORMS_LIST,
} from '@constants/notifications.constants'

import validations from './NotificationsSinksModal.validations'

export interface NotificationsSinksModalValues extends Notifications.NotificationSinkConfig {}

export interface NotificationsSinksModalDetails extends Common.ModalDetails {
  sink: Notifications.NotificationSinkItem
}

interface NotificationsSinksModalContainerProps {
  global?: boolean
}

const NotificationsSinksModalContainer: React.FC<NotificationsSinksModalContainerProps> = ({ global = false }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const isSubmitting = useSelector(isSubmittingNotificationsSinks)
  const companyId = useSelector(getSelectedCompanyId)
  const modalPageName = useSelector(getOpenedModal)

  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()
  const { sink } = useSelector((state) => getModalDetails<NotificationsSinksModalDetails>(state))

  const open = modalPageName === NOTIFICATIONS_SINKS_MODAL_NAME
  const isEdit = Boolean(sink?.id)

  const initialValues = useMemo(() => {
    if (sink) {
      return sink as NotificationsSinksModalValues
    }

    return {
      ...DEFAULT_NOTIFICATION_SINK_CONFIG,
      companyId: global ? '' : companyId,
      useCaseId: global ? '' : usecase,
    } as NotificationsSinksModalValues
  }, [
    sink,
    companyId,
    usecase,
    global,
  ])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: NotificationsSinksModalValues) => {
    const config: Partial<Notifications.NotificationSinkConfig> = {
      name: values.name,
      description: values.description,
      enabled: values.enabled,
      platform: values.platform,
    }

    if (!global) {
      config.companyId = companyId
      config.useCaseId = usecase
    }

    if (isEdit) {
      config.id = values.id!
    }

    if (values.platform === NOTIFICATIONS_PLATFORMS.SLACK) {
      config.channel = values.channel.trim()
    }

    if (values.platform === NOTIFICATIONS_PLATFORMS.EMAIL) {
      config.ignore_emails = values.ignore_emails
      config.add_emails = values.add_emails
      config.subject = values.subject
      config.unsubscribe_group_id = Number(values.unsubscribe_group_id)
      config.reply_email = values.reply_email
      config.reply_email_name = values.reply_email_name
      config.from_email = values.from_email
      config.from_email_name = values.from_email_name
      config.template_id = values.template_id
    }

    if (isEdit) {
      dispatch(
        updateNotificationsSinkAction(config as Notifications.UpdateNotificationsSinkRequest),
      )
    } else {
      dispatch(
        createNotificationsSinkAction(config as Notifications.CreateNotificationsSinkRequest),
      )
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: validations(intl),
  })

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <SidePanelComponent
      open={open && isAdmin}
      title={intl.formatMessage({ id: isEdit ? 'notifications.sinks.edit' : 'notifications.sinks.add' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'notifications.sinks.general_config' })}
              >
                <FormLayoutItem xs={12} hidden={!isEdit}>
                  <TextFieldComponent
                    name='id'
                    touched={touched.id}
                    errors={errors.id}
                    value={values.id}
                    label={intl.formatMessage({ id: 'notifications.sinks.fields.id' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12} container={true} hidden={global}>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='companyId'
                      label={intl.formatMessage({ id: 'notifications.sinks.fields.companyId' })}
                      value={values.companyId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.companyId}
                      touched={touched.companyId}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='useCaseId'
                      label={intl.formatMessage({ id: 'notifications.sinks.fields.useCaseId' })}
                      value={values.useCaseId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.useCaseId}
                      touched={touched.useCaseId}
                      disabled={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='name'
                      label={intl.formatMessage({ id: 'notifications.sinks.fields.name' })}
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.name}
                      touched={touched.name}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={6}>
                    <SelectFieldComponent
                      name='platform'
                      label={intl.formatMessage({ id: 'notifications.sinks.fields.platform' })}
                      value={values.platform}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={NOTIFICATIONS_PLATFORMS_LIST}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SwitchFieldComponent
                      name='enabled'
                      label={intl.formatMessage({ id: 'notifications.sinks.fields.enabled' })}
                      value={values.enabled}
                      checked={values.enabled}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      useLabelPlaceholder={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='description'
                    label={intl.formatMessage({ id: 'notifications.sinks.fields.description' })}
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors.description}
                    touched={touched.description}
                    multiline={true}
                    rows={2}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
              <FormLayoutItemsContainer
                hidden={values.platform !== NOTIFICATIONS_PLATFORMS.SLACK}
                title={intl.formatMessage({ id: 'notifications.sinks.slack_config' })}
              >
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='channel'
                      label={intl.formatMessage({ id: 'notifications.sinks.fields.channel' })}
                      value={values.channel}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.channel}
                      touched={touched.channel}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>
              <FormLayoutItemsContainer
                hidden={values.platform !== NOTIFICATIONS_PLATFORMS.EMAIL}
                title={intl.formatMessage({ id: 'notifications.sinks.email_config' })}
              >
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='subject'
                      label={intl.formatMessage({ id: 'notifications.sinks.fields.subject' })}
                      value={values.subject}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.subject}
                      touched={touched.subject}
                    />
                  </FormLayoutItem>

                  <FormLayoutItem xs={12} container={true}>
                    <FormLayoutItem xs={6}>
                      <TextFieldComponent
                        name='reply_email'
                        label={intl.formatMessage({ id: 'notifications.sinks.fields.reply_email' })}
                        value={values.reply_email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.reply_email}
                        touched={touched.reply_email}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={6}>
                      <TextFieldComponent
                        name='reply_email_name'
                        label={intl.formatMessage({ id: 'notifications.sinks.fields.reply_email_name' })}
                        value={values.reply_email_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.reply_email_name}
                        touched={touched.reply_email_name}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>

                  <FormLayoutItem xs={12} container={true}>
                    <FormLayoutItem xs={6}>
                      <TextFieldComponent
                        name='from_email'
                        label={intl.formatMessage({ id: 'notifications.sinks.fields.from_email' })}
                        value={values.from_email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.from_email}
                        touched={touched.from_email}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={6}>
                      <TextFieldComponent
                        name='from_email_name'
                        label={intl.formatMessage({ id: 'notifications.sinks.fields.from_email_name' })}
                        value={values.from_email_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.from_email_name}
                        touched={touched.from_email_name}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>

                  <FormLayoutItem xs={12} container={true}>
                    <FormLayoutItem xs={6}>
                      <TextFieldComponent
                        name='template_id'
                        label={intl.formatMessage({ id: 'notifications.sinks.fields.template_id' })}
                        value={values.template_id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.template_id}
                        touched={touched.template_id}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={6}>
                      <TextFieldComponent
                        name='unsubscribe_group_id'
                        label={intl.formatMessage({ id: 'notifications.sinks.fields.unsubscribe_group_id' })}
                        value={values.unsubscribe_group_id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='number'
                        errors={errors.unsubscribe_group_id}
                        touched={touched.unsubscribe_group_id}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>

                  <FormLayoutItem xs={12} container={true}>
                    <FormLayoutItem xs={6}>
                      <AutocompleteChipsFieldComponent<string>
                        name='ignore_emails'
                        label={intl.formatMessage({ id: 'notifications.sinks.fields.ignore_emails' })}
                        placeholder={intl.formatMessage({ id: 'notifications.sinks.fields.ignore_emails.placeholder' })}
                        floatingHelp={intl.formatMessage({ id: 'notifications.sinks.fields.ignore_emails.help' })}
                        value={values.ignore_emails}
                        options={[]}
                        freeSolo={true}
                        multiple={true}
                        getOptionLabel={(item: string) => item}
                        handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                          handleChange({
                            target: {
                              name: 'ignore_emails',
                              value: newValue,
                            },
                          })
                        }}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={6}>
                      <AutocompleteChipsFieldComponent<string>
                        name='add_emails'
                        label={intl.formatMessage({ id: 'notifications.sinks.fields.add_emails' })}
                        placeholder={intl.formatMessage({ id: 'notifications.sinks.fields.add_emails.placeholder' })}
                        floatingHelp={intl.formatMessage({ id: 'notifications.sinks.fields.add_emails.help' })}
                        value={values.add_emails}
                        options={[]}
                        freeSolo={true}
                        multiple={true}
                        getOptionLabel={(item: string) => item}
                        handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                          handleChange({
                            target: {
                              name: 'add_emails',
                              value: newValue,
                            },
                          })
                        }}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='notificationsSinksModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='notificationsSinksModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid || !dirty}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default NotificationsSinksModalContainer
