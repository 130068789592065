import React from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { FormikProps } from 'formik'
import get from 'lodash.get'

import TextFieldComponent from '@base/forms/TextField'
import IconButtonComponent from '@base/buttons/IconButton'
import DeleteIcon from '@icons/delete.icon'
import PlusIcon from '@icons/plus.icon'

import { FormLayoutItem } from '@base/forms/FormLayout'

interface KeyValuePair {
  key: string
  value: string
}

interface KeyValueFieldArrayProps {
  name: string
  form: FormikProps<any>
  remove: (index: number) => void
  push: (value: KeyValuePair) => void
}

const KeyValueMapField: React.FC<KeyValueFieldArrayProps> = ({
  name,
  remove,
  push,
  form,
}) => {
  const intl = useIntl()
  const globalError = typeof form.errors[name] === 'string' ? form.errors[name] : ''
  const fieldValues = form.values[name] as KeyValuePair[]
  const handleRemove = (index: number) => {
    remove(index)
  }

  const handleAddPair = () => {
    push({ key: '', value: '' })
  }

  return (
    <FormLayoutItem xs={12} container={true} data-testid={name}>
      {
        fieldValues?.map((pair, index) => (
          <FormLayoutItem
            xs={12}
            key={index}
            container={true}
          >
            <FormLayoutItem xs={12} container={true}>
              <FormLayoutItem
                xs={true}
              >
                <TextFieldComponent
                  name={`${name}.${index}.key`}
                  label={intl.formatMessage({ id: 'common.forms.key' })}
                  placeholder={intl.formatMessage({ id: 'common.forms.key' })}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  value={pair.key}
                  touched={get(form.touched, `${name}.${index}.key`, false) as boolean}
                  errors={((get(form.errors, `${name}.${index}.key`, '') as string) || globalError) as string}
                />
              </FormLayoutItem>
              <FormLayoutItem
                xs='auto'
              >
                <Box
                  display='flex'
                  marginTop='18px'
                >
                  <IconButtonComponent
                    name={`removePair-${index}`}
                    color='secondary'
                    onClick={() => handleRemove(index)}
                    label={intl.formatMessage({ id: 'common.removePair' })}
                    IconComponent={DeleteIcon}
                  />
                </Box>
              </FormLayoutItem>
            </FormLayoutItem>
            <FormLayoutItem xs={12}>
              <TextFieldComponent
                name={`${name}.${index}.value`}
                label={intl.formatMessage({ id: 'common.forms.value' })}
                placeholder={intl.formatMessage({ id: 'common.forms.value' })}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={pair.value}
                multiline={true}
                rows={4}
                touched={get(form.touched, `${name}.${index}.value`, false) as boolean}
                errors={get(form.errors, `${name}.${index}.value`, '') as string}
              />
            </FormLayoutItem>
          </FormLayoutItem>
        ))
      }

      <FormLayoutItem xs={12}>
        <IconButtonComponent
          name='addPair'
          onClick={handleAddPair}
          label={intl.formatMessage({ id: 'common.addPair' })}
          IconComponent={PlusIcon}
        />
      </FormLayoutItem>
    </FormLayoutItem>
  )
}

export default KeyValueMapField
