export enum PROMOTION_DAYS {
  /** Static holidays */
  VALENTINES_DAY = 'VALENTINES_DAY',
  WOMENS_DAY = 'WOMENS_DAY',
  EARTH_DAY = 'EARTH_DAY',
  HALLOWEEN = 'HALLOWEEN',
  SINGLES_DAY = 'SINGLES_DAY',
  NIKOLAUS = 'NIKOLAUS',
  CHRISTMAS = 'CHRISTMAS',

  /** Moveable holidays */
  EASTER = 'EASTER',
  MOTHERS_DAY = 'MOTHERS_DAY',
  FATHERS_DAY = 'FATHERS_DAY',
  PRIME_DEAL_DAYS = 'PRIME_DEAL_DAYS',
  BLACK_WEEK = 'BLACK_WEEK',
  CYBER_MONDAY = 'CYBER_MONDAY',
  SUPER_BOWL = 'SUPER_BOWL',
}

export const PROMOTION_DAYS_LIST = Object.keys(PROMOTION_DAYS) as PROMOTION_DAYS[]

export const PROMOTION_DATES_TO_LABELS_MAP: { [key in PROMOTION_DAYS]: string } = {
  [PROMOTION_DAYS.VALENTINES_DAY]: 'common.events.valentines_day',
  [PROMOTION_DAYS.WOMENS_DAY]: 'common.events.womens_day',
  [PROMOTION_DAYS.EARTH_DAY]: 'common.events.earth_day',
  [PROMOTION_DAYS.HALLOWEEN]: 'common.events.halloween',
  [PROMOTION_DAYS.SINGLES_DAY]: 'common.events.singles_day',
  [PROMOTION_DAYS.NIKOLAUS]: 'common.events.nikolaus',
  [PROMOTION_DAYS.CHRISTMAS]: 'common.events.christmas',
  [PROMOTION_DAYS.EASTER]: 'common.events.easter',
  [PROMOTION_DAYS.MOTHERS_DAY]: 'common.events.mothers_day',
  [PROMOTION_DAYS.FATHERS_DAY]: 'common.events.fathers_day',
  [PROMOTION_DAYS.PRIME_DEAL_DAYS]: 'common.events.prime_day',
  [PROMOTION_DAYS.BLACK_WEEK]: 'common.events.black_week',
  [PROMOTION_DAYS.CYBER_MONDAY]: 'common.events.cyber_monday',
  [PROMOTION_DAYS.SUPER_BOWL]: 'common.events.super_bowl',
}

export const SCHOOL_HOLIDAYS_GROUP_KEY = 'school_holidays'
export const PROMOTIONS_GROUP_KEY = 'promotions'
