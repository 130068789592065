import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getAxisFormatterByType, getChartDataPointKeys } from '@utils/recommendation-artifacts.utils'
import { ChartConfig, getChartItemColor } from '@utils/svg.utils'

import VisualizationComposedChartComponent from '@components/recommendation-artifacts/VisualizationComposedChart'
import SectionTileComponent from '@base/tiles/SectionTile'

export interface VisualizationItemComponentProps {
  /**
   * Unique identifier of the visualisation
   */
  id: string
  /**
   * Visualisation definition
   */
  visualization: RecommendationArtifacts.Visualisations
  /**
   * Comparison mode flag. If true, the visualisation will be displayed in comparison mode
   * The fields to show/hide are defined in the visualisation configuration
   * @default false
   */
  comparisonMode?: boolean
  /**
   * Loading flag. If true, the visualisation will be displayed in loading state
   * @default false
   */
  loading?: boolean
  /**
   * Wrapped flag. If true, the visualisation will be wrapped in a SectionTile
   * @default true
   */
  wappred?: boolean
  /**
   * Minimum height of the visualisation
   * @default '360px'
   */
  minHeight?: string
}

const VisualizationItemComponent: React.FC<VisualizationItemComponentProps> = ({
  id,
  visualization,
  comparisonMode = false,
  loading = false,
  wappred = true,
  minHeight = '360px',
}) => {
  const {
    title,
    xKey,
    xLabel,
    xType,
    yType = 'number',
    barChartStackOffset,
    barChartBarSizes,
    items,
    type,
    data,
    yLabel,
    unitLabel,
    actualValueKeys,
    showAllXTicks,
    rotateXLabels,
    maxXLabelsHeight,
    xFormattingOptions,
    yFormattingOptions,
  } = visualization

  const intl = useIntl()
  const chartConfig = useMemo(() => ({
    showAllXTicks,
    rotateXLabels,
    maxXLabelsHeight,
    barChartStackOffset,
    barChartBarSizes,
  } as ChartConfig), [
    showAllXTicks,
    rotateXLabels,
    maxXLabelsHeight,
    barChartStackOffset,
    barChartBarSizes,
  ])

  const xTickFormatter = useMemo(() => {
    return getAxisFormatterByType(intl, xType, true, chartConfig, xFormattingOptions) as (value: string) => string
  }, [intl, xType, chartConfig, xFormattingOptions])

  const yTickFormatter = useMemo(() => {
    return getAxisFormatterByType(intl, yType, true, chartConfig, yFormattingOptions) as (value: string) => string
  }, [intl, yType, chartConfig, yFormattingOptions])

  const xTooltipFormatter = useMemo(() => {
    return getAxisFormatterByType(intl, xType, false, chartConfig, xFormattingOptions) as (value: string) => React.ReactNode
  }, [intl, xType, chartConfig, xFormattingOptions])

  const yTooltipFormatter = useMemo(() => {
    return getAxisFormatterByType(intl, yType, false, chartConfig, yFormattingOptions) as (value: string) => React.ReactNode
  }, [intl, yType, chartConfig, yFormattingOptions])

  const chartItemsList = useMemo(() => getChartDataPointKeys({
    items,
    type,
    actualValueKeys,
    comparisonMode,
  }), [items, comparisonMode, type, actualValueKeys])

  const tooltipRows = useMemo(() => {
    const rows: Common.TooltipRow<RecommendationArtifacts.VisualisationDataItem>[] = [{
      key: xKey,
      groupId: 'primary',
      label: xLabel,
      valueFormatter: xTooltipFormatter,
    }]

    chartItemsList.forEach((item, index) => {
      rows.push({
        key: item.dataKey,
        label: item.label,
        groupId: 'data',
        legendType: item.type === 'line' ? 'line' : 'square',
        legendColor: getChartItemColor(item.color, item.type!, true),
        valueFormatter: yTooltipFormatter,
      })
    })

    return rows
  }, [
    xKey,
    xLabel,
    chartItemsList,
    xTooltipFormatter,
    yTooltipFormatter,
  ])

  const legendRows = useMemo(() => {
    const rows = [] as Common.ChartLegendItem[]

    chartItemsList.forEach((item, index) => {
      const color = getChartItemColor(item.color, item.type!, true)

      rows.push({
        label: item.label,
        color,
        type: item.type === 'line' ? 'line' : 'square',
      })
    })

    return rows
  }, [
    chartItemsList,
  ])

  const content = useMemo(() => {
    return (
      <VisualizationComposedChartComponent
        data={data}
        xKey={xKey}
        xLabel={xLabel}
        yLabel={yLabel}
        unitLabel={unitLabel}
        tooltipRows={tooltipRows}
        xTickFormatter={xTickFormatter}
        yTickFormatter={yTickFormatter}
        legendRows={legendRows}
        items={chartItemsList}
        chartConfig={chartConfig}
        height={minHeight}
      />
    )
  }, [
    minHeight,
    tooltipRows,
    chartItemsList,
    xTickFormatter,
    yTickFormatter,
    legendRows,
    xKey,
    xLabel,
    yLabel,
    unitLabel,
    data,
    chartConfig,
  ])

  if (!wappred) {
    return content
  }

  return (
    <SectionTileComponent
      name={id}
      title={title}
      contentMinHeight='360px'
      loading={loading}
    >
      {content}
    </SectionTileComponent>
  )
}

export default VisualizationItemComponent
