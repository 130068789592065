import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { useRouteMatch } from 'react-router-dom'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin, getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { NOTIFICATIONS_SEND_NOTIFICATION_MODAL_NAME } from '@constants/modals.constants'
import { arrayBasedConfigToMapBasedConfig } from '@utils/notifications.utils'

import KeyValueMapField from '@base/forms/KeyValueMapField'
import TextFieldComponent from '@base/forms/TextField'
import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField'

import { sendNotificationAction } from '@redux/modules/notifications/notifications.actions'
import { isSendingNotification } from '@redux/modules/notifications/notifications.selectors'
import {
  NOTIFICATIONS_TYPES,
  NOTIFICATIONS_SEVERITIES,
  NOTIFICATIONS_TYPES_LIST,
  NOTIFICATIONS_SEVERITIES_LIST,
} from '@constants/notifications.constants'

import validations from './NotificationsSendModal.validations'

export interface NotificationsSendModalValues extends Notifications.SendNotificationRequest {
  internalContent: Notifications.InternalNotificationConfigMap[]
}

const NotificationsSendModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const isSubmitting = useSelector(isSendingNotification)
  const selectedCompanyId = useSelector(getSelectedCompanyId)
  const modalPageName = useSelector(getOpenedModal)

  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()
  const open = modalPageName === NOTIFICATIONS_SEND_NOTIFICATION_MODAL_NAME

  const initialValues: NotificationsSendModalValues = {
    companyId: selectedCompanyId,
    useCaseId: usecase,
    type: NOTIFICATIONS_TYPES.TEST_NOTIFICATION,
    severity: NOTIFICATIONS_SEVERITIES.INFO,
    content: {},
    internalContent: [],
    origin: 'cockpit',
  }

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: NotificationsSendModalValues) => {
    const {
      internalContent,
      ...restValues
    } = values

    dispatch(
      sendNotificationAction({
        ...restValues,
        content: arrayBasedConfigToMapBasedConfig(internalContent),
      }),
    )
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: validations(intl),
  })

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [
    open,
    dispatch,
    resetForm,
  ])

  return (
    <SidePanelComponent
      open={open && isAdmin}
      title={intl.formatMessage({ id: 'notifications.dispatched.send_notification' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'notifications.dispatched.generalConfig' })}
              >
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={4}>
                    <TextFieldComponent
                      name='companyId'
                      touched={touched.companyId}
                      errors={errors.companyId}
                      value={values.companyId}
                      label={intl.formatMessage({ id: 'notifications.dispatched.fields.companyId' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={4}>
                    <TextFieldComponent
                      name='useCaseId'
                      touched={touched.useCaseId}
                      errors={errors.useCaseId}
                      value={values.useCaseId}
                      label={intl.formatMessage({ id: 'notifications.dispatched.fields.useCaseId' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={4}>
                    <TextFieldComponent
                      name='origin'
                      touched={touched.origin}
                      errors={errors.origin}
                      value={values.origin}
                      label={intl.formatMessage({ id: 'notifications.dispatched.fields.origin' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>

                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={6}>
                    <AutocompleteSelectFieldComponent<Notifications.NotificationType>
                      name='type'
                      label={intl.formatMessage({ id: 'notifications.dispatched.fields.type' })}
                      floatingHelp={intl.formatMessage({ id: 'notifications.dispatched.fields.type.help' })}
                      placeholder={intl.formatMessage({ id: 'notifications.dispatched.fields.type.placeholder' })}
                      value={values.type}
                      options={NOTIFICATIONS_TYPES_LIST.map((item) => item.value)}
                      getOptionLabel={(item: string) => item}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue: string) => {
                        setFieldValue('type', selectedValue)
                      }}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <AutocompleteSelectFieldComponent<Notifications.Severity>
                      name='severity'
                      label={intl.formatMessage({ id: 'notifications.dispatched.fields.severity' })}
                      floatingHelp={intl.formatMessage({ id: 'notifications.dispatched.fields.severity.help' })}
                      placeholder={intl.formatMessage({ id: 'notifications.dispatched.fields.severity.placeholder' })}
                      value={values.severity}
                      options={NOTIFICATIONS_SEVERITIES_LIST.map((item) => item.value)}
                      getOptionLabel={(item: string) => item}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue: string) => {
                        setFieldValue('severity', selectedValue)
                      }}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'notifications.dispatched.content' })}
              >
                <FormikProvider value={formik}>
                  <FieldArray
                    name='internalContent'
                    component={KeyValueMapField as any}
                  />
                </FormikProvider>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='notificationsSendModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='notificationsSendModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              label={intl.formatMessage({ id: 'notifications.dispatched.send_notification' })}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default NotificationsSendModalContainer
