import { IntlShape } from 'react-intl'

import * as Yup from 'yup'

const validation = (intl: IntlShape) => {
  const shape = Yup.object().shape({
    id: Yup.string().required(),
    templateId: Yup.string().required(),
  })

  return shape
}

export default validation
