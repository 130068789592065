/* istanbul ignore file */

import { fork, all } from 'redux-saga/effects'

import * as socratesSaga from './socrates/socrates.saga'
import * as commonSaga from './common/common.saga'
import * as customerSaga from './customer/customer.saga'
import * as parametersSaga from './parameters/parameters.saga'
import * as hermesSaga from './hermes/hermes.saga'
import * as modalManagerSaga from './modal-manager/modal-manager.saga'
import * as trainingFilesSaga from './training-files/training-files.saga'
import * as parametersTemplatesSaga from './parameters-templates/parameters-templates.saga'
import * as optimizeSaga from './optimize/optimize.saga'
import * as fileServiceSaga from './file-service/file-service.saga'
import * as pipelinesSaga from './pipelines/pipelines.saga'
import * as recommendationArtifactsSaga from './recommendation-artifacts/recommendation-artifacts.saga'
import * as snapshotsSaga from './snapshots/snapshots.saga'

import analyzeSaga from './analyze/analyze.saga'
import artifactsSaga from './artifacts/artifacts.saga'
import chatSaga from './chat/chat.saga'
import monitorSaga from './monitor/monitor.saga'
import insightsSaga from './insights/insights.saga'
import useCaseSaga from './use-case/use-case.saga'
import notificationsSaga from './notifications/notifications.saga'

export function* saga() {
  yield all([
    ...Object.values(analyzeSaga),
    ...Object.values(artifactsSaga),
    ...Object.values(chatSaga),
    ...Object.values(parametersSaga),
    ...Object.values(socratesSaga),
    ...Object.values(commonSaga),
    ...Object.values(optimizeSaga),
    ...Object.values(customerSaga),
    ...Object.values(pipelinesSaga),
    ...Object.values(monitorSaga),
    ...Object.values(useCaseSaga),
    ...Object.values(hermesSaga),
    ...Object.values(modalManagerSaga),
    ...Object.values(trainingFilesSaga),
    ...Object.values(parametersTemplatesSaga),
    ...Object.values(fileServiceSaga),
    ...Object.values(recommendationArtifactsSaga),
    ...Object.values(notificationsSaga),
    ...Object.values(snapshotsSaga),
    ...Object.values(insightsSaga),
  ].map(fork))
}
