/* eslint-disable  */
export const NOTIFICATIONS_URI = window._env_.NOTIFICATIONS_URI 
export const FILES_URI = window._env_.FILES_URI
export const HERA_URI = window._env_.HERA_URI
export const ATHENA_URI = window._env_.ATHENA_URI
export const HERMES_URI = window._env_.HERMES_URI
export const SOCRATES_URI = window._env_.SOCRATES_URI
export const CUSTOMER_URI = window._env_.CUSTOMER_URI
export const CHAT_URI = window._env_.CHAT_URI
export const USE_CASE_URI = window._env_.USE_CASE_URI
export const PIPELINES_URI = window._env_.PIPELINES_URI
export const SNAPSHOTS_URI = window._env_.SNAPSHOTS_URI
export const KEYCLOAK_URI = window._env_.KEYCLOAK_URI
export const KEYCLOAK_REALM = window._env_.KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID = window._env_.KEYCLOAK_CLIENT_ID
export const COCKPIT_GA_ID = window._env_.COCKPIT_GA_ID
export const COCKPIT_GTM_ID = window._env_.COCKPIT_GTM_ID
export const COCKPIT_BUILD_ENV = window._env_.COCKPIT_BUILD_ENV
export const PARETOS_COMPANY_ID = window._env_.PARETOS_COMPANY_ID
export const MUI_X_LICENSE_KEY = window._env_.MUI_X_LICENSE_KEY
export const COCKPIT_POSTHOG_KEY = window._env_.COCKPIT_POSTHOG_KEY
export const COCKPIT_POSTHOG_HOST = window._env_.COCKPIT_POSTHOG_HOST
export const KEYCLOAK_ADMIN_ROLE = 'paretos-admin'
export const CHAT_USER_ROLE = 'chat-user'
/* eslint-enable */
