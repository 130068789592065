export const VERSION_OF_DATA_GRID = 1
export const DEFAULT_DEBOUNCE_INTERVAL = 500
export const DEFAULT_COL_MIN_WIDTH = 150
export const DEFAULT_COL_WIDTH = 150
export const DEFAULT_ACTIONS_COL_WIDTH = 48
export const DEFAULT_BOOLEAN_COL_MIN_WIDTH = 200
export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100]
export const DEFAULT_DATA_GRID_STATE = {
  pagination: {
    paginationModel: {
      pageSize: DEFAULT_PAGE_SIZE,
    },
  },
  filter: {
    filterModel: {
      items: [],
      quickFilterValues: [''],
    },
  },
}

export const DEFAULT_DATA_GIRD_AUTOSIZE_OPTIONS = {
  includeHeaders: true,
  includeOutliers: true,
  outliersFactor: 1,
}

export const CUSTOM_COL_HEADER_CLASS_NAME = 'customColHeader'
export const CUSTOM_COL_GRID_PANEL_ANCHOR = '[data-id="gridPanelAnchor"]'
export const FORCED_RIGHT_BORDER_CLASS_NAME = 'forced-right-border'
export const FORCED_LEFT_BORDER_CLASS_NAME = 'forced-left-border'
export const COLUMN_GROUP_HEADER_HEIGHT = 40
export const COLUMN_HEADER_HEIGHT = 58
export const ROW_HEIGHT = 38

export enum DATA_GRIDS {
  TRAINING_DATA_FLAT_TABLE = 'trainingDataFlatTable',
  TRAINING_DATA_TABLE = 'trainingDataTable',
  EXPORT_TABLE = 'exportTable',
  CONNECTORS_TABLE = 'connectorsTable',
  COMPANIES_TABLE = 'companiesTable',
  COMPANY_USERS_TABLE = 'companyUsersTable',
  ANALYZE_TABLE = 'analyzeTable',
  BACKTESTING_TABLE = 'backtestingTable',
  LIVE_MONITORING_TABLE = 'liveMonitoringTable',
  PIPELINE_OVERVIEW = 'pipelineOverview',
  USE_CASE_PIPELINES = 'useCasePipelines',
  GLOBAL_PIPELINES = 'globalPipelines',
  OPTIMIZATION_CONSTRAINTS = 'optimizationConstraints',
  OPTIMIZATION_CONSTRAINTS_ANALYZE_OVERVIEW = 'optimizationConstraintsAnalyzeOverview',
  TOP_ITEMS_ARTIFACT = 'topItemsArtifact',
  ARTICLES_OVERVIEW_TABLE_ARTIFACT = 'articlesOverviewTableArtifact',
  FILE_BROWSER_PREVIEW_TABLE = 'fileBrowserPreviewTable',
  SNAPSHOTS_TABLE = 'snapshotsTable',
  SNAPSHOTS_TABLE_DETAILS = 'snapshotsTableDetails',
  DISPATCHED_NOTIFICATIONS_TABLE = 'dispatchedNotificationsTable',
  NOTIFICATIONS_ROUTINGS_TABLE = 'notificationsRoutingsTable',
  NOTIFICATIONS_SINKS_TABLE = 'notificationsSinksTable',
  NOTIFICATIONS_SINKS_TABLE_GLOBAL = 'notificationsSinksTableGlobal',
  NOTIFICATIONS_TEMPLATES_TABLE = 'notificationsTemplatesTable',
  NOTIFICATIONS_STATUS_TABLE = 'notificationsStatusTable',
}

export const DATA_GRID_CUSTOM_CLASS_NAMES = {
  ODD: 'odd',
  EVEN: 'even',
}
