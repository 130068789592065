import produce from 'immer'

import { NotificationsState, ReducerPayload } from '@redux/modules/types'
import { NOTIFICATIONS_PAGINATION_META_DEFAULT_VALUES } from '@constants/notifications.constants'
import { generateNotificationGraph } from '@utils/notifications.utils'

export const initialState: NotificationsState = {
  notifications: {
    items: [],
    total: 0,
    unreadCount: 0,
  },
  dispatchedNotificationsList: [],
  notificationsRoutingsList: [],
  notificationsSinksList: [],
  notificationsTemplatesList: [],
  notificationsRoutingGraph: {
    nodes: [],
    edges: [],
  },
  fetchingKeys: [],
  unreadNotificationsCount: 0,
  notificationsPaginationMeta: NOTIFICATIONS_PAGINATION_META_DEFAULT_VALUES,
}

export const receiveNotificationsPaginationMetaReducer = (state: NotificationsState, action: ReducerPayload<Notifications.NotificationsPaginationMetaAction>) => {
  const nextState = produce(state, (draftState) => {
    draftState.notificationsPaginationMeta = {
      ...draftState.notificationsPaginationMeta,
      ...action.payload,
    }
  })

  return nextState
}

export const receiveUnreadNotificationsCountReducer = (state: NotificationsState, action: ReducerPayload<number>) => {
  const nextState = produce(state, (draftState) => {
    draftState.unreadNotificationsCount = action.payload
  })

  return nextState
}

export const receiveNotificationsReducer = (state: NotificationsState, action: ReducerPayload<Notifications.NotificationsItems>) => {
  const nextState = produce(state, (draftState) => {
    draftState.notifications = action.payload
  })

  return nextState
}

export const receiveDispatchedNotificationsReducer = (state: NotificationsState, action: ReducerPayload<Notifications.DispatchedNotificationItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.dispatchedNotificationsList = action.payload
  })

  return nextState
}

export const receiveNotificationsRoutingsReducer = (state: NotificationsState, action: ReducerPayload<Notifications.NotificationRoutingItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.notificationsRoutingsList = action.payload
  })

  return nextState
}

export const receiveNotificationsSinksReducer = (state: NotificationsState, action: ReducerPayload<Notifications.NotificationsSinksResponse['items']>) => {
  const nextState = produce(state, (draftState) => {
    draftState.notificationsSinksList = action.payload.map((item) => ({
      ...item,
      ...item.config,
    }))
  })

  return nextState
}

export const receiveNotificationsTemplatesReducer = (state: NotificationsState, action: ReducerPayload<Notifications.NotificationTemplateItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.notificationsTemplatesList = action.payload
  })

  return nextState
}

export const receiveNotificationsRoutingGraphReducer = (state: NotificationsState, action: ReducerPayload<Notifications.NotificationGraphResponse>) => {
  const nextState = produce(state, (draftState) => {
    draftState.notificationsRoutingGraph = generateNotificationGraph(action.payload)
  })

  return nextState
}
