import React from 'react'

export interface ShareIconProps extends Common.IconProps {}

const ShareIcon: React.FC<ShareIconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ShareIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M10.9028 13.59C10.7946 14.4831 10.35 15.3013 9.65953 15.8781C8.9691 16.4548 8.08478 16.7467 7.18668 16.6943C6.28858 16.6418 5.44424 16.249 4.82563 15.5958C4.20702 14.9426 3.86067 14.0782 3.85711 13.1786V11.1343M16.5085 15.5186L1.50425 10.2857C1.25943 10.2003 1.04616 10.0428 0.892427 9.83405C0.738695 9.62524 0.651686 9.37484 0.642822 9.1157V7.71427C0.643881 7.44849 0.727281 7.18957 0.881546 6.97314C1.03581 6.75671 1.25336 6.59341 1.50425 6.5057L16.5085 1.2857C16.6049 1.25316 16.7075 1.24394 16.8081 1.25878C16.9087 1.27363 17.0043 1.31212 17.0872 1.3711C17.17 1.43008 17.2376 1.50786 17.2846 1.59807C17.3315 1.68827 17.3564 1.78831 17.3571 1.88999V14.9143C17.3564 15.0159 17.3315 15.116 17.2846 15.2062C17.2376 15.2964 17.17 15.3742 17.0872 15.4332C17.0043 15.4921 16.9087 15.5306 16.8081 15.5455C16.7075 15.5603 16.6049 15.5511 16.5085 15.5186Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ShareIcon
