import React from 'react'

import {
  Box, SxProps, Theme,
  Typography, useTheme,
} from '@mui/material'

export interface ChartLegendComponentProps {
  /**
   * Custom styles for container
   */
  sx?: SxProps<Theme>,
  /**
   * List of items to be displayed in the legend
   */
  items?: Common.ChartLegendItem[],
}

export const ChartLegendComponent: React.FC<ChartLegendComponentProps> = ({
  sx,
  items,
}) => {
  const theme = useTheme()

  if (!items || items.length === 0) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        padding: theme.spacing(2, 3),
        flexWrap: 'wrap',
        paddingLeft: theme.spacing(10),
        gap: theme.spacing(2),
        ...sx,
      }}
      data-testid={ChartLegendComponent.name}
    >
      {
        items.map((item, index) => {
          const {
            label,
            color = theme.palette.new.black,
            opacity,
            type,
            strokeWidth = 2,
          } = item
          return (
            <Box
              key={`${color}-${index}`}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
                gap: theme.spacing(1),
              }}
            >
              {
                type === 'square' ? (
                  <Box
                    sx={{
                      width: '16px',
                      height: '16px',
                      background: color,
                      opacity,
                      borderRadius: theme.spacing(0.2),
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: '16px',
                      borderRadius: theme.spacing(0.5),
                      height: `${strokeWidth}px`,
                      background: color,
                      opacity,
                    }}
                  />
                )
              }

              <Typography
                variant='body2'
                noWrap={true}
              >
                {label}
              </Typography>
            </Box>
          )
        })
      }
    </Box>
  )
}

export default ChartLegendComponent
