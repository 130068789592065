import { FontStyleOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography'

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h1Alt'
  | 'h2Alt'
  | 'h3Alt'
  | 'h4Alt'
  | 'h5Alt'
  | 'h6Alt'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Alt: true;
    h2Alt: true;
    h3Alt: true;
    h4Alt: true;
    h5Alt: true;
    h6Alt: true;
  }
}

export interface TypographyOptions
  extends Partial<Record<Variant, TypographyStyleOptions> & FontStyleOptions> {}

const typography: TypographyOptions = {
  fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  h1: {
    fontWeight: 500,
    fontSize: '2.8rem',
    letterSpacing: '-0.896px',
    lineHeight: 1.25,
  },
  h2: {
    fontWeight: 500,
    fontSize: '2.4rem',
    letterSpacing: '-0.768px',
    lineHeight: 1,
  },
  h3: {
    fontWeight: 500,
    fontSize: '2rem',
    letterSpacing: '-0.64px',
    lineHeight: 1,
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.6rem',
    letterSpacing: '-0.512px',
    lineHeight: 1.25,
  },
  h5: {
    fontWeight: 500,
    fontSize: '1.2rem',
    letterSpacing: '-0.192px',
    lineHeight: 1.25,
  },
  h6: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.25,
  },
  h1Alt: {
    fontWeight: 400,
    fontSize: '2.8rem',
    letterSpacing: '-0.896px',
    lineHeight: 1,
    fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  },
  h2Alt: {
    fontWeight: 400,
    fontSize: '2.4rem',
    letterSpacing: '-0.768px',
    lineHeight: 1,
    fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  },
  h3Alt: {
    fontWeight: 400,
    fontSize: '2rem',
    letterSpacing: '-0.64px',
    lineHeight: 1,
    fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  },
  h4Alt: {
    fontWeight: 400,
    fontSize: '1.6rem',
    letterSpacing: '-0.512px',
    lineHeight: 1,
    fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  },
  h5Alt: {
    fontWeight: 400,
    fontSize: '1.2rem',
    letterSpacing: '-0.192px',
    lineHeight: 1.25,
    fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  },
  h6Alt: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.25,
    fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  },
  button: {
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'initial',
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.25,
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.25,
    color: '#000000',
    fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.25,
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.25,
  },
  overline: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.25,
    letterSpacing: '0.6px',
    textTransform: 'uppercase',
    color: '#00000066',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.25,
    letterSpacing: '0.24px',
  },
}

export default typography
