import React from 'react'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'

import SettingsIcon from '@icons/settings.icon'
import ContextMenuComponent from '@base/menus/ContextMenu'
import ManageEventsModalContainer from '@containers/modals/manage-events-modal'

export interface InsightsChartMenuComponentProps {
  options: Common.ContextMenuAction[]
}

const InsightsChartMenuComponent: React.FC<InsightsChartMenuComponentProps> = ({
  options = [],
}) => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Box
      data-testid={InsightsChartMenuComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(0.5),
      }}
    >
      <ContextMenuComponent
        name='chartSettingsMenu'
        actions={options}
        label={intl.formatMessage({ id: 'insights.chart.menu.settings' })}
        IconComponent={SettingsIcon}
        buttonColor='tertiary'
      />

      <ManageEventsModalContainer />
    </Box>
  )
}

export default InsightsChartMenuComponent
