import sortBy from 'lodash.sortby'
import get from 'lodash.get'

import {
  USE_CASE_FAMILY_TYPES,
  USE_CASE_ARTIFACTS_TYPES,
  VISIBILITY_STATES,
} from '@constants/use-cases.constants'

import {
  CONNECT_PATH,
  MONITOR_PATH,
  MONITOR_BACKTESTING_PATH,
  MONITOR_LIVE_MONITORING_PATH,
  EXPORT_PATH,
  TRADE_OFFS_PATH,
  ARTICLE_ALLOCATION_RESULTS_PATH,
  ARTICLE_ALLOCATION_ANALYZE_PATH,
  ARTICLE_ALLOCATION_EXPORT_PATH,
  ARTICLE_ALLOCATION_SETUP_PATH,
  ANALYZE_PATH,
  REPLENISHMENT_RESULTS_PATH,
  REPLENISHMENT_ANALYZE_PATH,
  REPLENISHMENT_DISCOVER_PATH,
  REPLENISHMENT_EXPORT_PATH,
  REPLENISHMENT_SETUP_PATH,
} from '@constants/routes.constants'

import { INPUT_TYPES, POSSIBLE_TEMPLATE_TYPES } from '@constants/flow.constants'
import { LEGACY_FILES_IDENTIFIER } from '@constants/files.constants'
import { INVENTORY_DEMO_USE_CASES_IDS } from '@constants/optimize.constants'
import { formatNumberWithSuffix } from '@utils/analysis.utils'

/**
 * @function isUseCaseMenuItemDisabled Decides whether menu item is available
 *
 * @param {String} key Service Key
 * @param {Object} useCaseDetails use case details
 * @param {Boolean} isAdmin true, if user is admin
 *
 * @return {Boolean} true, if disabled
 */
export const isUseCaseMenuItemDisabled = (
  path: string,
  useCaseDetails: UseCase.DetailsExtended,
  isAdmin: boolean,
): boolean => {
  const { demandUseCaseId } = useCaseDetails

  switch (path) {
    case REPLENISHMENT_DISCOVER_PATH: {
      return false
    }

    case REPLENISHMENT_SETUP_PATH:
    case ARTICLE_ALLOCATION_SETUP_PATH: {
      return false
    }

    case REPLENISHMENT_EXPORT_PATH:
    case ARTICLE_ALLOCATION_EXPORT_PATH: {
      return false
    }

    case REPLENISHMENT_RESULTS_PATH:
    case ARTICLE_ALLOCATION_RESULTS_PATH: {
      return false
    }

    case REPLENISHMENT_ANALYZE_PATH: {
      return !demandUseCaseId
    }

    case ARTICLE_ALLOCATION_ANALYZE_PATH: {
      return false
    }

    case MONITOR_PATH:
    case MONITOR_LIVE_MONITORING_PATH:
    case MONITOR_BACKTESTING_PATH: {
      return false
    }

    case CONNECT_PATH: {
      return false
    }

    case ANALYZE_PATH: {
      return false
    }

    case TRADE_OFFS_PATH: {
      const socratesId = getArtifactId(useCaseDetails, USE_CASE_ARTIFACTS_TYPES.TRADEOFFS_V1)

      return !(socratesId)
    }

    case EXPORT_PATH: {
      return false
    }

    default:
      return true
  }
}

/**
 * @function usecaseEnrichment Enrich use case details with additional information
 *
 * @param {Object} useCase use case details
 * @param {Hera.ForecastValue[]} forecastDetails use case forecast deviations
 * @param {UseCase.ForecastParameters[]} forecastParameters use case forecast parameters
 * @param {Array} pipelinesStatuses use case pipelines statuses
 *
 * @return {Object} Enriched use case
 */
export const usecaseEnrichment = ({
  useCase = {} as UseCase.Details,
  forecastDetails = [],
  forecastParameters = [],
} : {
  useCase: UseCase.Details,
  forecastDetails: Hera.ForecastValue[],
  forecastParameters: UseCase.ForecastParameters[],
}): UseCase.DetailsExtended => {
  const sortedOutputParameters = sortBy((useCase.outputParameters || []), 'name')
  const sortedInputParameters = sortBy((useCase.inputParameters || []), 'name')
  const sortedGroupingAttributes = sortBy((useCase.groupingAttributes || []), 'name')

  const forecastAddedValue = forecastDetails ? forecastDetails.find((item) => item.useCaseId === useCase.useCaseId) : null
  const forecastParametersValue = forecastParameters ? forecastParameters.find((item) => item.useCaseId === useCase.useCaseId) : null

  return {
    ...useCase,
    outputParameters: sortedOutputParameters,
    inputParameters: sortedInputParameters,
    groupingAttributes: sortedGroupingAttributes,
    forecastValue: forecastAddedValue || null,
    forecastParameters: forecastParametersValue || null,
  }
}

/**
 * @function getValidMinMax Return valid min&max for API
 *
 * @param {String} num min/max from Form
 *
 * @return {Number}  valid min&max for API
 */
export const getValidMinMax = (num: number | string | null | undefined) => {
  if (num === '' || num === null) {
    return null
  }

  const minMax = Number(num)

  return isNaN(minMax) ? null : minMax
}

/**
 * @function parameterTypeToTemplateTypes Converts parameter type to template type
 *
 * @param {UseCase.ParameterItem} parameterItem parameter details
 *
 * @return {POSSIBLE_TEMPLATE_TYPES}  valid min&max for API
 */
export const parameterTypeToTemplateTypes = (parameterItem: UseCase.ParameterItem): POSSIBLE_TEMPLATE_TYPES => {
  if (parameterItem.inputType === INPUT_TYPES.ACTIVE) {
    return POSSIBLE_TEMPLATE_TYPES.ACTIVE
  }

  if (parameterItem.inputType === INPUT_TYPES.GENERIC) {
    return POSSIBLE_TEMPLATE_TYPES.EXTERNAL
  }

  if (parameterItem.inputType === INPUT_TYPES.PASSIVE) {
    return POSSIBLE_TEMPLATE_TYPES.PASSIVE
  }

  if (!parameterItem.inputType || parameterItem.modelOutputParameterId) {
    return POSSIBLE_TEMPLATE_TYPES.TARGET
  }

  return POSSIBLE_TEMPLATE_TYPES.TARGET
}

/**
 * @function isDemandFamilyCheck Checks whether it's demand use-case type
 *
 * @param {USE_CASE_FAMILY_TYPES} family useCase family
 *
 * @return {Boolean}  true, in case it is demand type
 */
export const isDemandFamilyCheck = (family: USE_CASE_FAMILY_TYPES) => {
  return family === USE_CASE_FAMILY_TYPES.DEMAND
}

/**
 * @function isArticleAllocationFamilyCheck Checks whether it's article allocation use-case type
 *
 * @param {USE_CASE_FAMILY_TYPES} family useCase family
 *
 * @return {Boolean}  true, in case it is article allocation type
 */
export const isArticleAllocationFamilyCheck = (family: USE_CASE_FAMILY_TYPES) => {
  return family === USE_CASE_FAMILY_TYPES.ARTICLE_ALLOCATION
}

/**
 * @function isReplenishmentFamilyCheck Checks whether it's replenishment use-case type
 *
 * @param {USE_CASE_FAMILY_TYPES} family useCase family
 *
 * @return {Boolean}  true, in case it is replenishment type
 */
export const isReplenishmentFamilyCheck = (family: USE_CASE_FAMILY_TYPES) => {
  return family === USE_CASE_FAMILY_TYPES.REPLENISHMENT
}

/**
 * @function isRecommendationFamilyCheck Checks whether it's recomendation use-case types
 *
 * @param {USE_CASE_FAMILY_TYPES} family useCase family
 *
 * @return {Boolean}  true, in case it is recomendation type
 */
export const isRecommendationFamilyCheck = (family: USE_CASE_FAMILY_TYPES) => {
  return [USE_CASE_FAMILY_TYPES.REPLENISHMENT, USE_CASE_FAMILY_TYPES.ARTICLE_ALLOCATION].includes(family)
}

/**
 * @function isOptimizeEnabled Checks whether optimize is enabled for use-case
 *
 * @param {string} useCaseId useCaseId
 *
 * @return {Boolean}  true, in case optimize is enabled
 */
export const isOptimizeEnabled = (useCaseId: string) => {
  if (useCaseId) {
    return INVENTORY_DEMO_USE_CASES_IDS.includes(useCaseId)
  }

  return false
}

/**
 * @function validateFileIdentifier Checks whether it's file identifier is valid
 *
 * @param {String} fileIdentifier File Identifier
 *
 * @return {Boolean}  true, if valid
 */
export const validateFileIdentifier = (fileIdentifier?: string) => {
  if (fileIdentifier) {
    const pattern = '^[A-Za-z_][A-Za-z0-9_]{0,63}$'
    const validator = new RegExp(pattern)

    return validator.test(fileIdentifier)
  }

  return false
}

/**
 * @function isLegacyUploadCheck Checks whether legacy upload route should be used
 *
 * @param {String} fileIdentifier File Identifier
 *
 * @return {Boolean}  true, if legacy upload route should be used
 */
export const isLegacyUploadCheck = (fileIdentifier?: string) => {
  return fileIdentifier === LEGACY_FILES_IDENTIFIER
}

/**
 * @function hasArtifactDefined Checks whether use-case has artifact defined
 *
 * @param {UseCase.Details} useCase useCase details
 * @param {USE_CASE_ARTIFACTS_TYPES} type artifact type
 *
 * @return {Boolean}  true, if artifact is defined
 */
export const hasArtifactDefined = (useCase: UseCase.Details, type: USE_CASE_ARTIFACTS_TYPES | string) => {
  const artifact = get(useCase, `artifacts.${type}`, {}) as UseCase.ArtifactMappingItemSimplified

  return Boolean(artifact && artifact.id)
}

/**
 * @function getArtifactId Returns artifact id if exist
 *
 * @param {UseCase.Details} useCase useCase details
 * @param {USE_CASE_ARTIFACTS_TYPES} type artifact type
 *
 * @return {String} Artifact id
 */
export const getArtifactId = (useCase: UseCase.Details, type: USE_CASE_ARTIFACTS_TYPES | string) => {
  const artifactId = get(useCase, `artifacts.${type}.id`, '')

  return artifactId
}

/**
 * @function getArtifactDate Returns artifact date if exist
 *
 * @param {UseCase.Details} useCase useCase details
 * @param {USE_CASE_ARTIFACTS_TYPES} type artifact type
 *
 * @return {String} Artifact date
 */
export const getArtifactDate = (useCase: UseCase.Details, type: USE_CASE_ARTIFACTS_TYPES | string) => {
  const artifactDate = get(useCase, `artifacts.${type}.createdAt`, '')

  return artifactDate
}

/**
 * @function getUseCaseForecastAddedValue Returns use-case forecast added value
 *
 * @param {Hera.ForecastValue | null} forecastValue useCase forecast deviations
 * @param {UseCase.ForecastParameters} forecastParameters useCase forecast parameters
 *
 * @returns {String} formatted value
 */
export const getUseCaseForecastAddedValue = (forecastValue: Hera.ForecastValue | null) => {
  if (!forecastValue) {
    return 0
  }

  const {
    overestimatedUnitsYearly = 0,
    underestimatedUnitsYearly = 0,
    overestimateCostPerUnit = 0,
    underestimateCostPerUnit = 0,
  } = forecastValue

  const sum = (overestimatedUnitsYearly * overestimateCostPerUnit) + (underestimatedUnitsYearly * underestimateCostPerUnit)

  if (!sum) {
    return 0
  }

  return formatNumberWithSuffix(sum)
}

/**
 * @function getFileIdentifiersCount Returns file identifiers count
 *
 * @param {TrainingFiles.FileIdentifierItem[] | TrainingFiles.FileIdentifierItem[]} filesIdentifiers identifiers
 * @param {Boolean} countGlobalIndetifier true, if global identifier should be counted
 *
 * @returns {Number} File identifiers count
 */
export const getFileIdentifiersCount = (filesIdentifiers: TrainingFiles.FileIdentifierOption[] | TrainingFiles.FileIdentifierItem[], countGlobalIndetifier = false) => {
  // -1 since there is global data type for general upload
  const fileVersionsCount = countGlobalIndetifier ? filesIdentifiers.length : Math.max(filesIdentifiers.length - 1, 0)

  return fileVersionsCount
}

export const visibilityStateToBoolean = (visibilityState: UseCase.VISIBILITY_STATES) => {
  return visibilityState === VISIBILITY_STATES.CUSTOMER
}

export const booleanToVisibilityState = (visibilityState: boolean) => {
  return visibilityState ? VISIBILITY_STATES.CUSTOMER : VISIBILITY_STATES.ADMIN
}
