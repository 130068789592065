import moment from 'moment'
import { FEDERAL_STATES_DE } from '@constants/states-de.constants'

interface SchoolHolidaysData {
  [year: number]: {
    [key in FEDERAL_STATES_DE]: {
      [k in SCHOOL_HOLIDAYS_DE]: Common.EventItem[]
    }
  }
}

export enum SCHOOL_HOLIDAYS_DE {
  WINTERFERIEN = 'WINTERFERIEN',
  OSTERFERIEN = 'OSTERFERIEN',
  PFINGSTFERIEN = 'PFINGSTFERIEN',
  SOMMERFERIEN = 'SOMMERFERIEN',
  HERBSTFERIEN = 'HERBSTFERIEN',
  WEIHNACHTSFERIEN = 'WEIHNACHTSFERIEN',
}

export const SCHOOL_HOLIDAYS_DE_TO_LABELS_MAP: { [key in SCHOOL_HOLIDAYS_DE]: string } = {
  [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: 'common.events.school_holidays.de.winter',
  [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: 'common.events.school_holidays.de.easter',
  [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: 'common.events.school_holidays.de.whitsun',
  [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: 'common.events.school_holidays.de.summer',
  [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: 'common.events.school_holidays.de.autumn',
  [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: 'common.events.school_holidays.de.christmas',
}

export const SCHOOL_HOLIDAYS_DE_LIST = Object.keys(SCHOOL_HOLIDAYS_DE) as SCHOOL_HOLIDAYS_DE[]

export const SCHOOL_HOLIDAYS_DE_DATA: SchoolHolidaysData = {
  2022: {
    [FEDERAL_STATES_DE.BADEN_WUERTTEMBERG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-14').startOf('day'), to: moment('2022-04-14').endOf('day') },
        { from: moment('2022-04-19').startOf('day'), to: moment('2022-04-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2022-06-07').startOf('day'), to: moment('2022-06-18').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-28').startOf('day'), to: moment('2022-09-10').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-31').startOf('day'), to: moment('2022-10-31').endOf('day') },
        { from: moment('2022-11-02').startOf('day'), to: moment('2022-11-04').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-21').startOf('day'), to: moment('2023-01-07').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BAVARIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2022-02-28').startOf('day'), to: moment('2022-03-04').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-11').startOf('day'), to: moment('2022-04-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2022-06-07').startOf('day'), to: moment('2022-06-18').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-08-01').startOf('day'), to: moment('2022-09-12').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-31').startOf('day'), to: moment('2022-11-04').endOf('day') },
        { from: moment('2022-11-16').startOf('day'), to: moment('2022-11-16').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-24').startOf('day'), to: moment('2023-01-07').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BERLIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2022-01-29').startOf('day'), to: moment('2022-02-05').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-03-07').startOf('day'), to: moment('2022-03-07').endOf('day') },
        { from: moment('2022-04-11').startOf('day'), to: moment('2022-04-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2022-05-27').startOf('day'), to: moment('2022-05-27').endOf('day') },
        { from: moment('2022-06-07').startOf('day'), to: moment('2022-06-07').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-07').startOf('day'), to: moment('2022-08-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-24').startOf('day'), to: moment('2022-11-05').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-22').startOf('day'), to: moment('2023-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BRANDENBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2022-01-31').startOf('day'), to: moment('2022-02-05').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-11').startOf('day'), to: moment('2022-04-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-07').startOf('day'), to: moment('2022-08-20').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-24').startOf('day'), to: moment('2022-11-05').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-22').startOf('day'), to: moment('2023-01-03').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BREMEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2022-01-31').startOf('day'), to: moment('2022-02-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-04').startOf('day'), to: moment('2022-04-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2022-05-27').startOf('day'), to: moment('2022-05-27').endOf('day') },
        { from: moment('2022-06-07').startOf('day'), to: moment('2022-06-07').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-14').startOf('day'), to: moment('2022-08-24').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-17').startOf('day'), to: moment('2022-10-29').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-23').startOf('day'), to: moment('2023-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.HAMBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2022-01-28').startOf('day'), to: moment('2022-01-28').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-03-07').startOf('day'), to: moment('2022-03-18').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2022-05-23').startOf('day'), to: moment('2022-05-27').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-07').startOf('day'), to: moment('2022-08-17').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-10').startOf('day'), to: moment('2022-10-21').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-23').startOf('day'), to: moment('2023-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.HESSEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-11').startOf('day'), to: moment('2022-04-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-25').startOf('day'), to: moment('2022-09-02').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-24').startOf('day'), to: moment('2022-10-29').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-22').startOf('day'), to: moment('2023-01-07').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.MECKLENBURG_VORPOMMERN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2022-02-05').startOf('day'),
        to: moment('2022-02-17').endOf('day'),
      }, {
        from: moment('2022-02-18').startOf('day'),
        to: moment('2022-02-18').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-11').startOf('day'), to: moment('2022-04-20').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2022-05-27').startOf('day'),
        to: moment('2022-05-27').endOf('day'),
      }, {
        from: moment('2022-06-03').startOf('day'),
        to: moment('2022-06-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-04').startOf('day'), to: moment('2022-08-13').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2022-10-10').startOf('day'),
        to: moment('2022-10-14').endOf('day'),
      }, {
        from: moment('2022-11-01').startOf('day'),
        to: moment('2022-11-02').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-22').startOf('day'), to: moment('2023-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.LOWER_SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2022-01-31').startOf('day'),
        to: moment('2022-02-01').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2022-04-04').startOf('day'),
        to: moment('2022-04-19').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2022-05-27').startOf('day'),
        to: moment('2022-05-27').endOf('day'),
      }, {
        from: moment('2022-06-07').startOf('day'),
        to: moment('2022-06-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-14').startOf('day'), to: moment('2022-08-24').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-17').startOf('day'), to: moment('2022-10-28').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-23').startOf('day'), to: moment('2023-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.NORTH_RHINE_WESTPHALIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-11').startOf('day'), to: moment('2022-04-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-06-27').startOf('day'), to: moment('2022-08-09').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-04').startOf('day'), to: moment('2022-10-15').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-23').startOf('day'), to: moment('2023-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.RHINELAND_PALATINATE]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2022-02-21').startOf('day'),
        to: moment('2022-02-25').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-13').startOf('day'), to: moment('2022-04-22').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-25').startOf('day'), to: moment('2022-09-02').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-17').startOf('day'), to: moment('2022-10-31').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-23').startOf('day'), to: moment('2023-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAARLAND]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2022-02-21').startOf('day'),
        to: moment('2022-03-01').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2022-04-14').startOf('day'),
        to: moment('2022-04-22').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2022-06-07').startOf('day'),
        to: moment('2022-06-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-25').startOf('day'), to: moment('2022-09-02').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-24').startOf('day'), to: moment('2022-11-04').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-22').startOf('day'), to: moment('2023-01-04').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2022-02-12').startOf('day'),
        to: moment('2022-02-26').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2022-04-15').startOf('day'),
        to: moment('2022-04-23').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2022-05-27').startOf('day'),
        to: moment('2022-05-27').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-18').startOf('day'), to: moment('2022-08-26').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-17').startOf('day'), to: moment('2022-10-29').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-22').startOf('day'), to: moment('2023-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAXONY_ANHALT]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2022-02-12').startOf('day'),
        to: moment('2022-02-19').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2022-04-11').startOf('day'),
        to: moment('2022-04-16').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2022-05-23').startOf('day'),
        to: moment('2022-05-28').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-14').startOf('day'), to: moment('2022-08-24').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-24').startOf('day'), to: moment('2022-11-04').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-22').startOf('day'), to: moment('2023-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SCHLESWIG_HOLSTEIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-04').startOf('day'), to: moment('2022-04-16').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2022-05-27').startOf('day'), to: moment('2022-05-28').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-04').startOf('day'), to: moment('2022-08-13').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-10').startOf('day'), to: moment('2022-10-21').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-23').startOf('day'), to: moment('2023-01-07').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.THURINGIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2022-01-03').startOf('day'),
        to: moment('2022-01-04').endOf('day'),
      }, {
        from: moment('2022-01-12').startOf('day'),
        to: moment('2022-02-19').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2022-04-11').startOf('day'), to: moment('2022-04-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2022-05-27').startOf('day'), to: moment('2022-05-27').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2022-07-18').startOf('day'), to: moment('2022-08-27').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2022-10-17').startOf('day'), to: moment('2022-10-29').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2022-12-22').startOf('day'), to: moment('2023-01-03').endOf('day') },
      ],
    },
  },
  2023: {
    [FEDERAL_STATES_DE.BADEN_WUERTTEMBERG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2023-04-06').startOf('day'),
        to: moment('2023-04-06').endOf('day'),
      }, {
        from: moment('2023-04-11').startOf('day'),
        to: moment('2023-04-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2023-05-30').startOf('day'),
        to: moment('2023-06-09').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2023-07-27').startOf('day'),
        to: moment('2023-09-09').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2023-10-30').startOf('day'),
        to: moment('2023-11-03').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2023-12-23').startOf('day'),
        to: moment('2024-01-05').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BAVARIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2023-02-20').startOf('day'),
        to: moment('2023-02-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2023-04-03').startOf('day'),
        to: moment('2023-04-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2023-05-30').startOf('day'),
        to: moment('2023-06-09').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2023-07-31').startOf('day'),
        to: moment('2023-09-11').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2023-10-30').startOf('day'),
        to: moment('2023-11-03').endOf('day'),
      }, {
        from: moment('2023-11-22').startOf('day'),
        to: moment('2023-11-22').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2023-12-23').startOf('day'),
        to: moment('2024-01-05').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BERLIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2023-01-30').startOf('day'),
        to: moment('2023-02-04').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2023-04-03').startOf('day'),
        to: moment('2023-04-14').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2023-05-19').startOf('day'),
        to: moment('2023-05-19').endOf('day'),
      }, {
        from: moment('2023-05-30').startOf('day'),
        to: moment('2023-05-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2023-07-13').startOf('day'),
        to: moment('2023-08-25').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2023-10-02').startOf('day'),
        to: moment('2023-10-02').endOf('day'),
      }, {
        from: moment('2023-10-23').startOf('day'),
        to: moment('2023-11-04').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2023-12-23').startOf('day'),
        to: moment('2024-01-05').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BRANDENBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2023-01-30').startOf('day'), to: moment('2023-02-03').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-04-03').startOf('day'), to: moment('2023-04-14').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-13').startOf('day'), to: moment('2023-08-26').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-23').startOf('day'), to: moment('2023-11-04').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-23').startOf('day'), to: moment('2024-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BREMEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2023-01-30').startOf('day'), to: moment('2023-01-31').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-03-27').startOf('day'), to: moment('2023-04-11').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2023-05-19').startOf('day'), to: moment('2023-05-19').endOf('day') },
        { from: moment('2023-05-30').startOf('day'), to: moment('2023-05-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-06').startOf('day'), to: moment('2023-08-16').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-02').startOf('day'), to: moment('2023-10-02').endOf('day') },
        { from: moment('2023-10-16').startOf('day'), to: moment('2023-10-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-23').startOf('day'), to: moment('2024-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.HAMBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2023-01-27').startOf('day'), to: moment('2023-01-27').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-03-06').startOf('day'), to: moment('2023-03-17').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2023-05-15').startOf('day'), to: moment('2023-05-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-13').startOf('day'), to: moment('2023-08-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-02').startOf('day'), to: moment('2023-10-02').endOf('day') },
        { from: moment('2023-10-16').startOf('day'), to: moment('2023-10-27').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-22').startOf('day'), to: moment('2024-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.HESSEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-04-03').startOf('day'), to: moment('2023-04-22').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-24').startOf('day'), to: moment('2023-09-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-23').startOf('day'), to: moment('2023-10-28').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-27').startOf('day'), to: moment('2024-01-13').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.MECKLENBURG_VORPOMMERN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2023-02-06').startOf('day'),
        to: moment('2023-02-18').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-04-03').startOf('day'), to: moment('2023-04-12').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2023-05-19').startOf('day'),
        to: moment('2023-05-19').endOf('day'),
      }, {
        from: moment('2023-05-26').startOf('day'),
        to: moment('2023-05-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-17').startOf('day'), to: moment('2023-08-26').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2023-10-09').startOf('day'),
        to: moment('2023-10-14').endOf('day'),
      }, {
        from: moment('2023-10-30').startOf('day'),
        to: moment('2023-11-01').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-21').startOf('day'), to: moment('2024-01-03').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.LOWER_SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2023-01-30').startOf('day'),
        to: moment('2023-01-31').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2023-03-27').startOf('day'),
        to: moment('2023-04-11').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2023-05-19').startOf('day'),
        to: moment('2023-05-19').endOf('day'),
      }, {
        from: moment('2023-05-30').startOf('day'),
        to: moment('2023-05-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-06').startOf('day'), to: moment('2023-08-18').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-02').startOf('day'), to: moment('2023-10-02').endOf('day') },
        { from: moment('2023-10-16').startOf('day'), to: moment('2023-10-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-27').startOf('day'), to: moment('2024-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.NORTH_RHINE_WESTPHALIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-04-03').startOf('day'), to: moment('2023-04-15').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2023-05-30').startOf('day'), to: moment('2023-05-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-06-22').startOf('day'), to: moment('2023-08-04').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-02').startOf('day'), to: moment('2023-10-14').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-21').startOf('day'), to: moment('2024-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.RHINELAND_PALATINATE]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-04-03').startOf('day'), to: moment('2023-04-06').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2023-05-30').startOf('day'), to: moment('2023-06-07').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-24').startOf('day'), to: moment('2023-09-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-16').startOf('day'), to: moment('2023-10-27').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-27').startOf('day'), to: moment('2024-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAARLAND]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2023-02-20').startOf('day'),
        to: moment('2023-02-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2023-04-03').startOf('day'),
        to: moment('2023-04-12').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2023-05-30').startOf('day'),
        to: moment('2023-06-02').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-24').startOf('day'), to: moment('2023-09-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-23').startOf('day'), to: moment('2023-11-03').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-21').startOf('day'), to: moment('2024-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2023-02-13').startOf('day'),
        to: moment('2023-02-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2023-04-07').startOf('day'),
        to: moment('2023-04-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2023-05-19').startOf('day'),
        to: moment('2023-05-19').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-10').startOf('day'), to: moment('2023-08-18').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-02').startOf('day'), to: moment('2023-10-14').endOf('day') },
        { from: moment('2023-10-30').startOf('day'), to: moment('2023-10-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-23').startOf('day'), to: moment('2024-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAXONY_ANHALT]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2023-02-06').startOf('day'),
        to: moment('2023-02-11').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2023-04-03').startOf('day'),
        to: moment('2023-04-08').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2023-05-15').startOf('day'),
        to: moment('2023-05-19').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-06').startOf('day'), to: moment('2023-08-16').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-02').startOf('day'), to: moment('2023-10-02').endOf('day') },
        { from: moment('2023-10-16').startOf('day'), to: moment('2023-10-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-21').startOf('day'), to: moment('2024-01-03').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SCHLESWIG_HOLSTEIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-04-06').startOf('day'), to: moment('2023-04-22').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2023-05-19').startOf('day'), to: moment('2023-05-20').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-17').startOf('day'), to: moment('2023-08-26').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-16').startOf('day'), to: moment('2023-10-27').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-27').startOf('day'), to: moment('2024-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.THURINGIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2023-02-13').startOf('day'), to: moment('2023-02-17').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2023-04-03').startOf('day'), to: moment('2023-04-15').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2023-05-19').startOf('day'), to: moment('2023-05-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2023-07-10').startOf('day'), to: moment('2023-08-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2023-10-02').startOf('day'), to: moment('2023-10-14').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2023-12-22').startOf('day'), to: moment('2024-01-05').endOf('day') },
      ],
    },
  },
  2024: {
    [FEDERAL_STATES_DE.BADEN_WUERTTEMBERG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2024-03-23').startOf('day'),
        to: moment('2024-04-05').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2024-05-21').startOf('day'),
        to: moment('2024-05-31').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2024-07-25').startOf('day'),
        to: moment('2024-09-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2024-10-28').startOf('day'),
        to: moment('2024-10-30').endOf('day'),
      }, {
        from: moment('2024-10-31').startOf('day'),
        to: moment('2024-10-31').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2024-12-23').startOf('day'),
        to: moment('2025-01-04').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BAVARIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2024-02-12').startOf('day'),
        to: moment('2024-02-16').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2024-03-25').startOf('day'),
        to: moment('2024-04-06').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2024-05-21').startOf('day'),
        to: moment('2024-06-01').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2024-07-29').startOf('day'),
        to: moment('2024-09-09').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2024-10-28').startOf('day'),
        to: moment('2024-10-31').endOf('day'),
      }, {
        from: moment('2024-11-20').startOf('day'),
        to: moment('2024-11-20').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2024-12-23').startOf('day'),
        to: moment('2025-01-03').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BERLIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2024-02-05').startOf('day'),
        to: moment('2024-02-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2024-03-25').startOf('day'),
        to: moment('2024-04-05').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2024-05-10').startOf('day'),
        to: moment('2024-05-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2024-07-18').startOf('day'),
        to: moment('2024-08-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2024-10-04').startOf('day'),
        to: moment('2024-10-04').endOf('day'),
      }, {
        from: moment('2024-10-21').startOf('day'),
        to: moment('2024-11-02').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2024-12-23').startOf('day'),
        to: moment('2024-12-31').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BRANDENBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2024-02-05').startOf('day'),
        to: moment('2024-02-09').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2024-03-25').startOf('day'),
        to: moment('2024-04-05').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2024-07-18').startOf('day'),
        to: moment('2024-08-31').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2024-10-04').startOf('day'),
        to: moment('2024-10-04').endOf('day'),
      }, {
        from: moment('2024-10-21').startOf('day'),
        to: moment('2024-11-02').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2024-12-23').startOf('day'),
        to: moment('2024-12-31').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BREMEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2024-02-01').startOf('day'),
        to: moment('2024-02-02').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2024-03-18').startOf('day'),
        to: moment('2024-03-28').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2024-05-10').startOf('day'),
        to: moment('2024-05-10').endOf('day'),
      }, {
        from: moment('2024-05-21').startOf('day'),
        to: moment('2024-05-21').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2024-06-24').startOf('day'),
        to: moment('2024-08-02').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2024-10-04').startOf('day'),
        to: moment('2024-10-19').endOf('day'),
      }, {
        from: moment('2024-11-01').startOf('day'),
        to: moment('2024-11-01').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2024-12-23').startOf('day'),
        to: moment('2025-01-04').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.HAMBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2024-02-02').startOf('day'),
        to: moment('2024-02-02').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2024-03-18').startOf('day'),
        to: moment('2024-03-28').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2024-05-10').startOf('day'),
        to: moment('2024-05-10').endOf('day'),
      }, {
        from: moment('2024-05-21').startOf('day'),
        to: moment('2024-05-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2024-07-18').startOf('day'),
        to: moment('2024-08-28').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2024-10-04').startOf('day'),
        to: moment('2024-10-04').endOf('day'),
      }, {
        from: moment('2024-10-21').startOf('day'),
        to: moment('2024-11-01').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2024-12-20').startOf('day'),
        to: moment('2025-01-03').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.HESSEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-03-25').startOf('day'), to: moment('2024-04-13').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-07-15').startOf('day'), to: moment('2024-08-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-10-14').startOf('day'), to: moment('2024-10-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-23').startOf('day'), to: moment('2025-01-10').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.MECKLENBURG_VORPOMMERN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2024-02-05').startOf('day'), to: moment('2024-02-16').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-03-25').startOf('day'), to: moment('2024-04-03').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2024-05-10').startOf('day'), to: moment('2024-05-10').endOf('day') },
        { from: moment('2024-05-17').startOf('day'), to: moment('2024-05-21').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-07-22').startOf('day'), to: moment('2024-08-31').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-10-04').startOf('day'), to: moment('2024-10-04').endOf('day') },
        { from: moment('2024-10-21').startOf('day'), to: moment('2024-10-26').endOf('day') },
        { from: moment('2024-11-01').startOf('day'), to: moment('2024-11-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-23').startOf('day'), to: moment('2025-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.LOWER_SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2024-02-01').startOf('day'),
        to: moment('2024-02-02').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2024-03-18').startOf('day'),
        to: moment('2024-03-28').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2024-05-10').startOf('day'),
        to: moment('2024-05-10').endOf('day'),
      }, {
        from: moment('2024-05-21').startOf('day'),
        to: moment('2024-05-21').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-06-24').startOf('day'), to: moment('2024-08-03').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-10-04').startOf('day'), to: moment('2024-10-19').endOf('day') },
        { from: moment('2024-11-01').startOf('day'), to: moment('2024-11-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-23').startOf('day'), to: moment('2025-01-04').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.NORTH_RHINE_WESTPHALIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-03-25').startOf('day'), to: moment('2024-04-06').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2024-05-21').startOf('day'), to: moment('2024-05-21').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-07-08').startOf('day'), to: moment('2024-08-20').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-10-14').startOf('day'), to: moment('2024-10-26').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-23').startOf('day'), to: moment('2025-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.RHINELAND_PALATINATE]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-03-25').startOf('day'), to: moment('2024-04-02').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2024-05-21').startOf('day'), to: moment('2024-05-29').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-07-15').startOf('day'), to: moment('2024-08-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-10-14').startOf('day'), to: moment('2024-10-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-23').startOf('day'), to: moment('2025-01-08').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAARLAND]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2024-02-12').startOf('day'), to: moment('2024-02-16').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-03-25').startOf('day'), to: moment('2024-04-05').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2024-05-21').startOf('day'), to: moment('2024-05-24').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-07-15').startOf('day'), to: moment('2024-08-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-10-14').startOf('day'), to: moment('2024-10-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-23').startOf('day'), to: moment('2025-01-03').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2024-02-12').startOf('day'), to: moment('2024-02-23').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-03-28').startOf('day'), to: moment('2024-04-05').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2024-05-10').startOf('day'), to: moment('2024-05-10').endOf('day') },
        { from: moment('2024-05-18').startOf('day'), to: moment('2024-05-21').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-06-20').startOf('day'), to: moment('2024-08-02').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-10-07').startOf('day'), to: moment('2024-10-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-23').startOf('day'), to: moment('2025-01-03').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAXONY_ANHALT]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2024-02-05').startOf('day'), to: moment('2024-02-10').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-03-25').startOf('day'), to: moment('2024-03-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2024-05-21').startOf('day'), to: moment('2024-05-24').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-06-24').startOf('day'), to: moment('2024-08-03').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-09-30').startOf('day'), to: moment('2024-10-12').endOf('day') },
        { from: moment('2024-11-01').startOf('day'), to: moment('2024-11-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-22').startOf('day'), to: moment('2025-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SCHLESWIG_HOLSTEIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-04-02').startOf('day'), to: moment('2024-04-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2024-05-10').startOf('day'), to: moment('2024-05-11').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-07-22').startOf('day'), to: moment('2024-08-31').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-10-21').startOf('day'), to: moment('2024-11-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-19').startOf('day'), to: moment('2025-01-07').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.THURINGIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2024-02-12').startOf('day'), to: moment('2024-02-16').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2024-03-25').startOf('day'), to: moment('2024-04-06').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2024-05-10').startOf('day'), to: moment('2024-05-10').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2024-06-20').startOf('day'), to: moment('2024-07-31').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2024-09-30').startOf('day'), to: moment('2024-10-12').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2024-12-22').startOf('day'), to: moment('2025-01-03').endOf('day') },
      ],
    },
  },
  2025: {
    [FEDERAL_STATES_DE.BADEN_WUERTTEMBERG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-14').startOf('day'), to: moment('2025-04-26').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-06-10').startOf('day'), to: moment('2025-06-20').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-31').startOf('day'), to: moment('2025-09-13').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-27').startOf('day'), to: moment('2025-10-30').endOf('day') },
        { from: moment('2025-10-31').startOf('day'), to: moment('2025-10-31').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BAVARIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2025-03-03').startOf('day'), to: moment('2025-03-07').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-14').startOf('day'), to: moment('2025-04-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-06-10').startOf('day'), to: moment('2025-06-20').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-08-01').startOf('day'), to: moment('2025-09-15').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-11-03').startOf('day'), to: moment('2025-11-07').endOf('day') },
        { from: moment('2025-11-19').startOf('day'), to: moment('2025-11-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BERLIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2025-02-03').startOf('day'), to: moment('2025-02-08').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-14').startOf('day'), to: moment('2025-04-25').endOf('day') },
        { from: moment('2025-05-02').startOf('day'), to: moment('2025-05-02').endOf('day') },
        { from: moment('2025-05-30').startOf('day'), to: moment('2025-05-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-06-10').startOf('day'), to: moment('2025-06-10').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-24').startOf('day'), to: moment('2025-09-06').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-20').startOf('day'), to: moment('2025-11-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BRANDENBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2025-02-03').startOf('day'), to: moment('2025-02-08').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-14').startOf('day'), to: moment('2025-04-25').endOf('day') },
        { from: moment('2025-05-02').startOf('day'), to: moment('2025-05-02').endOf('day') },
        { from: moment('2025-05-30').startOf('day'), to: moment('2025-05-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-06-10').startOf('day'), to: moment('2025-06-10').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-24').startOf('day'), to: moment('2025-09-06').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-20').startOf('day'), to: moment('2025-11-01').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.BREMEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2025-02-03').startOf('day'), to: moment('2025-02-04').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-07').startOf('day'), to: moment('2025-04-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-04-30').startOf('day'), to: moment('2025-04-30').endOf('day') },
        { from: moment('2025-05-02').startOf('day'), to: moment('2025-05-02').endOf('day') },
        { from: moment('2025-05-30').startOf('day'), to: moment('2025-05-30').endOf('day') },
        { from: moment('2025-06-10').startOf('day'), to: moment('2025-06-10').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-03').startOf('day'), to: moment('2025-08-13').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-13').startOf('day'), to: moment('2025-10-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.HAMBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2025-01-31').startOf('day'), to: moment('2025-01-31').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-03-10').startOf('day'), to: moment('2025-03-21').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-05-02').startOf('day'), to: moment('2025-05-02').endOf('day') },
        { from: moment('2025-05-26').startOf('day'), to: moment('2025-05-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-24').startOf('day'), to: moment('2025-09-03').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-20').startOf('day'), to: moment('2025-10-31').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-17').startOf('day'), to: moment('2026-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.HESSEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-07').startOf('day'), to: moment('2025-04-21').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-07').startOf('day'), to: moment('2025-08-15').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-06').startOf('day'), to: moment('2025-10-18').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2023-01-10').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.MECKLENBURG_VORPOMMERN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [
        { from: moment('2025-02-03').startOf('day'), to: moment('2025-02-14').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-14').startOf('day'), to: moment('2025-04-23').endOf('day') },
        { from: moment('2025-05-30').startOf('day'), to: moment('2025-05-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-06-06').startOf('day'), to: moment('2025-06-10').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-28').startOf('day'), to: moment('2025-09-06').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2025-10-02').startOf('day'),
        to: moment('2025-10-02').endOf('day'),
      }, {
        from: moment('2025-10-20').startOf('day'),
        to: moment('2025-10-25').endOf('day'),
      }, {
        from: moment('2025-11-03').startOf('day'),
        to: moment('2025-11-03').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.LOWER_SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2025-02-03').startOf('day'),
        to: moment('2025-02-04').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2025-04-07').startOf('day'),
        to: moment('2025-04-19').endOf('day'),
      }, {
        from: moment('2025-05-30').startOf('day'),
        to: moment('2025-05-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2025-05-02').startOf('day'),
        to: moment('2025-05-02').endOf('day'),
      }, {
        from: moment('2025-05-30').startOf('day'),
        to: moment('2025-05-30').endOf('day'),
      }, {
        from: moment('2025-06-10').startOf('day'),
        to: moment('2025-06-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-03').startOf('day'), to: moment('2025-08-13').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-13').startOf('day'), to: moment('2025-10-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.NORTH_RHINE_WESTPHALIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-14').startOf('day'), to: moment('2025-04-26').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2025-06-10').startOf('day'),
        to: moment('2025-06-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-14').startOf('day'), to: moment('2025-08-26').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-13').startOf('day'), to: moment('2025-10-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.RHINELAND_PALATINATE]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-14').startOf('day'), to: moment('2025-04-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-07').startOf('day'), to: moment('2025-08-15').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-13').startOf('day'), to: moment('2025-10-24').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-07').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAARLAND]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2025-02-24').startOf('day'),
        to: moment('2025-03-04').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2025-04-14').startOf('day'),
        to: moment('2025-04-25').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-07').startOf('day'), to: moment('2025-08-14').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-13').startOf('day'), to: moment('2025-10-24').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2025-02-17').startOf('day'),
        to: moment('2025-03-01').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2025-04-18').startOf('day'),
        to: moment('2025-04-25').endOf('day'),
      }, {
        from: moment('2025-05-30').startOf('day'),
        to: moment('2025-05-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-28').startOf('day'), to: moment('2025-08-08').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-06').startOf('day'), to: moment('2025-10-18').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-02').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SAXONY_ANHALT]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2025-01-27').startOf('day'),
        to: moment('2025-01-31').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2025-04-07').startOf('day'),
        to: moment('2025-04-19').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2025-05-30').startOf('day'),
        to: moment('2025-05-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-06-28').startOf('day'), to: moment('2025-08-08').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-13').startOf('day'), to: moment('2025-10-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-05').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.SCHLESWIG_HOLSTEIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-11').startOf('day'), to: moment('2025-04-25').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-05-30').startOf('day'), to: moment('2025-05-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-28').startOf('day'), to: moment('2025-08-06').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-10').startOf('day'), to: moment('2025-10-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-23').startOf('day'), to: moment('2026-01-06').endOf('day') },
      ],
    },
    [FEDERAL_STATES_DE.THURINGIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2025-02-03').startOf('day'),
        to: moment('2025-02-08').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [
        { from: moment('2025-04-07').startOf('day'), to: moment('2025-04-19').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [
        { from: moment('2025-05-30').startOf('day'), to: moment('2025-05-30').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [
        { from: moment('2025-07-28').startOf('day'), to: moment('2025-08-08').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [
        { from: moment('2025-10-06').startOf('day'), to: moment('2025-10-18').endOf('day') },
      ],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [
        { from: moment('2025-12-22').startOf('day'), to: moment('2026-01-03').endOf('day') },
      ],
    },
  },
  2026: {
    [FEDERAL_STATES_DE.BADEN_WUERTTEMBERG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-11').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-26').startOf('day'),
        to: moment('2026-06-05').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-30').startOf('day'),
        to: moment('2026-09-12').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-26').startOf('day'),
        to: moment('2026-10-30').endOf('day'),
      }, {
        from: moment('2026-10-31').startOf('day'),
        to: moment('2026-10-31').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-09').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BAVARIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-16').startOf('day'),
        to: moment('2026-02-20').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-26').startOf('day'),
        to: moment('2026-06-05').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-08-03').startOf('day'),
        to: moment('2026-09-14').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-11-02').startOf('day'),
        to: moment('2026-11-06').endOf('day'),
      }, {
        from: moment('2026-11-18').startOf('day'),
        to: moment('2026-11-18').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-24').startOf('day'),
        to: moment('2027-01-08').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BERLIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-02').startOf('day'),
        to: moment('2026-02-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-10').endOf('day'),
      }, {
        from: moment('2026-05-15').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-26').startOf('day'),
        to: moment('2026-05-26').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-09').startOf('day'),
        to: moment('2026-08-22').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-19').startOf('day'),
        to: moment('2026-10-31').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-02').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BRANDENBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-02').startOf('day'),
        to: moment('2026-02-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-10').endOf('day'),
      }, {
        from: moment('2026-05-15').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-26').startOf('day'),
        to: moment('2026-05-26').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-09').startOf('day'),
        to: moment('2026-08-22').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-19').startOf('day'),
        to: moment('2026-10-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-02').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.BREMEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-02').startOf('day'),
        to: moment('2026-02-03').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-23').startOf('day'),
        to: moment('2026-04-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-15').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }, {
        from: moment('2026-05-26').startOf('day'),
        to: moment('2026-05-26').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-02').startOf('day'),
        to: moment('2026-08-12').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-12').startOf('day'),
        to: moment('2026-10-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-09').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.HAMBURG]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-01-30').startOf('day'),
        to: moment('2026-01-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-02').startOf('day'),
        to: moment('2026-03-13').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-11').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-09').startOf('day'),
        to: moment('2026-08-19').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-19').startOf('day'),
        to: moment('2026-10-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-21').startOf('day'),
        to: moment('2027-01-01').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.HESSEN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-06-29').startOf('day'),
        to: moment('2026-08-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-05').startOf('day'),
        to: moment('2026-10-17').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-12').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.MECKLENBURG_VORPOMMERN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-09').startOf('day'),
        to: moment('2026-02-20').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-08').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-15').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }, {
        from: moment('2026-05-22').startOf('day'),
        to: moment('2026-05-26').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-13').startOf('day'),
        to: moment('2026-08-22').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-19').startOf('day'),
        to: moment('2026-10-24').endOf('day'),
      }, {
        from: moment('2026-11-26').startOf('day'),
        to: moment('2026-11-27').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-19').startOf('day'),
        to: moment('2027-01-02').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.LOWER_SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-02').startOf('day'),
        to: moment('2026-02-03').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-23').startOf('day'),
        to: moment('2026-04-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-15').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }, {
        from: moment('2026-05-26').startOf('day'),
        to: moment('2026-05-26').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-02').startOf('day'),
        to: moment('2026-08-12').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-12').startOf('day'),
        to: moment('2026-10-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-09').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.NORTH_RHINE_WESTPHALIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-11').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-26').startOf('day'),
        to: moment('2026-05-26').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-20').startOf('day'),
        to: moment('2026-09-01').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-17').startOf('day'),
        to: moment('2026-10-31').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-06').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.RHINELAND_PALATINATE]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-06-29').startOf('day'),
        to: moment('2026-08-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-05').startOf('day'),
        to: moment('2026-10-16').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-08').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.SAARLAND]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-16').startOf('day'),
        to: moment('2026-02-20').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-04-07').startOf('day'),
        to: moment('2026-04-17').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-06-29').startOf('day'),
        to: moment('2026-08-07').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-05').startOf('day'),
        to: moment('2026-10-16').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-21').startOf('day'),
        to: moment('2026-12-31').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.SAXONY]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-09').startOf('day'),
        to: moment('2026-02-21').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-04-03').startOf('day'),
        to: moment('2026-04-10').endOf('day'),
      }, {
        from: moment('2026-05-15').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-04').startOf('day'),
        to: moment('2026-08-14').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-12').startOf('day'),
        to: moment('2026-10-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-02').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.SAXONY_ANHALT]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-01-31').startOf('day'),
        to: moment('2026-02-06').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-30').startOf('day'),
        to: moment('2026-04-04').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-26').startOf('day'),
        to: moment('2026-05-29').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-04').startOf('day'),
        to: moment('2026-08-14').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-19').startOf('day'),
        to: moment('2026-10-30').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-21').startOf('day'),
        to: moment('2027-01-02').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.SCHLESWIG_HOLSTEIN]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-02').startOf('day'),
        to: moment('2026-02-03').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-03-26').startOf('day'),
        to: moment('2026-04-10').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-15').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-04').startOf('day'),
        to: moment('2026-08-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-12').startOf('day'),
        to: moment('2026-10-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-21').startOf('day'),
        to: moment('2027-01-06').endOf('day'),
      }],
    },
    [FEDERAL_STATES_DE.THURINGIA]: {
      [SCHOOL_HOLIDAYS_DE.WINTERFERIEN]: [{
        from: moment('2026-02-16').startOf('day'),
        to: moment('2026-02-21').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.OSTERFERIEN]: [{
        from: moment('2026-04-07').startOf('day'),
        to: moment('2026-04-17').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.PFINGSTFERIEN]: [{
        from: moment('2026-05-15').startOf('day'),
        to: moment('2026-05-15').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.SOMMERFERIEN]: [{
        from: moment('2026-07-04').startOf('day'),
        to: moment('2026-08-14').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.HERBSTFERIEN]: [{
        from: moment('2026-10-12').startOf('day'),
        to: moment('2026-10-24').endOf('day'),
      }],
      [SCHOOL_HOLIDAYS_DE.WEIHNACHTSFERIEN]: [{
        from: moment('2026-12-23').startOf('day'),
        to: moment('2027-01-02').endOf('day'),
      }],
    },
  },
}
