import { NOTIFICATIONS } from '@constants/api-provider.constants'
import { checkApiForError } from '@utils/api.utils'

import apiProvider from '@redux/api-provider'

export const markCompanyNotificationsAsRead = (payload: { companyId: string }) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/company/read', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data.success as boolean
    })
}

export const markUserNotificationsAsRead = (payload: { useCaseId: string }) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/use-case/read', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data.success as boolean
    })
}

export const getUnreadNotificationsCount = (payload: Notifications.APIGetUnreadNotificationsCountRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/unread/count', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.count as number
    })
}

export const sendNotification = (payload: Notifications.SendNotificationRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/send', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.notification as Notifications.NotificationItem
    })
}

export const listCompanyNotifications = (payload: Notifications.CompanyNotificationsRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/company/list', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.CompanyNotificationsResponse
    })
}

export const listUseCaseNotifications = (payload: Notifications.UseCaseNotificationsRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/use-case/list', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.UseCaseNotificationsResponse
    })
}

export const dismissNotifications = (payload: Notifications.DismissNotificationsRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/dismiss', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data.count as number
    })
}

export const markNotificationsAsRead = (payload: Notifications.MarkNotificationsAsReadRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/read', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data.count as number
    })
}

export const createTemplate = (payload: Notifications.CreateNotificationTemplate) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/templates/create', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationTemplateItem
    })
}

export const updateTemplate = (payload: Notifications.UpdateNotificationTemplate) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/templates/update', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationTemplateItem
    })
}

export const deleteTemplate = (payload: Notifications.RemoveNotificationTemplate) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/templates/delete', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.success as boolean
    })
}

export const listTemplates = (payload: Notifications.NotificationsTemplatesRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/templates/list', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationsTemplatesResponse
    })
}

export const createSink = (payload: Notifications.CreateNotificationsSinkRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/sinks/create', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationSinkItem
    })
}

export const updateSink = (payload: Notifications.UpdateNotificationsSinkRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/sinks/update', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationSinkItem
    })
}

export const deleteSink = (payload: Notifications.RemoveNotificationsSinkRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/sinks/delete', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data.success as boolean
    })
}

export const listSinks = (payload: Notifications.NotificationsSinksRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/sinks/list', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationsSinksResponse
    })
}

export const testSink = (payload: Notifications.TestNotificationsSinkRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/sinks/test', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data.success as boolean
    })
}

export const listRoutings = (payload: Notifications.NotificationsRoutingsRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/routings/list', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationsRoutingsResponse
    })
}

export const getRoutingGraph = (payload: Notifications.GetRoutingGraphRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/routings/graph', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data?.graph as Notifications.NotificationGraphResponse
    })
}

export const createRouting = (payload: Notifications.CreateRoutingRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/routings/create', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationRoutingItem
    })
}

export const updateRouting = (payload: Notifications.UpdateRoutingRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/routings/update', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data as Notifications.NotificationRoutingItem
    })
}

export const deleteRouting = (payload: { id: string }) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/routings/delete', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data.success as boolean
    })
}

export const setupDefaultRoutings = (payload: Notifications.SetupNotificationsRoutingsRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/routings/setup', payload)
    .then(({ data }) => {
      checkApiForError(data)
      return data.data.success as boolean
    })
}

export const getDispatchedNotifications = (payload: Notifications.DispatchedNotificationsRequest) => {
  return apiProvider
    .getApi(NOTIFICATIONS)
    .post('v1/notifications/dispatched/list', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data as Notifications.DispatchedNotificationsResponse
    })
}
