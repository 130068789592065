import React, { useMemo } from 'react'
import get from 'lodash.get'
import { TooltipProps } from 'recharts'
import { Box } from '@mui/material'

import ChartTooltipContainerComponent from '@components/charts/tooltips/ChartTooltipContainer'
import ChartTooltipGroupBlockComponent from '@components/charts/tooltips/ChartTooltipGroupBlock'
import ChartTooltipTableComponent from '@components/charts/tooltips/ChartTooltipTable'

export interface CustomTooltipProps<T> extends Omit<TooltipProps<any, any>, 'payload'> {
  /**
   * If 'true' the tooltip is active
   */
  active?: boolean,
  /**
   * The payload of the chart data point
   */
  payload?: T[],
  /**
   * The rows to be displayed in the tooltip
   */
  rows: Common.TooltipRow<T>[]
}

const ChartTooltipComponent = <T extends {}> ({
  active, payload, rows,
} : CustomTooltipProps<T>) => {
  const data: T = get(payload, '[0].payload', {} as T) as T

  const rowsByGroupId = useMemo(() => {
    return rows && rows.reduce((acc, row) => {
      const groupKey = row.groupId || 'default'

      if (!acc[groupKey]) {
        acc[groupKey] = []
      }

      acc[groupKey].push(row)

      return acc
    }, {} as { [key: string]: Common.TooltipRow<T>[] })
  }, [rows])

  const groupsList = useMemo(() => Object.keys(rowsByGroupId).filter((key) => key !== 'primary'), [rowsByGroupId])
  const primaryGroup = rowsByGroupId.primary

  if (active && payload && rows && rows.length && payload.length) {
    return (
      <ChartTooltipContainerComponent
        data-testid={ChartTooltipComponent.name}
        padding='15px'
        maxWidth='500px'
      >
        {
          primaryGroup ? (
            <ChartTooltipTableComponent>
              <Box component='tbody'>
                <ChartTooltipGroupBlockComponent
                  data={data}
                  groupRows={primaryGroup}
                  showDivider={groupsList.length > 0}
                />
              </Box>
            </ChartTooltipTableComponent>
          ) : (
            null
          )
        }

        <ChartTooltipTableComponent>
          <Box component='tbody'>
            {
              groupsList.map((groupId, groupIndex) => {
                const groupRows = rowsByGroupId[groupId]
                const isLastGroup = groupIndex === groupsList.length - 1

                return (
                  <ChartTooltipGroupBlockComponent
                    key={groupId}
                    data={data}
                    groupRows={groupRows}
                    showDivider={!isLastGroup}
                  />
                )
              })
            }
          </Box>
        </ChartTooltipTableComponent>
      </ChartTooltipContainerComponent>
    )
  }

  return null
}

export default ChartTooltipComponent
