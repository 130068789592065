import {
  styled,
  Typography,
} from '@mui/material'

export default styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  letterSpacing: '0.6px',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '125%',
  fontVariantNumeric: 'lining-nums tabular-nums',
  fontFeatureSettings: "'dlig' on, 'ss02' on",
}))
