import { handleActions } from 'redux-actions'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'
import { NotificationsState } from '@redux/modules/types'

import {
  RECEIVE_NOTIFICATIONS,
  RECEIVE_DISPATCHED_NOTIFICATIONS,
  RECEIVE_NOTIFICATIONS_ROUTINGS,
  RECEIVE_NOTIFICATIONS_SINKS,
  RECEIVE_NOTIFICATIONS_TEMPLATES,
  RECEIVE_NOTIFICATIONS_ROUTING_GRAPH,
  RECEIVE_UNREAD_NOTIFICATIONS_COUNT,
  RECEIVE_NOTIFICATIONS_PAGINATION_META,
  START_FETCHING_NOTIFICATIONS,
  STOP_FETCHING_NOTIFICATIONS,
} from './notifications.action-types'

import {
  initialState,
  receiveDispatchedNotificationsReducer,
  receiveNotificationsReducer,
  receiveNotificationsRoutingsReducer,
  receiveNotificationsSinksReducer,
  receiveNotificationsTemplatesReducer,
  receiveNotificationsRoutingGraphReducer,
  receiveUnreadNotificationsCountReducer,
  receiveNotificationsPaginationMetaReducer,
} from './notifications.reducers'

export default handleActions<NotificationsState, any>({
  [RECEIVE_NOTIFICATIONS]: receiveNotificationsReducer,
  [RECEIVE_DISPATCHED_NOTIFICATIONS]: receiveDispatchedNotificationsReducer,
  [RECEIVE_NOTIFICATIONS_ROUTINGS]: receiveNotificationsRoutingsReducer,
  [RECEIVE_NOTIFICATIONS_SINKS]: receiveNotificationsSinksReducer,
  [RECEIVE_NOTIFICATIONS_TEMPLATES]: receiveNotificationsTemplatesReducer,
  [RECEIVE_NOTIFICATIONS_ROUTING_GRAPH]: receiveNotificationsRoutingGraphReducer,
  [RECEIVE_UNREAD_NOTIFICATIONS_COUNT]: receiveUnreadNotificationsCountReducer,
  [RECEIVE_NOTIFICATIONS_PAGINATION_META]: receiveNotificationsPaginationMetaReducer,
  [START_FETCHING_NOTIFICATIONS]: startFetchingAction<NotificationsState>,
  [STOP_FETCHING_NOTIFICATIONS]: stopFetchingAction<NotificationsState>,
}, initialState)
