import React, {
  useMemo,
} from 'react'

import moment from 'moment'
import { useIntl } from 'react-intl'
import { Box, Typography, useTheme } from '@mui/material'

import InsightsLineChartComponent, { GroupingModeAttributes, RegularModeAttributes } from '@components/insights/InsightsLineChart/InsightsLineChart.component'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'

import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'
import { INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD } from '@constants/insights.constants'

export interface InsightsChartComponentProps {
  /**
   * Data grid state. Contains information about the grid filters and rows selection.
   */
  gridState?: Insights.BaseGridState
  /**
   * If 'true', the component is in loading state
   */
  isFetching?: boolean
  /**
   * Data set to be displayed
   */
  dataset: Insights.BaseChartDatasetItem[]
  /**
   * Lines to be displayed
   */
  lines: Insights.BaseChartLineItem[]
  /**
   * Promotion days to be displayed in the chart
   */
  events?: Insights.BaseChartEventItem[]
  /**
   * Annotations to be displayed
   */
  annotations?: Insights.BaseChartAnnotationItem[]
  /**
   * Target name to be used in the tooltip
   */
  targetName: string
  /**
   * Target unit to be used in the tooltip
   */
  targetUnit: string
  /**
   * Events to exclude from the chart
   */
  eventsToExclude: string[]
  /**
   * If 'true', events are visible in the chart
   */
  eventsVisibility: boolean
  /**
   * If 'true', the last year target is visible in the chart
   */
  lastYearVisibility: boolean
  /**
   * Chart settings actions
   */
  chartSettingsActions: Common.ContextMenuAction[]
  /**
   * Attributes for the grouping mode
   */
  groupingModeAttributes: GroupingModeAttributes
  /**
   * Attributes for the regular mode
   */
  regularModeAttributes: RegularModeAttributes
}

export const INSIGHT_CHART_HEIGHT = '480px'

const InsightsChartComponent: React.FC<InsightsChartComponentProps> = ({
  gridState,
  isFetching,
  dataset = [],
  lines = [],
  annotations = [],
  events = [],
  targetName,
  targetUnit,
  eventsToExclude,
  eventsVisibility,
  lastYearVisibility,
  chartSettingsActions,
  groupingModeAttributes,
  regularModeAttributes,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const hasGroupingEnabled = useMemo(() => {
    return gridState && gridState.groupingModel && gridState.groupingModel.length > 0
  }, [gridState])

  const selectedRows = useMemo(() => {
    return hasGroupingEnabled && gridState ? (gridState.rowSelectionModel || []) : []
  }, [gridState, hasGroupingEnabled])

  const hasNoSelection = useMemo(() => {
    return ((gridState?.rowSelectionModel || []).length === 0) && (gridState?.rowSelectionModelMode === 'include')
  }, [gridState])

  const disableTodayLine = useMemo(() => {
    if (annotations && annotations.length > 0) {
      return true
    }

    /**
     * If the time window is set to from today, we should disable the today line
     */
    if (gridState && gridState.timeWindow && gridState.timeWindow[0] !== null && gridState.timeWindow[1] === null) {
      const from = moment(gridState.timeWindow[0])

      return from.isSame(moment().utc().startOf('day'))
    }

    return false
  }, [gridState, annotations])

  const filteredLines = useMemo(() => {
    if (lastYearVisibility) {
      return lines
    }

    return lines.filter((line) => {
      return !line.id.includes(INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD)
    })
  }, [lines, lastYearVisibility])

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    width: '100%',
    minHeight: INSIGHT_CHART_HEIGHT,
    borderRadius: '7px',
    border: `1px solid ${theme.palette.new.business_black_20}`,
    marginBottom: theme.spacing(2),
  }

  const warpperStypes = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  }

  if (hasNoSelection && !isFetching) {
    return (
      <Box
        data-testid={`${InsightsChartComponent.name}-no-selection`}
        sx={{
          ...containerStyles,
          ...warpperStypes,
        }}
      >
        <Typography
          variant='h6'
        >
          {intl.formatMessage({ id: 'insights.chart.noSelection.title' })}
        </Typography>
        <Typography>
          {intl.formatMessage({ id: 'insights.chart.noSelection.text' })}
        </Typography>
      </Box>
    )
  }

  if ((!dataset || dataset.length === 0) && !isFetching && gridState) {
    return (
      <Box
        data-testid={`${InsightsChartComponent.name}-no-data`}
        sx={{
          ...containerStyles,
          ...warpperStypes,
        }}
      >
        <Typography
          variant='h6'
        >
          {intl.formatMessage({ id: 'insights.chart.noData' })}
        </Typography>
      </Box>
    )
  }

  if ((isFetching || !gridState) && ((lines.length === 0) || (!dataset || dataset.length === 0))) {
    return (
      <Box
        data-testid={`${InsightsChartComponent.name}-loading`}
        sx={{
          width: '100%',
          height: INSIGHT_CHART_HEIGHT,
          borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
          border: `1px solid ${theme.palette.new.business_black_20}`,
          marginBottom: theme.spacing(2),
          overflow: 'hidden',
        }}
      >
        <LoadingFallbackComponent fluid={true} size='medium' />
      </Box>
    )
  }

  return (
    <Box
      data-testid={InsightsChartComponent.name}
      sx={containerStyles}
    >
      <InsightsLineChartComponent
        dataset={dataset}
        lines={filteredLines}
        targetName={targetName}
        targetUnit={targetUnit}
        hasGroupingEnabled={hasGroupingEnabled}
        isFetching={isFetching}
        enableTodayLine={!disableTodayLine}
        selectedRows={selectedRows}
        annotations={annotations}
        events={events}
        eventsVisibility={eventsVisibility}
        eventsToExclude={eventsToExclude}
        lastYearVisibility={lastYearVisibility}
        chartSettingsActions={chartSettingsActions}
        groupingModeAttributes={groupingModeAttributes}
        regularModeAttributes={regularModeAttributes}
      />
    </Box>
  )
}

export default InsightsChartComponent
