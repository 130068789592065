import groupBy from 'lodash.groupby'

import palette from '@configuration/theme/theme.palette'
import { UPLOAD_DOCUMENTATION_URL } from '@constants/common.constants'

import SAPIcon from '@icons/flow/sap.icon'
import MSSQLIcon from '@icons/flow/msSQL.icon'
import MySQLIcon from '@icons/flow/mySQL.icon'
import PostgreSQLIcon from '@icons/flow/postgreSQL.icon'
import RedshiftIcon from '@icons/flow/redshift.icon'
import S3Icon from '@icons/flow/s3.icon'
import Y42Icon from '@icons/flow/y42.icon'
import BigQueryIcon from '@icons/flow/bigQuery.icon'
import SnowflakeIcon from '@icons/flow/snowflake.icon'
import ShopifyIcon from '@icons/flow/shopify.icon'
import FacebookIcon from '@icons/flow/facebook.icon'
import GTMIcon from '@icons/flow/gtm.icon'
import GAIcon from '@icons/flow/ga.icon'
import ParetosIcon from '@icons/flow/paretos.icon'
import LayoutIcon from '@icons/flow/layout.icon'
import TikTokIcon from '@icons/flow/tikTok.icon'
import PinterestIcon from '@icons/flow/pinterest.icon'
import GoogleSheetsIcon from '@icons/flow/googleSheets.icon'
import AmazonIcon from '@icons/flow/amazon.icon'
import BingIcon from '@icons/flow/bing.icon'
import BrokenLinkIcon from '@icons/flow/brokenLink.icon'

import SAPWhiteIcon from '@icons/flow/white-flow/sap.icon'
import MSSQLWhiteIcon from '@icons/flow/white-flow/msSQL.icon'
import MySQLWhiteIcon from '@icons/flow/white-flow/mySQL.icon'
import PostgreSQLWhiteIcon from '@icons/flow/white-flow/postgreSQL.icon'
import RedshiftWhiteIcon from '@icons/flow/white-flow/redshift.icon'
import S3WhiteIcon from '@icons/flow/white-flow/s3.icon'
import Y42WhiteIcon from '@icons/flow/white-flow/y42.icon'
import BigQueryWhiteIcon from '@icons/flow/white-flow/bigQuery.icon'
import SnowflakeWhiteIcon from '@icons/flow/white-flow/snowflake.icon'
import ShopifyWhiteIcon from '@icons/flow/white-flow/shopify.icon'
import FacebookWhiteIcon from '@icons/flow/white-flow/facebook.icon'
import GTMWhiteIcon from '@icons/flow/white-flow/gtm.icon'
import GAWhiteIcon from '@icons/flow/white-flow/ga.icon'
import ParetosWhiteIcon from '@icons/flow/white-flow/paretos.icon'
import LayoutWhiteIcon from '@icons/flow/white-flow/layout.icon'
import TikTokWhiteIcon from '@icons/flow/white-flow/tikTok.icon'
import PinterestWhiteIcon from '@icons/flow/white-flow/pinterest.icon'
import GoogleSheetsWhiteIcon from '@icons/flow/white-flow/googleSheets.icon'
import AmazonWhiteIcon from '@icons/flow/white-flow/amazon.icon'
import BingWhiteIcon from '@icons/flow/white-flow/bing.icon'
import BrokenLinkWhiteIcon from '@icons/flow/white-flow/brokenLinkWhite.icon'

import SAPDisabledIcon from '@icons/flow/disabled-flow/sap.icon'
import MSSQLDisabledIcon from '@icons/flow/disabled-flow/msSQL.icon'
import MySQLDisabledIcon from '@icons/flow/disabled-flow/mySQL.icon'
import PostgreSQLDisabledIcon from '@icons/flow/disabled-flow/postgreSQL.icon'
import RedshiftDisabledIcon from '@icons/flow/disabled-flow/redshift.icon'
import S3DisabledIcon from '@icons/flow/disabled-flow/s3.icon'
import Y42DisabledIcon from '@icons/flow/disabled-flow/y42.icon'
import BigQueryDisabledIcon from '@icons/flow/disabled-flow/bigQuery.icon'
import SnowflakeDisabledIcon from '@icons/flow/disabled-flow/snowflake.icon'
import ShopifyDisabledIcon from '@icons/flow/disabled-flow/shopify.icon'
import FacebookDisabledIcon from '@icons/flow/disabled-flow/facebook.icon'
import GTMDisabledIcon from '@icons/flow/disabled-flow/gtm.icon'
import GADisabledIcon from '@icons/flow/disabled-flow/ga.icon'
import ParetosDisabledIcon from '@icons/flow/disabled-flow/paretos.icon'
import LayoutDisabledIcon from '@icons/flow/disabled-flow/layout.icon'
import TikTokDisabledIcon from '@icons/flow/disabled-flow/tikTok.icon'
import PinterestDisabledIcon from '@icons/flow/disabled-flow/pinterest.icon'
import GoogleSheetsDisabledIcon from '@icons/flow/disabled-flow/googleSheets.icon'
import AmazonDisabledIcon from '@icons/flow/disabled-flow/amazon.icon'
import BingDisabledIcon from '@icons/flow/disabled-flow/bing.icon'
import BrokenLinkDisabledIcon from '@icons/flow/disabled-flow/brokenLinkWhite.icon'

import {
  CONNECT_DATA_SOURCE_MODAL_NAME,
  USE_CASE_DATA_TYPES_MODAL_NAME,
  PARETOS_DATA_SOURCE_MODAL_NAME,
} from '@constants/modals.constants'

import ParetosSftpIcon from '@icons/flow/paretosSftp.icon'
import ParetosSftpWhiteIcon from '@icons/flow/white-flow/paretosSftp.icon'
import ParetosSftpDisabledIcon from '@icons/flow/disabled-flow/paretosSftp.icon'

export const NODE_TYPES = {
  INPUT: 'new-input',
  EMPTY_GROUPING: 'empty-grouping',
  GROUPING_ATTRIBUTES: 'grouping-attributes',
  ACTIVE_INPUTS_GROUP: 'active-input-parameter-group',
  GENERIC_INPUTS_GROUP: 'generic-input-parameter-group',
  INPUTS_GROUP: 'input-parameter-group',
  TARGETS_GROUP: 'output-parameter-group',
  EVALUATION_PROFILE_GROUP: 'evaluation-profile-parameter-group',
  PIPELINE_ITEM: 'pipeline-item',
  NOTIFICATION_TYPE: 'notification-type',
  NOTIFICATION_SINK_INFO: 'notification-sink-info',
  DEFAULT: 'default',
} as const

export type NodeTypeValues = (typeof NODE_TYPES)[keyof typeof NODE_TYPES]

export enum POSSIBLE_TEMPLATE_TYPES {
  PASSIVE = 'PASSIVE_INPUT',
  ACTIVE = 'ACTIVE_INPUT',
  EXTERNAL = 'EXTERNAL_INPUT',
  TARGET = 'TARGET',
}

export enum INPUT_TYPES {
  PASSIVE = 'PASSIVE',
  ACTIVE = 'ACTIVE',
  GENERIC = 'GENERIC',
  GROUPING = 'GROUPING',
  NONE = 'NONE',
}

export const INPUT_TYPE_DEFAULT = INPUT_TYPES.PASSIVE

export const INPUT_TYPES_OPTIONS = [{
  value: INPUT_TYPES.ACTIVE,
  labelKey: 'connect.block.input_type.active',
}, {
  value: INPUT_TYPES.PASSIVE,
  labelKey: 'connect.block.input_type.passive',
}]

export const GOAL_TYPES = {
  MAXIMIZE: 'MAXIMIZE',
  MINIMIZE: 'MINIMIZE',
}

export const GOAL_OPTIONS = [{
  value: GOAL_TYPES.MAXIMIZE,
  labelKey: 'connect.block.output.maximize',
}, {
  value: GOAL_TYPES.MINIMIZE,
  labelKey: 'connect.block.output.minimize',
}]

export const GOAL_DEFAULT = GOAL_TYPES.MAXIMIZE

export enum SOURCE_TYPES {
  AMAZON_ADS = 'AMAZON_ADS',
  BING_ADS = 'BING_ADS',
  FACEBOOK = 'FACEBOOK',
  GOOGLE_ADS = 'GOOGLE_ADS',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  GOOGLE_ANALYTICS_V4 = 'GOOGLE_ANALYTICS_V4',
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
  PINTEREST = 'PINTEREST',
  SAP = 'SAP',
  SHOPIFY = 'SHOPIFY',
  SNOWFLAKE = 'SNOWFLAKE',
  S3 = 'S3',
  Y42 = 'Y42',
  MY_SQL = 'MY_SQL',
  MS_SQL = 'MS_SQL',
  POSTGRE_SQL = 'POSTGRE_SQL',
  BIGQUERY = 'BIGQUERY',
  REDSHIFT = 'REDSHIFT',
  TIKTOK_ADS = 'TIKTOK_ADS',
  CUSTOM = 'CUSTOM',
  PARETOS_SFTP = 'PARETOS_SFTP',
  PARETOS = 'PARETOS',
  NO_CONNECTION = 'NO_CONNECTION',
}

export enum SOURCE_TYPES_CATEGORIES {
  ADVERTISING = 'ADVERTISING',
  ANALYTICS_AUTOMATION = 'ANALYTICS_AUTOMATION',
  EMAIL_MARKETING = 'EMAIL_MARKETING',
  ECOMMERCE = 'ECOMMERCE',
  DATABASES = 'DATABASES',
  FILE_SERVICES = 'FILE_SERVICES',
  FILE_UPLOAD = 'FILE_UPLOAD',
  ADDITIONAL = 'ADDITIONAL',
  NONE = 'NONE'
}

export type CategoryToLabelMap = {
  [key in SOURCE_TYPES_CATEGORIES]: string
}

export const CATEGORIES_TO_LABEL_MAP: CategoryToLabelMap = {
  [SOURCE_TYPES_CATEGORIES.NONE]: '',
  [SOURCE_TYPES_CATEGORIES.ADVERTISING]: 'connect.block.source_type.categories.advertising',
  [SOURCE_TYPES_CATEGORIES.ANALYTICS_AUTOMATION]: 'connect.block.source_type.categories.analytics_automation',
  [SOURCE_TYPES_CATEGORIES.ECOMMERCE]: 'connect.block.source_type.categories.ecommerce',
  [SOURCE_TYPES_CATEGORIES.EMAIL_MARKETING]: 'connect.block.source_type.categories.email_marketing',
  [SOURCE_TYPES_CATEGORIES.DATABASES]: 'connect.block.source_type.categories.databases',
  [SOURCE_TYPES_CATEGORIES.FILE_SERVICES]: 'connect.block.source_type.categories.file_services',
  [SOURCE_TYPES_CATEGORIES.FILE_UPLOAD]: 'connect.block.source_type.categories.file_upload',
  [SOURCE_TYPES_CATEGORIES.ADDITIONAL]: 'connect.block.source_type.categories.additional',
}

export const SOURCE_TYPE_DEFAULT = SOURCE_TYPES.NO_CONNECTION
export const UNIT_LABEL_DEFAULT = ''
export const NO_UNIT_LABEL_PLACEHOLDER = '-'

export const SOURCE_TYPE_FALLBACK_OPTION = {
  value: SOURCE_TYPES.NO_CONNECTION,
  labelKey: 'connect.block.source_type.paretos',
  descriptionKey: 'connect.block.source_type.paretos.description',
  helpKey: 'connect.block.source_type.paretos.help',
  hidden: true,
}

export interface SourceTypeOption {
  value: SOURCE_TYPES,
  labelKey?: string,
  descriptionKey?: string,
  helpKey?: string,
  documentationLink?: string,
  videoLink?: string,
  videoHeight?: string,
  sourceId?: string,
  modalName?: string,
  category: SOURCE_TYPES_CATEGORIES,
  hidden?: boolean,
  forbiddenParameterTypes?: POSSIBLE_TEMPLATE_TYPES[],
}

export const SOURCE_TYPES_OPTIONS: SourceTypeOption[] = [
  {
    value: SOURCE_TYPES.NO_CONNECTION,
    labelKey: 'connect.block.source_type.no_source',
    category: SOURCE_TYPES_CATEGORIES.NONE,
    hidden: true,
  },
  {
    value: SOURCE_TYPES.AMAZON_ADS,
    labelKey: 'connect.block.source_type.amazon',
    descriptionKey: 'connect.block.source_type.amazon.description',
    helpKey: 'connect.block.source_type.amazon.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/7d40718cbda345aba859590e5fbe4552',
    videoHeight: '585px',
    sourceId: 'c6b0a29e-1da9-4512-9002-7bfd0cba2246',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ADVERTISING,
  },
  {
    value: SOURCE_TYPES.FACEBOOK,
    labelKey: 'connect.block.source_type.facebook',
    descriptionKey: 'connect.block.source_type.facebook.description',
    helpKey: 'connect.block.source_type.facebook.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/ff465539a9814b74937b00f7b2b3cacd',
    videoHeight: '550px',
    sourceId: 'e7778cfc-e97c-4458-9ecb-b4f2bba8946c',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ADVERTISING,
  },
  {
    value: SOURCE_TYPES.GOOGLE_ADS,
    labelKey: 'connect.block.source_type.gads',
    descriptionKey: 'connect.block.source_type.gads.description',
    helpKey: 'connect.block.source_type.gads.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/69e0289eed2c4d85aa8e55b56753da35',
    videoHeight: '585px',
    sourceId: '253487c0-2246-43ba-a21f-5116b20a2c50',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ADVERTISING,
  },
  {
    value: SOURCE_TYPES.BING_ADS,
    labelKey: 'connect.block.source_type.bing',
    descriptionKey: 'connect.block.source_type.bing.description',
    helpKey: 'connect.block.source_type.bing.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/b51e6d1cd0964d388a1e534a500bdbd9',
    videoHeight: '586px',
    sourceId: '47f25999-dd5e-4636-8c39-e7cea2453331',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ADVERTISING,
  },
  {
    value: SOURCE_TYPES.PINTEREST,
    labelKey: 'connect.block.source_type.pinterest',
    descriptionKey: 'connect.block.source_type.pinterest.description',
    helpKey: 'connect.block.source_type.pinterest.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/71563ff0ea0541959f336fe85d7711bd',
    videoHeight: '586px',
    sourceId: '5cb7e5fe-38c2-11ec-8d3d-0242ac130003',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ADVERTISING,
  },
  {
    value: SOURCE_TYPES.TIKTOK_ADS,
    labelKey: 'connect.block.source_type.tiktok',
    descriptionKey: 'connect.block.source_type.tiktok.description',
    helpKey: 'connect.block.source_type.tiktok.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/bfcf0e2f749a4b43a592e1c0ce25f88f',
    videoHeight: '586px',
    sourceId: '4bfac00d-ce15-44ff-95b9-9e3c3e8fbd35',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ADVERTISING,
  },
  {
    labelKey: 'connect.block.source_type.ga',
    descriptionKey: 'connect.block.source_type.ga.description',
    helpKey: 'connect.block.source_type.ga.help',
    value: SOURCE_TYPES.GOOGLE_ANALYTICS,
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d5a927c64d343a683c6f0e9c6b42170',
    videoHeight: '495px',
    sourceId: 'eff3616a-f9c3-11eb-9a03-0242ac130003',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ANALYTICS_AUTOMATION,
  },
  {
    labelKey: 'connect.block.source_type.ga_v4',
    descriptionKey: 'connect.block.source_type.ga.description',
    helpKey: 'connect.block.source_type.ga.help',
    value: SOURCE_TYPES.GOOGLE_ANALYTICS_V4,
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d5a927c64d343a683c6f0e9c6b42170',
    videoHeight: '495px',
    sourceId: '3cc2eafd-84aa-4dca-93af-322d9dfeec1a',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ANALYTICS_AUTOMATION,
  },
  {
    value: SOURCE_TYPES.GOOGLE_SHEETS,
    labelKey: 'connect.block.source_type.google_sheets',
    descriptionKey: 'connect.block.source_type.google_sheets.description',
    helpKey: 'connect.block.source_type.google_sheets.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/38bee7b6b9ac433589ccf591d6edbc83',
    videoHeight: '606px',
    sourceId: '71607ba1-c0ac-4799-8049-7f4b90dd50f7',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.FILE_SERVICES,
  },
  {
    value: SOURCE_TYPES.SHOPIFY,
    labelKey: 'connect.block.source_type.shopify',
    descriptionKey: 'connect.block.source_type.shopify.description',
    helpKey: 'connect.block.source_type.shopify.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/5e2be6b4ce784eecabe0eaa0c64f2b92',
    videoHeight: '572px',
    sourceId: '9da77001-af33-4bcd-be46-6252bf9342b9',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ECOMMERCE,
  },
  {
    value: SOURCE_TYPES.SNOWFLAKE,
    labelKey: 'connect.block.source_type.snowflake',
    descriptionKey: 'connect.block.source_type.snowflake.description',
    helpKey: 'connect.block.source_type.snowflake.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.S3,
    labelKey: 'connect.block.source_type.S3',
    descriptionKey: 'connect.block.source_type.S3.description',
    helpKey: 'connect.block.source_type.S3.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.Y42,
    labelKey: 'connect.block.source_type.Y42',
    descriptionKey: 'connect.block.source_type.Y42.description',
    helpKey: 'connect.block.source_type.Y42.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.REDSHIFT,
    labelKey: 'connect.block.source_type.redshift',
    descriptionKey: 'connect.block.source_type.redshift.description',
    helpKey: 'connect.block.source_type.redshift.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.POSTGRE_SQL,
    labelKey: 'connect.block.source_type.postgreSQL',
    descriptionKey: 'connect.block.source_type.postgreSQL.description',
    helpKey: 'connect.block.source_type.postgreSQL.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.BIGQUERY,
    labelKey: 'connect.block.source_type.bigQuery',
    descriptionKey: 'connect.block.source_type.bigQuery.description',
    helpKey: 'connect.block.source_type.bigQuery.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.SAP,
    labelKey: 'connect.block.source_type.sap',
    descriptionKey: 'connect.block.source_type.sap.description',
    helpKey: 'connect.block.source_type.sap.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.MY_SQL,
    labelKey: 'connect.block.source_type.mySQL',
    descriptionKey: 'connect.block.source_type.mySQL.description',
    helpKey: 'connect.block.source_type.mySQL.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.MS_SQL,
    labelKey: 'connect.block.source_type.msSQL',
    descriptionKey: 'connect.block.source_type.msSQL.description',
    helpKey: 'connect.block.source_type.msSQL.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/9d325c8703ac49d2a01ebd9bb84fea60',
    videoHeight: '550px',
    category: SOURCE_TYPES_CATEGORIES.DATABASES,
  },
  {
    value: SOURCE_TYPES.PARETOS_SFTP,
    labelKey: 'connect.block.source_type.paretos_sftp',
    descriptionKey: 'connect.block.source_type.paretos_sftp.description',
    helpKey: 'connect.block.source_type.paretos_sftp.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/0039e2196e694e809bb7ba47f3d62faf',
    videoHeight: '572px',
    sourceId: '45b66344-2a46-4f52-8d7f-e2003ddff9dc',
    modalName: CONNECT_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.FILE_SERVICES,
  },
  {
    value: SOURCE_TYPES.CUSTOM,
    labelKey: 'connect.block.source_type.custom',
    modalName: USE_CASE_DATA_TYPES_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.FILE_UPLOAD,
  },
  {
    value: SOURCE_TYPES.PARETOS,
    labelKey: 'connect.block.source_type.paretos',
    descriptionKey: 'connect.block.source_type.paretos.description',
    helpKey: 'connect.block.source_type.paretos.help',
    documentationLink: UPLOAD_DOCUMENTATION_URL,
    videoLink: 'https://www.loom.com/embed/6a3a364c29504572800e74ac3c17375b',
    videoHeight: '572px',
    modalName: PARETOS_DATA_SOURCE_MODAL_NAME,
    category: SOURCE_TYPES_CATEGORIES.ADDITIONAL,
    forbiddenParameterTypes: [
      POSSIBLE_TEMPLATE_TYPES.ACTIVE,
      POSSIBLE_TEMPLATE_TYPES.PASSIVE,
      POSSIBLE_TEMPLATE_TYPES.TARGET,
    ],
  },
]

export const DATA_SOURCES_OPTIONS: SourceTypeOption[] = SOURCE_TYPES_OPTIONS.filter((sourceTypeOption) => {
  return !sourceTypeOption.hidden
})

export const SOURCE_TYPES_OPTIONS_BY_CATEGORY = groupBy(SOURCE_TYPES_OPTIONS, (option: any) => option.category)

export const SOURCE_TYPES_TO_ICONS_MAP = {
  [SOURCE_TYPES.SAP]: SAPIcon,
  [SOURCE_TYPES.S3]: S3Icon,
  [SOURCE_TYPES.Y42]: Y42Icon,
  [SOURCE_TYPES.MY_SQL]: MySQLIcon,
  [SOURCE_TYPES.MS_SQL]: MSSQLIcon,
  [SOURCE_TYPES.POSTGRE_SQL]: PostgreSQLIcon,
  [SOURCE_TYPES.BIGQUERY]: BigQueryIcon,
  [SOURCE_TYPES.REDSHIFT]: RedshiftIcon,
  [SOURCE_TYPES.SNOWFLAKE]: SnowflakeIcon,
  [SOURCE_TYPES.FACEBOOK]: FacebookIcon,
  [SOURCE_TYPES.GOOGLE_ADS]: GTMIcon,
  [SOURCE_TYPES.GOOGLE_ANALYTICS]: GAIcon,
  [SOURCE_TYPES.GOOGLE_ANALYTICS_V4]: GAIcon,
  [SOURCE_TYPES.SHOPIFY]: ShopifyIcon,
  [SOURCE_TYPES.PARETOS_SFTP]: ParetosSftpIcon,
  [SOURCE_TYPES.CUSTOM]: LayoutIcon,
  [SOURCE_TYPES.PARETOS]: ParetosIcon,
  [SOURCE_TYPES.TIKTOK_ADS]: TikTokIcon,
  [SOURCE_TYPES.AMAZON_ADS]: AmazonIcon,
  [SOURCE_TYPES.GOOGLE_SHEETS]: GoogleSheetsIcon,
  [SOURCE_TYPES.PINTEREST]: PinterestIcon,
  [SOURCE_TYPES.BING_ADS]: BingIcon,
  [SOURCE_TYPES.NO_CONNECTION]: BrokenLinkIcon,
}

export const SOURCE_TYPES_TO_WHITE_ICONS_MAP = {
  [SOURCE_TYPES.SAP]: SAPWhiteIcon,
  [SOURCE_TYPES.S3]: S3WhiteIcon,
  [SOURCE_TYPES.Y42]: Y42WhiteIcon,
  [SOURCE_TYPES.MY_SQL]: MySQLWhiteIcon,
  [SOURCE_TYPES.MS_SQL]: MSSQLWhiteIcon,
  [SOURCE_TYPES.POSTGRE_SQL]: PostgreSQLWhiteIcon,
  [SOURCE_TYPES.BIGQUERY]: BigQueryWhiteIcon,
  [SOURCE_TYPES.REDSHIFT]: RedshiftWhiteIcon,
  [SOURCE_TYPES.SNOWFLAKE]: SnowflakeWhiteIcon,
  [SOURCE_TYPES.FACEBOOK]: FacebookWhiteIcon,
  [SOURCE_TYPES.GOOGLE_ADS]: GTMWhiteIcon,
  [SOURCE_TYPES.GOOGLE_ANALYTICS]: GAWhiteIcon,
  [SOURCE_TYPES.GOOGLE_ANALYTICS_V4]: GAWhiteIcon,
  [SOURCE_TYPES.SHOPIFY]: ShopifyWhiteIcon,
  [SOURCE_TYPES.PARETOS_SFTP]: ParetosSftpWhiteIcon,
  [SOURCE_TYPES.CUSTOM]: LayoutWhiteIcon,
  [SOURCE_TYPES.PARETOS]: ParetosWhiteIcon,
  [SOURCE_TYPES.TIKTOK_ADS]: TikTokWhiteIcon,
  [SOURCE_TYPES.AMAZON_ADS]: AmazonWhiteIcon,
  [SOURCE_TYPES.GOOGLE_SHEETS]: GoogleSheetsWhiteIcon,
  [SOURCE_TYPES.PINTEREST]: PinterestWhiteIcon,
  [SOURCE_TYPES.BING_ADS]: BingWhiteIcon,
  [SOURCE_TYPES.NO_CONNECTION]: BrokenLinkWhiteIcon,
}

export const SOURCE_TYPES_TO_DISABLED_ICONS_MAP = {
  [SOURCE_TYPES.SAP]: SAPDisabledIcon,
  [SOURCE_TYPES.S3]: S3DisabledIcon,
  [SOURCE_TYPES.Y42]: Y42DisabledIcon,
  [SOURCE_TYPES.MY_SQL]: MySQLDisabledIcon,
  [SOURCE_TYPES.MS_SQL]: MSSQLDisabledIcon,
  [SOURCE_TYPES.POSTGRE_SQL]: PostgreSQLDisabledIcon,
  [SOURCE_TYPES.BIGQUERY]: BigQueryDisabledIcon,
  [SOURCE_TYPES.REDSHIFT]: RedshiftDisabledIcon,
  [SOURCE_TYPES.SNOWFLAKE]: SnowflakeDisabledIcon,
  [SOURCE_TYPES.FACEBOOK]: FacebookDisabledIcon,
  [SOURCE_TYPES.GOOGLE_ADS]: GTMDisabledIcon,
  [SOURCE_TYPES.GOOGLE_ANALYTICS]: GADisabledIcon,
  [SOURCE_TYPES.GOOGLE_ANALYTICS_V4]: GADisabledIcon,
  [SOURCE_TYPES.SHOPIFY]: ShopifyDisabledIcon,
  [SOURCE_TYPES.PARETOS_SFTP]: ParetosSftpDisabledIcon,
  [SOURCE_TYPES.CUSTOM]: LayoutDisabledIcon,
  [SOURCE_TYPES.PARETOS]: ParetosDisabledIcon,
  [SOURCE_TYPES.TIKTOK_ADS]: TikTokDisabledIcon,
  [SOURCE_TYPES.AMAZON_ADS]: AmazonDisabledIcon,
  [SOURCE_TYPES.GOOGLE_SHEETS]: GoogleSheetsDisabledIcon,
  [SOURCE_TYPES.PINTEREST]: PinterestDisabledIcon,
  [SOURCE_TYPES.BING_ADS]: BingDisabledIcon,
  [SOURCE_TYPES.NO_CONNECTION]: BrokenLinkDisabledIcon,
}

export const PARETOS_DATA_CATEGORIES = {
  CALENDAR: 'CALENDAR',
  WEATHER: 'WEATHER',
  OTHER: 'OTHER',
}

export const PARETOS_DATA_CATEGORY_LABEL_MAP = {
  [PARETOS_DATA_CATEGORIES.CALENDAR]: 'connect.modal.connect.connect_with_paretos.categories.calendar',
  [PARETOS_DATA_CATEGORIES.WEATHER]: 'connect.modal.connect.connect_with_paretos.categories.weather',
  [PARETOS_DATA_CATEGORIES.OTHER]: 'connect.modal.connect.connect_with_paretos.categories.other',
}

export interface ParetosSourceOption {
  labelKey: string,
  unitKey: string,
  category: string,
  categoryLabel: string,
}

export const PARETOS_SOURCE_OPTIONS: ParetosSourceOption[] = [{
  labelKey: 'connect.modal.connect.connect_with_paretos.day_of_month',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.CALENDAR,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.CALENDAR],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.day_of_week',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.CALENDAR,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.CALENDAR],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.month_of_year',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.CALENDAR,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.CALENDAR],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.year',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.CALENDAR,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.CALENDAR],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.bank_holidays',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.CALENDAR,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.CALENDAR],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.school_holidays',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.CALENDAR,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.CALENDAR],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.sun_radiation',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.WEATHER,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.WEATHER],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.temperature',
  unitKey: 'connect.modal.connect.connect_with_paretos.deg',
  category: PARETOS_DATA_CATEGORIES.WEATHER,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.WEATHER],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.rain',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.WEATHER,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.WEATHER],
}, {
  labelKey: 'connect.modal.connect.connect_with_paretos.stock_price',
  unitKey: 'connect.modal.connect.connect_with_paretos.num',
  category: PARETOS_DATA_CATEGORIES.OTHER,
  categoryLabel: PARETOS_DATA_CATEGORY_LABEL_MAP[PARETOS_DATA_CATEGORIES.OTHER],
}]

export const PARETOS_SOURCE_OPTIONS_BY_CATEGORY = groupBy(PARETOS_SOURCE_OPTIONS, (option: any) => option.category)

export const DEFAULT_ARROW_FILL_COLOR = palette.new.grey_a
export const DEFAULT_ARROW_CONFIG = {
  animated: true,
  markerEnd: 'arrow',
  type: 'custom',
  style: { stroke: DEFAULT_ARROW_FILL_COLOR },
}

export const TRIPPLE_CONNECTOR_STYLES = [{
  top: 'calc(50% - 20px)',
}, {
  top: '50%',
}, {
  top: 'calc(50% + 20px)',
}]

export const NODES_WIDTHS = {
  [NODE_TYPES.INPUT]: 400,
  [NODE_TYPES.EMPTY_GROUPING]: 60,
  [NODE_TYPES.GROUPING_ATTRIBUTES]: 240,
  [NODE_TYPES.ACTIVE_INPUTS_GROUP]: 40,
  [NODE_TYPES.GENERIC_INPUTS_GROUP]: 40,
  [NODE_TYPES.EVALUATION_PROFILE_GROUP]: 40,
  [NODE_TYPES.INPUTS_GROUP]: 40,
  [NODE_TYPES.TARGETS_GROUP]: 240,
  [NODE_TYPES.PIPELINE_ITEM]: 260,
  [NODE_TYPES.NOTIFICATION_TYPE]: 240,
  [NODE_TYPES.NOTIFICATION_SINK_INFO]: 240,
  [NODE_TYPES.DEFAULT]: 400,
}

export const NODES_HEIGHT = {
  [NODE_TYPES.INPUT]: 10,
  [NODE_TYPES.EMPTY_GROUPING]: 60,
  [NODE_TYPES.GROUPING_ATTRIBUTES]: 90,
  [NODE_TYPES.ACTIVE_INPUTS_GROUP]: 90,
  [NODE_TYPES.GENERIC_INPUTS_GROUP]: 90,
  [NODE_TYPES.EVALUATION_PROFILE_GROUP]: 90,
  [NODE_TYPES.INPUTS_GROUP]: 90,
  [NODE_TYPES.TARGETS_GROUP]: 90,
  [NODE_TYPES.PIPELINE_ITEM]: 260,
  [NODE_TYPES.NOTIFICATION_TYPE]: 90,
  [NODE_TYPES.NOTIFICATION_SINK_INFO]: 170,
  [NODE_TYPES.DEFAULT]: 20,
}

export const INPUT_SOURCE_BLOCK_HEIGHT = 60
export const INPUT_SOURCE_BLOCK_HEIGHT_MARGIN = 20

export const GROUPING_ATTRIBUTES_BLOCK_HEIGHT = 100
export const GROUPING_ATTRIBUTES_BLOCK_WIDTHS = 200
export const GROUPING_ATTRIBUTES_BLOCK_WIDTHS_MARGIN = 20
export const GROUPING_ATTRIBUTES_BLOCK_HEIGHT_MARGIN = 20

export const OUTPUT_PARAMETER_BLOCK_HEIGHT = 100
export const OUTPUT_PARAMETER_BLOCK_WIDTHS = 200
export const OUTPUT_PARAMETER_BLOCK_WIDTHS_MARGIN = 20
export const OUTPUT_PARAMETER_BLOCK_HEIGHT_MARGIN = 20

export const EVALUATION_PROFILE_BLOCK_HEIGHT = 100
export const EVALUATION_PROFILE_BLOCK_WIDTHS = 200
export const EVALUATION_PROFILE_BLOCK_WIDTHS_MARGIN = 20
export const EVALUATION_PROFILE_BLOCK_HEIGHT_MARGIN = 20

export const INPUT_PARAMETER_BLOCK_WIDTHS_MARGIN = 20
export const INPUT_PARAMETER_BLOCK_HEIGHT_MARGIN = 20
export const INPUT_PARAMETER_BLOCK_HEIGHT = 100
export const INPUT_PARAMETER_BLOCK_WIDTHS = 200

export const INPUT_PARAMETER_PER_ROW = 4
export const TARGET_PARAMETER_PER_ROW = 4
export const GROUPING_ATTRIBUTES_PER_ROW = 4
export const EVALUATION_PROFILE_PER_ROW = 1
export const MAX_SOURCES_TO_SHOW = 5

export const FLOW_ELEMENTS_TRANSITIONS = {
  transition: 'border 0.3s, background 0.3s, stroke 0.3s',
}

export const FLOW_INACTIVE_ELEMENTS_TRANSITIONS = {
  transition: 'opacity 0.3s, color 0.3s',
}
