import React from 'react'
import { useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { GridPagination, GridSlotProps } from '@mui/x-data-grid-premium'
import { getPaginationLabel } from '@utils/common.utils'
import { getPaginationStyles } from '@utils/ui.utils'

import PaginationActionsComponent from '@base/utils/PaginationActions'

export interface PaginationActionsComponentProps {
  count: number,
  onPageChange: (event: any, page: number) => void,
  page: number,
  rowsPerPage: number,
  nextIconButtonProps?: {
    disabled?: boolean,
  },
  backIconButtonProps?: {
    disabled?: boolean,
  },
}

const DataGridPaginationActionsComponent = (props: GridSlotProps['pagination']) => {
  const intl = useIntl()
  const theme = useTheme()
  const { toolbarStyles, slotProps } = getPaginationStyles(theme)
  const labelDisplayedRows = getPaginationLabel(intl)

  return (
    <GridPagination
      ActionsComponent={PaginationActionsComponent}
      labelDisplayedRows={labelDisplayedRows}
      slotProps={slotProps}
      sx={toolbarStyles}
      {...props}
    />
  )
}

export default DataGridPaginationActionsComponent
