import React from 'react'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'

import { formatDateString } from '@utils/moment.utils'
import { NOTIFICATIONS_DATE_FORMAT } from '@constants/notifications.constants'
import { mapNotificationSeverityToChipBgColor, mapNotificationSeverityToChipColor } from '@utils/notifications.utils'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'

import HeaderBaseTypography from './HeaderBaseTypography.component'
import ActionButtonComponent from './ActionButton.component'

export interface NotificationTileHeaderBlockComponentProps {
  notification: Notifications.EnrichedNotificationItem
  level: 'use_case' | 'company'
  isAdmin?: boolean
  onDismissButtonClick: (notification: Notifications.NotificationItem) => void
  onMarkAsReadButtonClick: (notification: Notifications.NotificationItem) => void
}

export const NotificationTileHeaderBlockComponent: React.FC<NotificationTileHeaderBlockComponentProps> = ({
  notification,
  level,
  isAdmin,
  onDismissButtonClick,
  onMarkAsReadButtonClick,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const {
    createdAt,
    severity,
    useCaseName,
    useCaseId,
  } = notification

  const formattedDate = formatDateString(createdAt, NOTIFICATIONS_DATE_FORMAT)

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='5px'
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        gap='10px'
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          gap='10px'
        >
          <HeaderBaseTypography
            sx={{
              color: mapNotificationSeverityToChipColor(severity),
              backgroundColor: mapNotificationSeverityToChipBgColor(severity),
              textTransform: 'uppercase',
              padding: '3px',
              borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
            }}
          >
            {severity}
          </HeaderBaseTypography>

          {
            level === 'company' ? (
              <HeaderBaseTypography
                maxWidth='450px'
                noWrap={true}
                color={theme.palette.new.business_black_40}
              >
                {useCaseId ? useCaseName : intl.formatMessage({ id: 'notifications.modal.global_notifications' })}
              </HeaderBaseTypography>
            ) : (
              null
            )
          }
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          gap='10px'
        >
          {
            (isAdmin) ? (
              <ActionButtonComponent
                name='dismissNotification'
                notification={notification}
                onClick={onDismissButtonClick}
                color={theme.palette.new.rebellious_red}
                hoverColor={theme.palette.new.rebellious_red_10}
                disabled={notification.dismissed || notification.read || !notification.dismissable}
                label={intl.formatMessage({ id: 'notifications.dismiss' })}
              />
            ) : (
              null
            )
          }

          <ActionButtonComponent
            name='markNotificationAsRead'
            notification={notification}
            onClick={onMarkAsReadButtonClick}
            color={theme.palette.new.versatile_violet}
            hoverColor={theme.palette.new.versatile_violet_5}
            disabled={notification.read || notification.dismissed}
            label={intl.formatMessage({ id: 'notifications.mark_as_read' })}
          />

          <HeaderBaseTypography
            color={theme.palette.new.business_black_40}
          >
            {formattedDate}
          </HeaderBaseTypography>
        </Box>
      </Box>
    </Box>
  )
}

export default NotificationTileHeaderBlockComponent
