import { INSIGHTS_COLOR_WAY } from '@constants/insights.constants'
import {
  ANALYZE_PATH, EXPORT_PATH, MONITOR_BACKTESTING_PATH, MONITOR_LIVE_MONITORING_PATH,
  REPLENISHMENT_DISCOVER_PATH, REPLENISHMENT_EXPORT_PATH,
  REPLENISHMENT_RESULTS_PATH,
} from './routes.constants'

export const NOTIFICATIONS_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const NOTIFICATIONS_PULL_INTERVAL_IN_MS = 30000
export const NOTIFICATIONS_PAGINATION_META_DEFAULT_VALUES = {
  page: 1,
  pageSize: 10,
}

export enum NOTIFICATIONS_PLATFORMS {
  EMAIL = 'email',
  SLACK = 'slack',
}

export const NOTIFICATIONS_PLATFORMS_LIST = Object.values(NOTIFICATIONS_PLATFORMS).map((platform) => ({
  label: platform,
  value: platform,
}))

export enum NOTIFICATIONS_TYPES {
  DATA_UPLOAD = 'data_upload',
  DATA_STALE = 'data_stale',
  DATA_VALIDATION = 'data_validation',
  ELT_VALIDATION = 'elt_validation',
  FORECAST_INPUT = 'forecast_input',
  FORECAST_CONFIGURATION = 'forecast_configuration',
  FORECAST_QUALITY = 'forecast_quality',
  OPTIMIZATION_INPUT = 'optimization_input',
  OPTIMIZATION_CONSTRAINT_VIOLATION = 'optimization_constraint_violation',
  OPTIMIZATION_CHECK = 'optimization_check',
  OPTIMIZATION_INFEASIBLE = 'optimization_infeasible',
  OPTIMIZATION_RESULT = 'optimization_result',
  TEST_NOTIFICATION = 'test_notification',
}

export const NOTIFICATIONS_TYPES_LIST = Object.values(NOTIFICATIONS_TYPES).map((type) => ({
  label: type,
  value: type,
}))

export const NOTIFICATIONS_TEMPLATES_TYPES_TO_COLOR_MAP = {
  [NOTIFICATIONS_TYPES.DATA_UPLOAD]: INSIGHTS_COLOR_WAY[0].base,
  [NOTIFICATIONS_TYPES.DATA_STALE]: INSIGHTS_COLOR_WAY[1].base,
  [NOTIFICATIONS_TYPES.DATA_VALIDATION]: INSIGHTS_COLOR_WAY[2].base,
  [NOTIFICATIONS_TYPES.ELT_VALIDATION]: INSIGHTS_COLOR_WAY[3].base,
  [NOTIFICATIONS_TYPES.FORECAST_INPUT]: INSIGHTS_COLOR_WAY[4].base,
  [NOTIFICATIONS_TYPES.FORECAST_CONFIGURATION]: INSIGHTS_COLOR_WAY[5].base,
  [NOTIFICATIONS_TYPES.FORECAST_QUALITY]: INSIGHTS_COLOR_WAY[6].base,
  [NOTIFICATIONS_TYPES.OPTIMIZATION_INPUT]: INSIGHTS_COLOR_WAY[7].base,
  [NOTIFICATIONS_TYPES.OPTIMIZATION_CONSTRAINT_VIOLATION]: INSIGHTS_COLOR_WAY[8].base,
  [NOTIFICATIONS_TYPES.OPTIMIZATION_CHECK]: INSIGHTS_COLOR_WAY[9].base,
  [NOTIFICATIONS_TYPES.OPTIMIZATION_INFEASIBLE]: INSIGHTS_COLOR_WAY[10].base,
  [NOTIFICATIONS_TYPES.OPTIMIZATION_RESULT]: INSIGHTS_COLOR_WAY[11].base,
  [NOTIFICATIONS_TYPES.TEST_NOTIFICATION]: INSIGHTS_COLOR_WAY[0].base,
}

export enum NOTIFICATIONS_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
  NO_SINKS = 'nosinks',
  PENDING = 'pending',
  UNKNOWN = 'unknown',
}

export const NOTIFICATIONS_STATUS_LIST = Object.values(NOTIFICATIONS_STATUS).map((status) => ({
  label: status,
  value: status,
}))

export enum NOTIFICATIONS_SEVERITIES {
  INFO = 'info',
  INSIGHT = 'insight',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
}

export const NOTIFICATIONS_SEVERITIES_LIST = Object.values(NOTIFICATIONS_SEVERITIES).map((severity) => ({
  label: severity,
  value: severity,
}))

export const DEFAULT_EMAIL_SENDGRID_TEMPLATE_ID = 'd-d5c688f4cdc34619b796ee4f07fd82a9'
export const DEFAULT_NOTIFICATION_SINK_CONFIG: Notifications.NotificationSinkConfig = {
  id: '',
  name: '',
  description: '',
  platform: NOTIFICATIONS_PLATFORMS.SLACK,
  enabled: false,
  channel: '#',
  ignore_emails: [],
  add_emails: [],
  subject: 'Paretos Notification',
  unsubscribe_group_id: 1,
  reply_email: 'noreply@paretos.com',
  reply_email_name: 'Paretos Notifications',
  from_email: 'noreply@paretos.com',
  from_email_name: 'Paretos Notifications',
  template_id: DEFAULT_EMAIL_SENDGRID_TEMPLATE_ID,
}

export const NOTIFICATION_TYPE_TO_CONTENT_KEY_MAP: Record<Notifications.NotificationType, string> = {
  [NOTIFICATIONS_TYPES.DATA_UPLOAD]: 'notifications.content.data_upload',
  [NOTIFICATIONS_TYPES.DATA_STALE]: 'notifications.content.data_stale',
  [NOTIFICATIONS_TYPES.DATA_VALIDATION]: 'notifications.content.data_validation',
  [NOTIFICATIONS_TYPES.ELT_VALIDATION]: 'notifications.content.elt_validation',
  [NOTIFICATIONS_TYPES.FORECAST_INPUT]: 'notifications.content.forecast_input',
  [NOTIFICATIONS_TYPES.FORECAST_CONFIGURATION]: 'notifications.content.forecast_configuration',
  [NOTIFICATIONS_TYPES.FORECAST_QUALITY]: 'notifications.content.forecast_quality',
  [NOTIFICATIONS_TYPES.OPTIMIZATION_INPUT]: 'notifications.content.optimization_input',
  [NOTIFICATIONS_TYPES.OPTIMIZATION_CONSTRAINT_VIOLATION]: 'notifications.content.optimization_constraint_violation',
  [NOTIFICATIONS_TYPES.OPTIMIZATION_CHECK]: 'notifications.content.optimization_check',
  [NOTIFICATIONS_TYPES.OPTIMIZATION_INFEASIBLE]: 'notifications.content.optimization_infeasible',
  [NOTIFICATIONS_TYPES.OPTIMIZATION_RESULT]: 'notifications.content.optimization_result',
  [NOTIFICATIONS_TYPES.TEST_NOTIFICATION]: 'notifications.content.test_notification',
}

export const NOTIFICATION_ACTIONS: {
  [key: string]: Notifications.ActionType
} = {
  COCKPIT_VIEW: 'cockpit_view',
  VIEW_UPLOAD_FILE: 'view_upload_file',
  TEST_ACTION: 'test_action',
}

export const COCKPIT_VIEW_NAMES: {
  [key: string]: Notifications.CockpitViewNames
} = {
  MONITOR: 'monitor',
  MONITOR_BACKTEST: 'monitor_backtest',
  INSIGHTS: 'insights',
  RP_DISCOVER: 'rp_discover',
  RP_RESULTS: 'rp_results',
  RP_EXPORT: 'rp_export',
  EXPORT: 'export',
}

export const COCKPIT_VIEW_NAMES_TO_PATH_MAP: {
  [key: string]: string
} = {
  [COCKPIT_VIEW_NAMES.MONITOR]: MONITOR_LIVE_MONITORING_PATH,
  [COCKPIT_VIEW_NAMES.MONITOR_BACKTEST]: MONITOR_BACKTESTING_PATH,
  [COCKPIT_VIEW_NAMES.INSIGHTS]: ANALYZE_PATH,

  [COCKPIT_VIEW_NAMES.RP_DISCOVER]: REPLENISHMENT_DISCOVER_PATH,
  [COCKPIT_VIEW_NAMES.RP_RESULTS]: REPLENISHMENT_RESULTS_PATH,
  [COCKPIT_VIEW_NAMES.RP_EXPORT]: REPLENISHMENT_EXPORT_PATH,

  [COCKPIT_VIEW_NAMES.EXPORT]: EXPORT_PATH,
}

export const COCKPIT_VIEW_NAMES_TO_LOCALE_KEYS_MAP: {
  [key: string]: string
} = {
  [COCKPIT_VIEW_NAMES.MONITOR]: 'notifications.view.name.monitor',
  [COCKPIT_VIEW_NAMES.MONITOR_BACKTEST]: 'notifications.view.name.monitor_backtest',
  [COCKPIT_VIEW_NAMES.INSIGHTS]: 'notifications.view.name.insights',

  [COCKPIT_VIEW_NAMES.RP_DISCOVER]: 'notifications.view.name.rp_discover',
  [COCKPIT_VIEW_NAMES.RP_RESULTS]: 'notifications.view.name.rp_results',
  [COCKPIT_VIEW_NAMES.RP_EXPORT]: 'notifications.view.name.rp_export',

  [COCKPIT_VIEW_NAMES.EXPORT]: 'notifications.view.name.export',
}
