import moment from 'moment'
import { IntlShape } from 'react-intl'
import groupBy from 'lodash.groupby'

import {
  SCHOOL_HOLIDAYS_DE_DATA,
  SCHOOL_HOLIDAYS_DE_LIST,
  SCHOOL_HOLIDAYS_DE_TO_LABELS_MAP,
  SCHOOL_HOLIDAYS_DE,
} from '@constants/school-holidays.constants'

import {
  PROMOTION_DATES_TO_LABELS_MAP,
  PROMOTION_DAYS,
  PROMOTION_DAYS_LIST,
  SCHOOL_HOLIDAYS_GROUP_KEY,
  PROMOTIONS_GROUP_KEY,
} from '@constants/events.constants'

import {
  FEDERAL_STATES_DE,
  FEDERAL_STATES_DE_LIST,
  FEDERAL_STATES_DE_TO_LABELS_MAP,
  FEDERAL_STATES_TO_SHORT_LABELS_MAP,
} from '@constants/states-de.constants'

import { TIME_RESOLUTION } from '@constants/date.constants'

/* eslint-disable newline-per-chained-call */

/**
 * Get Thanksgiving date for a given year
 * @param year Year
 * @returns Thanksgiving date
 */
export const getThanksgiving = (year: number) => {
  // Start on Nov 1st
  const thanksgiving = moment().year(year).month(10).startOf('month')
  // Day of the week for November 1st
  const dayOfWeek = thanksgiving.day()
  // Calculate how many days to add to get to the first Thursday
  // If November 1 is after Thursday, add the necessary number of days
  const daysToThursday = (4 - dayOfWeek + 7) % 7
  // Now it's the first Thursday of November
  thanksgiving.add(daysToThursday, 'days')
  // Add 3 more weeks to get the fourth Thursday
  thanksgiving.add(21, 'days')

  return thanksgiving
}

export const PROMOTION_DATES: {
  [key in PROMOTION_DAYS]: (year: number) => Common.EventItem[]
} = {
  // Static dates
  [PROMOTION_DAYS.VALENTINES_DAY]: (year) => ([{
    from: moment(`${year}-02-14`).startOf('day'),
    to: moment(`${year}-02-14`).endOf('day'),
  }]),
  [PROMOTION_DAYS.WOMENS_DAY]: (year) => ([{
    from: moment(`${year}-03-08`).startOf('day'),
    to: moment(`${year}-03-08`).endOf('day'),
  }]),
  [PROMOTION_DAYS.EARTH_DAY]: (year) => ([{
    from: moment(`${year}-04-22`).startOf('day'),
    to: moment(`${year}-04-22`).endOf('day'),
  }]),
  [PROMOTION_DAYS.HALLOWEEN]: (year) => ([{
    from: moment(`${year}-10-31`).startOf('day'),
    to: moment(`${year}-10-31`).endOf('day'),
  }]),
  [PROMOTION_DAYS.SINGLES_DAY]: (year) => ([{
    from: moment(`${year}-11-11`).startOf('day'),
    to: moment(`${year}-11-11`).endOf('day'),
  }]),
  [PROMOTION_DAYS.NIKOLAUS]: (year) => ([{
    from: moment(`${year}-12-06`).startOf('day'),
    to: moment(`${year}-12-06`).endOf('day'),
  }]),
  [PROMOTION_DAYS.CHRISTMAS]: (year) => ([{
    from: moment(`${year}-12-24`).startOf('day'),
    to: moment(`${year}-12-26`).endOf('day'),
  }]),

  // Dynamic dates
  [PROMOTION_DAYS.EASTER]: (year) => {
    const easterDate = getEasterDateByYear(year)
    const easterStartDate = moment(`${year}-${easterDate.month}-${easterDate.day}`)

    return [{
      from: easterStartDate.clone().subtract(2, 'day').startOf('day'),
      to: easterStartDate.clone().add(1, 'day').endOf('day'),
    }]
  },

  [PROMOTION_DAYS.MOTHERS_DAY]: (year) => ([{
    from: moment().year(year).month(4).day('Sunday').add(1, 'week').startOf('day'),
    to: moment().year(year).month(4).day('Sunday').add(1, 'week').endOf('day'),
  }]),
  [PROMOTION_DAYS.FATHERS_DAY]: (year) => ([{
    from: moment().year(year).month(5).day('Sunday').startOf('day'),
    to: moment().year(year).month(5).day('Sunday').endOf('day'),
  }]),
  [PROMOTION_DAYS.PRIME_DEAL_DAYS]: (year) => {
    if (year === 2022) {
      return [{
        from: moment(`${year}-07-12`).startOf('day'),
        to: moment(`${year}-07-13`).endOf('day'),
      }, {
        from: moment(`${year}-10-11`).startOf('day'),
        to: moment(`${year}-10-12`).endOf('day'),
      }]
    }

    if (year === 2023) {
      return [{
        from: moment(`${year}-07-11`).startOf('day'),
        to: moment(`${year}-07-12`).endOf('day'),
      }, {
        from: moment(`${year}-10-10`).startOf('day'),
        to: moment(`${year}-10-11`).endOf('day'),
      }]
    }

    if (year === 2024) {
      return [{
        from: moment(`${year}-07-16`).startOf('day'),
        to: moment(`${year}-07-17`).endOf('day'),
      }, {
        from: moment(`${year}-10-08`).startOf('day'),
        to: moment(`${year}-10-09`).endOf('day'),
      }]
    }

    if (year === 2025) {
      return [{
        from: moment(`${year}-07-08`).startOf('day'),
        to: moment(`${year}-07-09`).endOf('day'),
      }, {
        from: moment(`${year}-10-08`).startOf('day'),
        to: moment(`${year}-10-09`).endOf('day'),
      }]
    }

    return [{
      from: moment(`${year}-07-08`).startOf('day'),
      to: moment(`${year}-07-09`).endOf('day'),
    }, {
      from: moment(`${year}-10-08`).startOf('day'),
      to: moment(`${year}-10-09`).endOf('day'),
    }]
  },
  [PROMOTION_DAYS.BLACK_WEEK]: (year) => {
    const thanksgiving = getThanksgiving(year)
    // Black Week starts the Friday after Thanksgiving
    const blackWeekStart = thanksgiving.clone().add(1, 'day').startOf('day')
    // Black Week ends on the Friday of the following week
    const blackWeekEnd = thanksgiving.clone().add(6, 'days').endOf('day')

    return [{
      from: blackWeekStart,
      to: blackWeekEnd,
    }]
  },
  [PROMOTION_DAYS.CYBER_MONDAY]: (year) => {
    const thanksgiving = getThanksgiving(year)
    // Cyber Monday is the Monday after Thanksgiving
    const cyberMondayStart = thanksgiving.clone().add(4, 'days').startOf('day')
    const cyberMondayEnd = thanksgiving.clone().add(4, 'days').endOf('day')

    return [{
      from: cyberMondayStart,
      to: cyberMondayEnd,
    }]
  },
  [PROMOTION_DAYS.SUPER_BOWL]: (year) => ([{
    from: moment().year(year).month(1).startOf('month').day('Sunday').add(2, 'week').startOf('day'),
    to: moment().year(year).month(1).startOf('month').day('Sunday').add(2, 'week').endOf('day'),
  }]),
}

/**
 * Get events dates for a given year range
 * @param intl IntlShape
 * @param startYear Start year
 * @param endYear End year
 * @returns Events dates
 */
export const getEventsDatesByYearRange = (startYear: number, endYear: number) => {
  const events = [] as Insights.BaseChartEventItem[]

  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i)
  const promotionDatesKeys = Object.keys(PROMOTION_DATES)
  const getUnixTimestamp = (date: moment.Moment) => Math.floor(date.utc(true).valueOf() / 1000)

  years.forEach((year) => {
    promotionDatesKeys.forEach((promotionKey) => {
      const key = promotionKey as PROMOTION_DAYS
      const promotionsForYear = PROMOTION_DATES[key](year)

      promotionsForYear.forEach((promotion) => {
        events.push({
          id: key,
          key,
          group: PROMOTIONS_GROUP_KEY,
          labelKey: PROMOTION_DATES_TO_LABELS_MAP[key],
          from: getUnixTimestamp(promotion.from),
          to: getUnixTimestamp(promotion.to),
        })
      })
    })

    const schoolHolidaysForSelectedYear = SCHOOL_HOLIDAYS_DE_DATA[year]

    if (schoolHolidaysForSelectedYear) {
      const availableRegions = Object.keys(schoolHolidaysForSelectedYear)

      availableRegions.forEach((region) => {
        const regionKey = region as FEDERAL_STATES_DE
        const regionHolidays = schoolHolidaysForSelectedYear[regionKey]
        const availableSchoolHolidays = Object.keys(regionHolidays)

        availableSchoolHolidays.forEach((schoolHoliday) => {
          const schoolHolidayKey = schoolHoliday as SCHOOL_HOLIDAYS_DE
          const schoolHolidayDates = regionHolidays[schoolHolidayKey]

          schoolHolidayDates.forEach((schoolHolidayDate) => {
            events.push({
              id: getSchoolHolidayId(regionKey, schoolHolidayKey),
              key: schoolHolidayKey,
              group: SCHOOL_HOLIDAYS_GROUP_KEY,
              region: regionKey,
              labelKey: SCHOOL_HOLIDAYS_DE_TO_LABELS_MAP[schoolHolidayKey],
              from: getUnixTimestamp(schoolHolidayDate.from),
              to: getUnixTimestamp(schoolHolidayDate.to),
            })
          })
        })
      })
    }
  })

  return events
}

/**
 * Get school holiday id
 * @param state Federal state
 * @param schoolHoliday School holiday
 * @returns School holiday id
 */
export const getSchoolHolidayId = (state: FEDERAL_STATES_DE, schoolHoliday: SCHOOL_HOLIDAYS_DE) => `${state}_${schoolHoliday}`

/**
 * Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar
 * based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php
 * @param year Year
 *
 * @returns Easter year, month and day
 */
export const getEasterDateByYear = (year: number) => {
  // eslint-disable-next-line
	var f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4)

  return {
    year: String(year),
    month: month < 10 ? `0${month}` : String(month),
    day: day < 10 ? `0${day}` : String(day),
  }
}

export const PROMOTION_DAYS_LIST_SORTED = PROMOTION_DAYS_LIST.sort((a, b) => {
  const year = new Date().getFullYear()

  const promotionsA = PROMOTION_DATES[a](year)
  const promotionsB = PROMOTION_DATES[b](year)

  const dateA = promotionsA[promotionsA.length - 1].from
  const dateB = promotionsB[promotionsB.length - 1].from

  return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0
})

/**
 * Get promotion details at a given data point
 *
 * @param param
 * @param param.intl IntlShape
 * @param param.data Data point
 * @param param.eventsVisibility Flag to show promotion days
 * @param param.eventsToExclude Promotion days to exclude
 * @returns Promotion details at a given data point
 */
export const getEventDetailsAtDatapoint = ({
  intl,
  data,
  eventsVisibility,
  eventsToExclude = [],
}: {
  data: Insights.BaseChartDatasetItem
  eventsVisibility: boolean
  eventsToExclude?: string[]
  intl: IntlShape
}) => {
  const events = (eventsVisibility ? data.events || [] : []) as Insights.BaseChartEventItem[]
  const filteredEvents = events.filter((event) => !eventsToExclude.includes(event.id))
  const hasEvents = filteredEvents && filteredEvents.length > 0
  const eventsLabel = intl.formatMessage({ id: filteredEvents && filteredEvents.length > 1 ? 'common.events' : 'common.event' })

  return {
    hasEvents,
    label: eventsLabel,
    formattedEvents: formatEvents(intl, filteredEvents, eventsToExclude),
  }
}

/**
 * Format promotion events
 * @param intl IntlShape
 * @param value Promotion events as label keys
 * @returns Formatted promotion events
 */
export const formatEvents = (intl: IntlShape, events: Insights.BaseChartEventItem[], eventsToExclude?: string[]) => {
  const groupedEvents = groupBy(events, 'group')
  const promotions = (groupedEvents[PROMOTIONS_GROUP_KEY] || [])
  const schoolHolidays = (groupedEvents[SCHOOL_HOLIDAYS_GROUP_KEY] || [])

  const formattedPromotions = promotions.map((event: Insights.BaseChartEventItem) => {
    return intl.formatMessage({ id: event.labelKey })
  }).join(', ')

  const formattedEvents: string[] = []

  if (promotions.length > 0) {
    formattedEvents.push(formattedPromotions)
  }

  if (schoolHolidays.length > 0) {
    const schoolHolidaysRegionKeysInDatapoint = schoolHolidays.map((event: Insights.BaseChartEventItem) => event.region as FEDERAL_STATES_DE).filter(Boolean)
    const areAllRegionsInHolidays = schoolHolidaysRegionKeysInDatapoint.length === FEDERAL_STATES_DE_LIST.length

    const excludedRegions = FEDERAL_STATES_DE_LIST.filter((region) => {
      const allSchoolHolidaysForRegion = SCHOOL_HOLIDAYS_DE_LIST.map((schoolHoliday) => getSchoolHolidayId(region, schoolHoliday))

      return allSchoolHolidaysForRegion.some((schoolHolidayId) => eventsToExclude?.includes(schoolHolidayId))
    })

    const hasExludedRegions = excludedRegions.length > 0

    const missingRegions = FEDERAL_STATES_DE_LIST.filter((region) => !schoolHolidaysRegionKeysInDatapoint.includes(region))
    const missingRegionsLabels = missingRegions.map((region) => {
      return intl.formatMessage({ id: FEDERAL_STATES_TO_SHORT_LABELS_MAP[region] })
    }).join(', ')

    const selectedRegionsLabels = schoolHolidaysRegionKeysInDatapoint.map((region) => {
      return intl.formatMessage({ id: FEDERAL_STATES_TO_SHORT_LABELS_MAP[region] })
    }).join(', ')

    if (areAllRegionsInHolidays && !hasExludedRegions) {
      formattedEvents.push(intl.formatMessage({ id: 'common.events.school_holidays.all_regions' }))
    } else if (schoolHolidaysRegionKeysInDatapoint.length > 8 && !hasExludedRegions) {
      formattedEvents.push(intl.formatMessage({ id: 'common.events.school_holidays.excluding_regions' }, { regions: missingRegionsLabels }))
    } else {
      formattedEvents.push(intl.formatMessage({ id: 'common.events.school_holidays.regions' }, { regions: selectedRegionsLabels }))
    }
  }

  return formattedEvents.join(', ')
}

export interface GroupedEventItem {
  id: string
  labelKey: string
  subItems: GroupedEventItem[]
}

export interface GroupedEvents {
  key: string
  labelKey: string
  groupLabelKey: string
  items: GroupedEventItem[]
}

export const GROUPED_PROMOTIONS: GroupedEvents = {
  key: PROMOTIONS_GROUP_KEY,
  labelKey: 'common.events.special_days',
  groupLabelKey: 'common.events.special_days.group_label',
  items: PROMOTION_DAYS_LIST_SORTED.map((day) => ({
    id: day,
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[day],
    subItems: [],
  })),
}

export const GROUPED_SCHOOL_HOLIDAYS: GroupedEvents = {
  key: SCHOOL_HOLIDAYS_GROUP_KEY,
  labelKey: 'common.events.school_holidays',
  groupLabelKey: 'common.events.school_holidays.group_label',
  items: FEDERAL_STATES_DE_LIST.map((state) => ({
    id: state,
    labelKey: FEDERAL_STATES_DE_TO_LABELS_MAP[state],
    subItems: SCHOOL_HOLIDAYS_DE_LIST.map((holiday) => ({
      id: getSchoolHolidayId(state, holiday),
      labelKey: SCHOOL_HOLIDAYS_DE_TO_LABELS_MAP[holiday],
      subItems: [],
    })),
  })),
}

export const GROUPED_EVENTS = [GROUPED_PROMOTIONS, GROUPED_SCHOOL_HOLIDAYS]
export const ALL_EVENT_ITEMS_IDS = GROUPED_EVENTS.flatMap((group) => [
  ...group.items.map((item) => item.id),
  ...group.items.flatMap((item) => item.subItems.map((subItem) => subItem.id)),
])

export const DEFAULT_EVENTS_TO_EXCLUDE = []

/**
 * Filters the events data for the given date
 *
 * @param events List of events
 * @param resolution Resolution
 * @param datapointTimestamp Datapoint timestamp
 * @returns Events at the given date
 */
export const getEventsAtDate = (
  events: Insights.BaseChartEventItem[],
  resolution: TIME_RESOLUTION,
  datapointTimestamp: number,
) => {
  const resolutionCheck = {
    [TIME_RESOLUTION.DAILY]: (from: number, to: number) => datapointTimestamp >= from && datapointTimestamp <= to,
    [TIME_RESOLUTION.WEEKLY]: (from: number, to: number) => moment.unix(datapointTimestamp).isBetween(moment.unix(from), moment.unix(to), 'week', '[]'),
    [TIME_RESOLUTION.MONTHLY]: (from: number, to: number) => moment.unix(datapointTimestamp).isBetween(moment.unix(from), moment.unix(to), 'month', '[]'),
  }

  const check = resolutionCheck[resolution] || (() => false)

  return events.filter(({ from, to }) => check(from, to))
}
