import { createSelector } from 'reselect'
import { State } from '@redux/modules/types'
import { getUseCasesList } from '@redux/modules/use-case/use-case.selectors'

import {
  CREATE_NOTIFICATIONS_ROUTINGS,
  CREATE_NOTIFICATIONS_SINK,
  CREATE_NOTIFICATIONS_TEMPLATE,
  DISMISS_NOTIFICATIONS,
  MARK_NOTIFICATIONS_ALL_AS_READ,
  MARK_NOTIFICATIONS_AS_READ,
  REMOVE_NOTIFICATIONS_ROUTINGS,
  REMOVE_NOTIFICATIONS_SINK,
  REMOVE_NOTIFICATIONS_TEMPLATE,
  REQUEST_DISPATCHED_NOTIFICATIONS,
  REQUEST_NOTIFICATIONS,
  REQUEST_NOTIFICATIONS_ROUTING_GRAPH,
  REQUEST_NOTIFICATIONS_ROUTINGS,
  REQUEST_NOTIFICATIONS_SINKS,
  REQUEST_NOTIFICATIONS_TEMPLATES,
  SEND_NOTIFICATION,
  SETUP_NOTIFICATIONS_ROUTINGS,
  TEST_NOTIFICATIONS_SINK,
  UPDATE_NOTIFICATIONS_ROUTINGS,
  UPDATE_NOTIFICATIONS_SINK,
  UPDATE_NOTIFICATIONS_TEMPLATE,
} from './notifications.action-types'

export const getNotificationsPaginationMeta = (state: State) => {
  return state.notifications.notificationsPaginationMeta
}

export const getNotifications = (state: State) => {
  return state.notifications.notifications
}

export const getNotificationsList = (state: State) => {
  return (state.notifications.notifications?.items || [])
}

export const getNotificationsTotal = (state: State) => {
  return state.notifications.notifications?.total || 0
}

export const getUnreadCountOfList = (state: State) => {
  return state.notifications.notifications?.unreadCount || 0
}

export const getGlobalUnreadCount = (state: State) => {
  return state.notifications.unreadNotificationsCount
}

export const getEnrichedNotifications = createSelector(
  getNotificationsList,
  getUseCasesList,
  (notifications, useCases) => (notifications || [])
    .map((notification) => ({
      ...notification,
      useCaseName: notification.useCaseId ? (useCases.find((useCase) => useCase.useCaseId === notification.useCaseId)?.name || '') : '',
    } as Notifications.EnrichedNotificationItem)),
)

export const isFetchingNotifications = (state: State) => {
  return state.notifications.fetchingKeys.includes(REQUEST_NOTIFICATIONS)
}

export const isFetchingNotificationsRoutings = (state: State) => {
  return state.notifications.fetchingKeys.includes(REQUEST_NOTIFICATIONS_ROUTINGS)
}

export const isFetchingDispatchedNotifications = (state: State) => {
  return state.notifications.fetchingKeys.includes(REQUEST_DISPATCHED_NOTIFICATIONS)
}

export const isFetchingNotificationsSinks = (state: State) => {
  return state.notifications.fetchingKeys.includes(REQUEST_NOTIFICATIONS_SINKS)
}

export const isFetchingNotificationsTemplates = (state: State) => {
  return state.notifications.fetchingKeys.includes(REQUEST_NOTIFICATIONS_TEMPLATES)
}

export const isSubmittingNotificationsTemplates = (state: State) => {
  return state.notifications.fetchingKeys.includes(CREATE_NOTIFICATIONS_TEMPLATE) ||
    state.notifications.fetchingKeys.includes(UPDATE_NOTIFICATIONS_TEMPLATE) ||
    state.notifications.fetchingKeys.includes(REMOVE_NOTIFICATIONS_TEMPLATE)
}

export const isSubmittingNotificationsSinks = (state: State) => {
  return state.notifications.fetchingKeys.includes(CREATE_NOTIFICATIONS_SINK) ||
    state.notifications.fetchingKeys.includes(UPDATE_NOTIFICATIONS_SINK) ||
    state.notifications.fetchingKeys.includes(REMOVE_NOTIFICATIONS_SINK) ||
    state.notifications.fetchingKeys.includes(TEST_NOTIFICATIONS_SINK)
}

export const isSubmittingNotificationsRoutings = (state: State) => {
  return state.notifications.fetchingKeys.includes(CREATE_NOTIFICATIONS_ROUTINGS) ||
    state.notifications.fetchingKeys.includes(UPDATE_NOTIFICATIONS_ROUTINGS) ||
    state.notifications.fetchingKeys.includes(REMOVE_NOTIFICATIONS_ROUTINGS) ||
    state.notifications.fetchingKeys.includes(SETUP_NOTIFICATIONS_ROUTINGS)
}

export const isSubmittingNotificationsStatus = (state: State) => {
  return state.notifications.fetchingKeys.includes(MARK_NOTIFICATIONS_AS_READ) ||
    state.notifications.fetchingKeys.includes(DISMISS_NOTIFICATIONS)
}

export const isSubmittingNotificationsMarkAllAsRead = (state: State) => {
  return state.notifications.fetchingKeys.includes(MARK_NOTIFICATIONS_ALL_AS_READ)
}

export const isSettingUpDefaultRoutings = (state: State) => {
  return state.notifications.fetchingKeys.includes(SETUP_NOTIFICATIONS_ROUTINGS)
}

export const isSendingNotification = (state: State) => {
  return state.notifications.fetchingKeys.includes(SEND_NOTIFICATION)
}

export const isFetchingNotificationsRoutingGraph = (state: State) => {
  return state.notifications.fetchingKeys.includes(REQUEST_NOTIFICATIONS_ROUTING_GRAPH)
}

export const getNotificationsRoutingGraph = (state: State) => {
  return state.notifications.notificationsRoutingGraph
}

export const getDispatchedNotifications = (state: State) => {
  return state.notifications.dispatchedNotificationsList
}

export const getNotificationsRoutings = (state: State) => {
  return state.notifications.notificationsRoutingsList
}

export const getNotificationsSinks = (state: State) => {
  return state.notifications.notificationsSinksList
}

export const getNotificationsGlobalSinks = createSelector(
  getNotificationsSinks,
  (sinks) => sinks.filter((sink) => !sink.companyId),
)

export const getNotificationsTemplates = (state: State) => {
  return state.notifications.notificationsTemplatesList
}
