import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from '@redux/hooks'
import { Box } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { NOTIFICATIONS_OVERVIEW_MODAL_NAME } from '@constants/modals.constants'
import {
  getEnrichedNotifications,
  getNotificationsTotal,
  isFetchingNotifications,
  getNotificationsPaginationMeta,
  getUnreadCountOfList,
} from '@redux/modules/notifications/notifications.selectors'

import {
  changeNotificationsPaginationMetaAction,
  dismissNotificationsAction,
  markNotificationsAllAsReadAction,
  markNotificationsAsReadAction,
  requestNotificationsAction,
} from '@redux/modules/notifications/notifications.actions'

import { getIsAdmin, getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { getUseCasesList } from '@redux/modules/use-case/use-case.selectors'

import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import NotificationsHeaderComponent from './components/NotificationsHeader'
import NotificationsListComponent from './components/NotificationsList'

export interface NotificationsOverviewModalProps {}

const NotificationsOverviewModal: React.FC<NotificationsOverviewModalProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isAdmin = useSelector(getIsAdmin)
  const notifications = useSelector(getEnrichedNotifications)
  const notificationsTotal = useSelector(getNotificationsTotal)
  const unreadCountOfList = useSelector(getUnreadCountOfList)
  const notificationsPaginationMeta = useSelector(getNotificationsPaginationMeta)
  const useCasesList = useSelector(getUseCasesList)

  const isFetching = useSelector(isFetchingNotifications)
  const modalPageName = useSelector(getOpenedModal)
  const selectedCompanyId = useSelector(getSelectedCompanyId)

  const defaultUseCaseFilter = useMemo(() => ({
    value: 'all',
    label: intl.formatMessage({ id: 'notifications.modal.overivew.allUseCases' }),
  }), [
    intl,
  ])

  const [useCaseFilter, setUseCaseFilter] = useState(defaultUseCaseFilter)

  const [dialogState, setDialogState] = useState({
    open: false,
    id: 0,
  })

  const open = modalPageName === NOTIFICATIONS_OVERVIEW_MODAL_NAME
  const useCaseOptions = useMemo(() => {
    const options = [defaultUseCaseFilter]

    return options.concat(useCasesList.map((useCase) => ({
      label: useCase.name,
      value: useCase.useCaseId,
    })))
  }, [
    useCasesList,
    defaultUseCaseFilter,
  ])

  useEffect(() => {
    if (open) {
      dispatch(
        requestNotificationsAction({
          companyId: selectedCompanyId,
          page: notificationsPaginationMeta.page,
          pageSize: notificationsPaginationMeta.pageSize,
          read: notificationsPaginationMeta.filter === 'unread' ? false : undefined,
          useCaseId: (!useCaseFilter || useCaseFilter.value === 'all') ? undefined : useCaseFilter.value,
        }),
      )
    }
  }, [
    open,
    dispatch,
    useCaseFilter,
    selectedCompanyId,
    notificationsPaginationMeta,
  ])

  const onUseCaseChange = (e: React.SyntheticEvent, selectedValue: { value: string, label: string }) => {
    if (selectedValue) {
      setUseCaseFilter(selectedValue)
    }
  }

  const handleModalClose = () => {
    dispatch(
      setPrimaryModalPageName(null),
    )
  }

  const handleMarkAllAsRead = () => {
    dispatch(
      markNotificationsAllAsReadAction({
        useCaseId: (!useCaseFilter || (useCaseFilter.value === 'all')) ? undefined : useCaseFilter.value,
        companyId: selectedCompanyId,
      }),
    )
  }

  const tabs = [
    {
      label: intl.formatMessage({ id: 'notifications.modal.overivew.tabs.all' }),
      key: 'all',
      active: (!notificationsPaginationMeta.filter || (notificationsPaginationMeta.filter === 'all')),
    },
    {
      label: intl.formatMessage({ id: 'notifications.modal.overivew.tabs.unread' }, { count: unreadCountOfList }),
      key: 'unread',
      active: notificationsPaginationMeta.filter === 'unread',
    },
  ]

  const handleTabChange = (selection: Common.TabOption) => {
    dispatch(
      changeNotificationsPaginationMetaAction({
        page: 1,
        filter: selection.key,
      }),
    )
  }

  const onPageChange = (event: any, newPage: number) => {
    dispatch(
      changeNotificationsPaginationMetaAction({
        page: newPage + 1,
      }),
    )
  }

  const onRowsPerPageChange = (event: any) => {
    dispatch(
      changeNotificationsPaginationMetaAction({
        page: 1,
        pageSize: Number(event.target.value),
      }),
    )
  }

  const onDismissButtonClick = (notification: Notifications.NotificationItem) => {
    setDialogState({
      open: true,
      id: Number(notification.id),
    })
  }

  const handleDismiss = (id: number) => {
    dispatch(
      dismissNotificationsAction({
        companyId: selectedCompanyId,
        notificationIds: [Number(id)],
      }),
    )

    setDialogState({
      open: false,
      id: 0,
    })
  }

  const onMarkAsReadButtonClick = (notification: Notifications.NotificationItem) => {
    dispatch(
      markNotificationsAsReadAction({
        companyId: selectedCompanyId,
        notificationIds: [Number(notification.id)],
      }),
    )
  }

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'notifications.modal.overivew' })}
      handleClose={handleModalClose}
      hasUnsavedChanges={false}
    >
      <SidePanelCardComponent>
        <Box
          display='flex'
          flexDirection='column'
          gap={2}
        >
          <NotificationsHeaderComponent
            tabs={tabs}
            useCaseOptions={useCaseOptions}
            useCaseFilter={useCaseFilter}
            unreadCountOfList={unreadCountOfList}
            onTabChange={handleTabChange}
            onUseCaseChange={onUseCaseChange}
            handleMarkAllAsRead={handleMarkAllAsRead}
          />

          <NotificationsListComponent
            notifications={notifications}
            notificationsTotal={notificationsTotal}
            isFetching={isFetching}
            isAdmin={isAdmin}
            handleModalClose={handleModalClose}
            onDismissButtonClick={onDismissButtonClick}
            onMarkAsReadButtonClick={onMarkAsReadButtonClick}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            notificationsPaginationMeta={notificationsPaginationMeta}
          />
        </Box>
      </SidePanelCardComponent>

      <TextConfirmationDialogComponent
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: 0 })}
        onSubmit={() => handleDismiss(dialogState.id)}
        confirmationText={String(dialogState.id)}
        confirmationInputLabel={intl.formatMessage({ id: 'notifications.modal.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'notifications.modal.dialog.content' }, {
            id: <Box component='strong'>{dialogState.id}</Box>,
          })
        }
      />

      <SidePanelCardActionsComponent>
        <ModalButtonComponent
          name='notificationsOverviewCloseButton'
          onClick={handleModalClose}
          type='back'
        />
      </SidePanelCardActionsComponent>
    </SidePanelComponent>
  )
}

export default NotificationsOverviewModal
