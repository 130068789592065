import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { NOTIFICATIONS_SINKS_TEST_MODAL_NAME } from '@constants/modals.constants'

import TextFieldComponent from '@base/forms/TextField'
import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField'

import { requestNotificationsTemplatesAction, testNotificationsSinkAction } from '@redux/modules/notifications/notifications.actions'
import { getNotificationsTemplates, isFetchingNotificationsTemplates, isSubmittingNotificationsSinks } from '@redux/modules/notifications/notifications.selectors'

import validations from './NotificationsSinkTestModal.validations'

export interface NotificationsSinkTestModalDetails extends Common.ModalDetails {
  sink: Notifications.NotificationSinkItem
}

export interface NotificationsSinkTestModalValues extends Notifications.TestNotificationsSinkRequest {
  template: Notifications.NotificationTemplateItem | null
}

const NotificationsSinkTestModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const isSubmitting = useSelector(isSubmittingNotificationsSinks)
  const modalPageName = useSelector(getOpenedModal)
  const templatesList = useSelector(getNotificationsTemplates)
  const isFetchingTemplates = useSelector(isFetchingNotificationsTemplates)

  const { sink } = useSelector((state) => getModalDetails<NotificationsSinkTestModalDetails>(state))
  const open = modalPageName === NOTIFICATIONS_SINKS_TEST_MODAL_NAME

  const templatesListForSink = useMemo(() => {
    return templatesList.filter((item) => {
      if (!sink) {
        return false
      }

      return item.platform === sink.platform
    })
  }, [templatesList, sink])

  const initialValues = useMemo(() => {
    if (!sink) {
      return {
        id: '',
        companyId: '',
        useCaseId: '',
        templateId: '',
        template: null,
      } as NotificationsSinkTestModalValues
    }

    return {
      ...sink,
      template: templatesList.find((item) => {
        return item.id === sink.id
      }),
    } as NotificationsSinkTestModalValues
  }, [sink, templatesList])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: NotificationsSinkTestModalValues) => {
    const config: Notifications.TestNotificationsSinkRequest = {
      id: values.id!,
      templateId: values.template?.id,
    }

    if (values.useCaseId) {
      Object.assign(config, {
        useCaseId: values.useCaseId,
      })
    }

    if (values.companyId) {
      Object.assign(config, {
        companyId: values.companyId,
      })
    }

    dispatch(
      testNotificationsSinkAction(config as Notifications.TestNotificationsSinkRequest),
    )
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: validations(intl),
  })

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    }

    if (open && templatesList.length === 0) {
      dispatch(requestNotificationsTemplatesAction({ page: 1 }))
    }
  }, [
    open,
    dispatch,
    resetForm,
    templatesList,
  ])

  return (
    <SidePanelComponent
      open={open && isAdmin}
      title={intl.formatMessage({ id: 'notifications.sinks.test' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='id'
                    touched={touched.id}
                    errors={errors.id}
                    value={values.id}
                    label={intl.formatMessage({ id: 'notifications.sinks.fields.id' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>

                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='companyId'
                    touched={touched.companyId}
                    errors={errors.companyId}
                    value={values.companyId}
                    label={intl.formatMessage({ id: 'notifications.sinks.fields.companyId' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>

                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='useCaseId'
                    touched={touched.useCaseId}
                    errors={errors.useCaseId}
                    value={values.useCaseId}
                    label={intl.formatMessage({ id: 'notifications.sinks.fields.useCaseId' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>

                <FormLayoutItem xs={12}>
                  <AutocompleteSelectFieldComponent<Notifications.NotificationTemplateItem>
                    name='template'
                    label={intl.formatMessage({ id: 'notifications.sinks.fields.template' })}
                    value={values.template}
                    options={templatesListForSink}
                    getOptionLabel={(item: Notifications.NotificationTemplateItem) => item.description || item.id || 'n/a'}
                    isOptionEqualToValue={(option: Notifications.NotificationTemplateItem, value: Notifications.NotificationTemplateItem) => {
                      return option.id === value.id
                    }}
                    handleChangeCallback={(e: React.SyntheticEvent, selectedValue: Notifications.NotificationTemplateItem) => {
                      setFieldValue('template', selectedValue)

                      setFieldValue('templateId', selectedValue.id)
                    }}
                    loading={isFetchingTemplates}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='notificationsSinkTestModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='notificationsSinkTestModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid || !dirty}
              label={intl.formatMessage({ id: 'notifications.sinks.table.action.test' })}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default NotificationsSinkTestModalContainer
