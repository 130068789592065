import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import {
  GRID_ACTIONS_COLUMN_TYPE,
  GridCellParams,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-premium'

import { useIntl } from 'react-intl'
import { Box, Card } from '@mui/material'
import { useDispatch, useSelector } from '@redux/hooks'
import { useRouteMatch } from 'react-router-dom'

import {
  generateActionsColumnDefinition,
  generateBooleanColumnDefinition,
  generateChipColumnDefinition,
  generateCopyButtonColumnDefinition,
} from '@utils/data-grid-cells.utils'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import useGridInitialState from '@hooks/useGridInitialState.hook'

import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { removeNotificationsRoutingsAction, requestNotificationsRoutingsAction } from '@redux/modules/notifications/notifications.actions'
import { isFetchingNotificationsRoutings, getNotificationsRoutings } from '@redux/modules/notifications/notifications.selectors'
import { getBgColorByPlatform, mapNotificationSeverityToChipBgColor, mapNotificationSeverityToChipColor } from '@utils/notifications.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { NOTIFICATIONS_ROUTINGS_MODAL_NAME } from '@constants/modals.constants'

import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button/DataGridActionButton.component'
import EditIcon from '@icons/edit.icon'
import DeleteIcon from '@icons/delete.icon'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog/TextConfirmationDialog.component'
import NotificationsRoutingsModalContainer from '@containers/modals/notifications/notifications-routings-modal'
import RoutingsGraphContainer from './routings-graph/RoutingsGraph.container'

export interface NotificationsRoutingsContainerProps {}

const NotificationsRoutingsContainer: React.FC<NotificationsRoutingsContainerProps> = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const selectedCompanyId = useSelector(getSelectedCompanyId)
  const isFetching = useSelector(isFetchingNotificationsRoutings)
  const list = useSelector(getNotificationsRoutings)

  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()
  const tableId = getDataGridId(DATA_GRIDS.NOTIFICATIONS_ROUTINGS_TABLE, 1)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'severity', sort: 'desc' }],
    },
  })

  const [dialogState, setDialogState] = useState({
    open: false,
    id: '',
  })

  const handleNotificationsRoutingsEdit = useCallback((row: Notifications.NotificationRoutingItem) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: NOTIFICATIONS_ROUTINGS_MODAL_NAME,
        modalDetails: {
          returnTo: '',
          routing: row,
        },
      }),
    )
  }, [dispatch])

  const handleNotificationsRoutingsDelete = useCallback((row: Notifications.NotificationRoutingItem) => {
    setDialogState({
      open: true,
      id: row.id!,
    })
  }, [])

  const handleDelete = (id: string) => {
    dispatch(
      removeNotificationsRoutingsAction({
        id,
        companyId: selectedCompanyId,
        useCaseId: usecase,
      }),
    )

    setDialogState({
      open: false,
      id: '',
    })
  }

  const getActionItems = useCallback((params: GridRowParams<Notifications.NotificationRoutingItem>) => {
    return [
      <DataGridActionButtonComponent
        name='notificationsRoutingsEdit'
        icon={<EditIcon />}
        onClick={() => handleNotificationsRoutingsEdit(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.edit' })}
        id={params.id}
      />,
      <DataGridActionButtonComponent
        name='notificationsRoutingsDelete'
        icon={<DeleteIcon />}
        onClick={() => handleNotificationsRoutingsDelete(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
        id={params.id}
      />,
    ]
  }, [
    intl,
    handleNotificationsRoutingsEdit,
    handleNotificationsRoutingsDelete,
  ])

  useLayoutEffect(() => {
    dispatch(
      requestNotificationsRoutingsAction({ companyId: selectedCompanyId, useCaseId: usecase, page: 1 }),
    )
  }, [dispatch, selectedCompanyId, usecase])

  const columns = useMemo<GridColDef[]>(() => {
    const columnsList = [
      generateCopyButtonColumnDefinition({
        field: 'id',
        headerName: intl.formatMessage({ id: 'notifications.routings.fields.id' }),
      }),
      generateChipColumnDefinition({
        intl,
        field: 'notificationType',
        headerName: intl.formatMessage({ id: 'notifications.routings.fields.notificationType' }),
        uppercase: true,
        gridColDefOverrides: {
          minWidth: 150,
        },
      }),
      generateChipColumnDefinition({
        intl,
        field: 'severity',
        headerName: intl.formatMessage({ id: 'notifications.routings.fields.severity' }),
        uppercase: true,
        getBgColorByValue: mapNotificationSeverityToChipBgColor,
        getColorByValue: mapNotificationSeverityToChipColor,
        gridColDefOverrides: {
          minWidth: 100,
        },
      }),
      generateBooleanColumnDefinition({
        intl,
        field: 'enabled',
        headerName: intl.formatMessage({ id: 'notifications.routings.status' }),
        gridColDefOverrides: {
          align: 'left',
          minWidth: 100,
        },
      }),
      generateChipColumnDefinition({
        intl,
        field: 'sinkPlatform',
        headerName: intl.formatMessage({ id: 'notifications.routings.sinkPlatform' }),
        uppercase: true,
        getBgColorByValue: getBgColorByPlatform,
        gridColDefOverrides: {
          minWidth: 100,
          valueGetter: (params: GridCellParams, row: Notifications.NotificationRoutingItem) => {
            return row?.sink?.platform
          },
          groupingValueGetter: (params: GridCellParams, row: Notifications.NotificationRoutingItem) => {
            return row?.sink?.platform
          },
        },
      }),
      generateCopyButtonColumnDefinition({
        field: 'sinkId',
        headerName: intl.formatMessage({ id: 'notifications.routings.sinkId' }),
        gridColDefOverrides: {
          valueGetter: (params: GridCellParams, row: Notifications.NotificationRoutingItem) => {
            return row?.sink?.id
          },
          groupingValueGetter: (params: GridCellParams, row: Notifications.NotificationRoutingItem) => {
            return row?.sink?.id
          },
        },
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 2,
      }),
    ]

    return columnsList
  }, [
    intl,
    getActionItems,
  ])

  return (
    <Card
      data-testid={NotificationsRoutingsContainer.name}
      elevation={0}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.NOTIFICATIONS_ROUTINGS_TABLE}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={(row) => row.id}
        initialState={initialState}
      />

      <RoutingsGraphContainer />

      <TextConfirmationDialogComponent
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onSubmit={() => handleDelete(dialogState.id)}
        confirmationText={dialogState.id}
        confirmationInputLabel={intl.formatMessage({ id: 'notifications.routings.table.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'notifications.routings.table.dialog.content' }, {
            name: <Box component='strong'>{dialogState.id}</Box>,
          })
        }
      />

      <NotificationsRoutingsModalContainer />
    </Card>
  )
}

export default NotificationsRoutingsContainer
