import { NOTIFICATIONS_PLATFORMS } from '@constants/notifications.constants'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

const validation = (intl: IntlShape) => {
  const shape = Yup.object().shape({
    name: Yup.string().required(),
    channel: Yup.string().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.SLACK,
      then: (schema) => schema.required().test('is-valid-slack-channel', 'Invalid Slack channel. It should be a valid channel name with a # at the beginning.', (value: any) => {
        if (!value) {
          return false
        }

        // should be a valid slack channel name with a # at the beginning
        return value.match(/^#[a-zA-Z0-9_-]+$/)
      }),
      otherwise: (schema) => schema.notRequired(),
    }),
    ignore_emails: Yup.array().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.EMAIL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    add_emails: Yup.array().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.EMAIL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    subject: Yup.string().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.EMAIL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    unsubscribe_group_id: Yup.number().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.EMAIL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    reply_email: Yup.string().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.EMAIL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    reply_email_name: Yup.string().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.EMAIL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    from_email: Yup.string().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.EMAIL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    from_email_name: Yup.string().when('platform', {
      is: (platform: string) => platform === NOTIFICATIONS_PLATFORMS.EMAIL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  })

  return shape
}

export default validation
