import React from 'react'
import { Box, useTheme } from '@mui/material'

import ChartTooltipTableRowComponent from '@components/charts/tooltips/ChartTooltipTableRow'

export interface ChartTooltipTableDividerComponentProps {
  show?: boolean
}

const ChartTooltipTableDividerComponent: React.FC<ChartTooltipTableDividerComponentProps> = ({ show }) => {
  const theme = useTheme()

  if (!show) {
    return null
  }

  return (
    <ChartTooltipTableRowComponent
      sx={{
        display: 'block',
        position: 'relative',
        height: '25px',
      }}
    >
      <Box
        data-testid={ChartTooltipTableDividerComponent.name}
        component='span'
        sx={{
          display: 'block',
          width: '100%',
          position: 'absolute',
          borderBottom: `1px solid ${theme.palette.new.business_black_20}`,
          left: '-20px',
          minWidth: '1000px',
          top: '50%',
        }}
      />
    </ChartTooltipTableRowComponent>
  )
}

export default ChartTooltipTableDividerComponent
