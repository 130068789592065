import React from 'react'

import {
  styled, FormGroup,
  FormGroupProps,
  useTheme, FormLabel,
  FormControl,
} from '@mui/material'

import typography from '@configuration/theme/theme.typography'
import { DEFAULT_BORDER_RADIUS, DEFAULT_PADDING } from '@constants/ui.constants'
import classNames from 'classnames'

const GroupFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  gap: '4px',
}))

export const GroupFormLabel = styled(FormLabel)(({ theme }) => ({
  ...typography.caption,
  color: theme.palette.new.business_black_60,
}))

const FormGroupBase = styled(FormGroup)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.new.business_black_20,
  width: '100%',
  borderRadius: DEFAULT_BORDER_RADIUS.SMALL,

  '&.group-in-group': {
    border: 'none',
    borderRadius: 'unset',

    '& .MuiFormControl-root': {
      paddingLeft: '28px',

      '&:first-of-type': {
        borderTop: '1px solid',
        borderColor: theme.palette.new.business_black_20,
        borderTopLeftRadius: 'unset',
        borderTopRightRadius: 'unset',
      },

      '&:last-of-type': {
        borderBottom: '2px solid',
        borderColor: theme.palette.new.business_black_20,
        borderBottomLeftRadius: 'unset',
        borderBottomRightRadius: 'unset',
      },
    },
  },
  '& .MuiFormControl-root': {
    border: 'none',
    borderBottom: '1px solid',
    borderColor: theme.palette.new.business_black_20,
    borderRadius: 'unset',

    '&:first-of-type': {
      borderTopLeftRadius: DEFAULT_BORDER_RADIUS.SMALL,
      borderTopRightRadius: DEFAULT_BORDER_RADIUS.SMALL,
    },

    '&:last-of-type': {
      borderBottom: 'none',
      borderBottomLeftRadius: DEFAULT_BORDER_RADIUS.SMALL,
      borderBottomRightRadius: DEFAULT_BORDER_RADIUS.SMALL,
    },
  },
}))

export interface FormGroupComponentProps extends FormGroupProps {
  /**
   * The label of the form group
   */
  label?: string
  /**
   * Whether the form group is in a group
   */
  groupInGroup?: boolean
}

const FormGroupComponent: React.FC<FormGroupComponentProps> = ({
  label,
  children,
  groupInGroup,
  ...props
}) => {
  const theme = useTheme()

  const labelStyle = {
    paddingLeft: DEFAULT_PADDING.MEDIUM,
    color: theme.palette.new.business_black_60,
  }

  return (
    <GroupFormControl
      variant='standard'
      data-testid={FormGroupComponent.name}
    >
      {
        label ? (
          <GroupFormLabel
            sx={labelStyle}
          >
            {label}
          </GroupFormLabel>
        ) : (
          null
        )
      }

      <FormGroupBase
        {...props}
        className={classNames(props.className, {
          'group-in-group': groupInGroup,
        })}
      >
        {children}
      </FormGroupBase>
    </GroupFormControl>
  )
}

export default FormGroupComponent
