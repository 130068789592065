import React from 'react'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { DEFAULT_PADDING } from '@constants/ui.constants'

import ChevronRightIcon from '@icons/chevronRight.icon'
import ArrowLastIcon from '@icons/arrowLast.icon'
import ArrowFirstIcon from '@icons/arrowFirst.icon'
import ChevronLeftIcon from '@icons/chevronLeft.icon'
import IconButtonComponent from '@base/buttons/IconButton'

export interface PaginationActionsComponentProps {
  count: number,
  onPageChange: (event: any, page: number) => void,
  page: number,
  rowsPerPage: number,
  nextIconButtonProps?: {
    disabled?: boolean,
  },
  backIconButtonProps?: {
    disabled?: boolean,
  },
}

const PaginationActionsComponent: React.FC<PaginationActionsComponentProps> = ({
  count,
  onPageChange,
  page,
  rowsPerPage,
  nextIconButtonProps,
  backIconButtonProps,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const lastPage = Math.ceil(count / rowsPerPage) - 1

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1)
  }

  const handleGoToFirstPage = (event: any) => {
    onPageChange(event, 0)
  }

  const handleGoToLastPage = (event: any) => {
    onPageChange(event, lastPage)
  }

  const isLastPage = page >= lastPage

  return (
    <Box
      sx={{
        flexShrink: 0,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: 'flex',
        gap: DEFAULT_PADDING.MEDIUM,
      }}
      data-testid={PaginationActionsComponent.name}
    >
      <IconButtonComponent
        name='firstPage'
        color='tertiary'
        onClick={handleGoToFirstPage}
        disabled={backIconButtonProps?.disabled || (page === 0)}
        aria-label={intl.formatMessage({ id: 'common.button.firstPage' })}
        label={intl.formatMessage({ id: 'common.button.firstPage' })}
        IconComponent={ArrowFirstIcon}
      />

      <IconButtonComponent
        name='previousPage'
        color='tertiary'
        onClick={handleBackButtonClick}
        disabled={backIconButtonProps?.disabled || (page === 0)}
        aria-label={intl.formatMessage({ id: 'common.button.previousPage' })}
        label={intl.formatMessage({ id: 'common.button.previousPage' })}
        IconComponent={ChevronLeftIcon}
      />

      <IconButtonComponent
        name='nextPage'
        color='tertiary'
        onClick={handleNextButtonClick}
        disabled={nextIconButtonProps?.disabled || isLastPage}
        aria-label={intl.formatMessage({ id: 'common.button.nextPage' })}
        label={intl.formatMessage({ id: 'common.button.nextPage' })}
        IconComponent={ChevronRightIcon}
      />

      <IconButtonComponent
        name='lastPage'
        color='tertiary'
        onClick={handleGoToLastPage}
        disabled={nextIconButtonProps?.disabled || isLastPage}
        aria-label={intl.formatMessage({ id: 'common.button.lastPage' })}
        label={intl.formatMessage({ id: 'common.button.lastPage' })}
        IconComponent={ArrowLastIcon}
      />
    </Box>
  )
}

export default PaginationActionsComponent
