import React from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  TablePagination,
  Typography,
  useTheme,
} from '@mui/material'

import PaginationActionsComponent from '@base/utils/PaginationActions'
import NotificationTileComponent from '@base/tiles/NotificationTile'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'
import { getPaginationStyles } from '@utils/ui.utils'
import { getPaginationLabel } from '@utils/common.utils'

interface NotificationsListComponentProps {
  notifications: Notifications.NotificationItem[]
  notificationsTotal: number
  isFetching: boolean
  isAdmin: boolean
  handleModalClose: () => void
  onDismissButtonClick: (notification: Notifications.NotificationItem) => void
  onMarkAsReadButtonClick: (notification: Notifications.NotificationItem) => void
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  notificationsPaginationMeta: Notifications.NotificationsPaginationMeta
}

const NotificationsListComponent: React.FC<NotificationsListComponentProps> = ({
  notifications,
  notificationsTotal,
  isFetching,
  isAdmin,
  handleModalClose,
  onDismissButtonClick,
  onMarkAsReadButtonClick,
  onPageChange,
  onRowsPerPageChange,
  notificationsPaginationMeta,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const { toolbarStyles, slotProps } = getPaginationStyles(theme)
  const labelDisplayedRows = getPaginationLabel(intl)

  return (
    <Box
      data-testid={NotificationsListComponent.name}
      display='flex'
      flexDirection='column'
      gap={2}
    >
      <Box
        display='flex'
        flexDirection='column'
        sx={{
          border: '1px solid',
          position: 'relative',
          borderColor: theme.palette.new.business_black_20,
          borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
          filter: isFetching ? 'blur(5px)' : 'none',
        }}
      >
        {
          notificationsTotal === 0 && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='300px'
            >
              <Typography variant='h6'>
                {
                  intl.formatMessage({
                    id: notificationsPaginationMeta.filter === 'unread' ?
                      'notifications.modal.no_unread_notifications_found' :
                      'notifications.modal.no_notifications_found',
                  })
                }
              </Typography>
            </Box>
          )
        }

        {
          notifications.map((notification, index) => (
            <NotificationTileComponent
              key={notification.id}
              notification={notification}
              handleModalClose={handleModalClose}
              borderBottom={index !== notifications.length - 1}
              isAdmin={isAdmin}
              onDismissButtonClick={onDismissButtonClick}
              onMarkAsReadButtonClick={onMarkAsReadButtonClick}
              level='company'
            />
          ))
        }
      </Box>

      <Box
        display='flex'
        justifyContent='flex-end'
        width='100%'
      >
        <TablePagination
          ActionsComponent={PaginationActionsComponent}
          labelDisplayedRows={labelDisplayedRows}
          count={notificationsTotal}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={notificationsPaginationMeta.page - 1}
          rowsPerPage={notificationsPaginationMeta.pageSize}
          sx={toolbarStyles}
          slotProps={slotProps}
          disabled={isFetching || notificationsTotal === 0}
        />
      </Box>
    </Box>
  )
}

export default NotificationsListComponent
