import palette from '@configuration/theme/theme.palette'
import groupBy from 'lodash.groupby'

import { INSIGHTS_COLOR_WAY } from '@constants/insights.constants'
import { DEFAULT_ARROW_CONFIG, NODE_TYPES } from '@constants/flow.constants'
import { History } from 'history'
import { IntlShape } from 'react-intl'
import { generatePath } from 'react-router-dom'

import {
  COCKPIT_VIEW_NAMES_TO_LOCALE_KEYS_MAP,
  COCKPIT_VIEW_NAMES_TO_PATH_MAP,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_TYPE_TO_CONTENT_KEY_MAP,
  NOTIFICATIONS_PLATFORMS,
  NOTIFICATIONS_SEVERITIES,
  NOTIFICATIONS_STATUS,
  NOTIFICATIONS_TEMPLATES_TYPES_TO_COLOR_MAP,
} from '@constants/notifications.constants'

import { DATA_FILE_LINK_PATH } from '@constants/routes.constants'
import { ButtonComponentProps } from '@base/buttons/Button/Button.component'

/**
 * Generates a notification action
 *
 * @param intl React Intl instance
 * @param history React Router history instance
 * @param notification Notification item
 *
 * @returns The notification action button label and action
 */
export const generateNotificationAction = ({
  intl,
  history,
  notification,
  handleModalClose,
} : {
  intl: IntlShape
  history: History
  notification: Notifications.NotificationItem
  handleModalClose: () => void
}): {
  actionButtonDisabled: ButtonComponentProps['disabled']
  actionButtonLabel: ButtonComponentProps['label'] | undefined
  actionButtonAction: ButtonComponentProps['onClick'] | undefined
  actionButtonColor: ButtonComponentProps['color']
} => {
  const isInsights = notification.severity === NOTIFICATIONS_SEVERITIES.INSIGHT
  const isDisabled = notification.dismissed || notification.read

  const baseAction = {
    actionButtonDisabled: true,
    actionButtonLabel: undefined,
    actionButtonAction: undefined,
    actionButtonColor: 'primary' as const,
  }

  if (!notification.action) {
    return baseAction
  }

  if (NOTIFICATION_ACTIONS.COCKPIT_VIEW === notification.action.type) {
    const cockpitViewAction = notification.action as Notifications.CockpitViewAction
    const cockpitViewPath = COCKPIT_VIEW_NAMES_TO_PATH_MAP[cockpitViewAction.view_name]
    const cockpitViewLabelKey = COCKPIT_VIEW_NAMES_TO_LOCALE_KEYS_MAP[cockpitViewAction.view_name]

    if (cockpitViewPath && cockpitViewLabelKey && notification.useCaseId) {
      const cockpitViewLabel = intl.formatMessage({ id: cockpitViewLabelKey })

      return {
        actionButtonDisabled: isDisabled,
        actionButtonColor: isInsights ? 'paretos' : 'highlighted',
        actionButtonLabel: intl.formatMessage({ id: 'notifications.view.goToLabel' }, { name: cockpitViewLabel }),
        actionButtonAction: () => {
          handleModalClose()

          history.push(generatePath(cockpitViewPath, { usecase: notification.useCaseId }))
        },
      }
    }
  }

  if (NOTIFICATION_ACTIONS.VIEW_UPLOAD_FILE === notification.action.type) {
    const viewUploadFileAction = notification.action as Notifications.ViewUploadFileAction

    if (notification.useCaseId) {
      return {
        actionButtonDisabled: isDisabled,
        actionButtonColor: isInsights ? 'paretos' : 'primary',
        actionButtonLabel: intl.formatMessage({ id: 'notifications.view.viewUploadFile' }),
        actionButtonAction: () => {
          handleModalClose()

          history.push(generatePath(DATA_FILE_LINK_PATH, {
            usecase: notification.useCaseId!,
            identifier: viewUploadFileAction.file_type,
            version: viewUploadFileAction.version,
            file: viewUploadFileAction.file_id,
          }))
        },
      }
    }
  }

  return baseAction
}

/**
 * Converts an array based config to a map based config
 *
 * @param arrayBasedConfig - The array based config
 *
 * @returns The map based config
 */
export const arrayBasedConfigToMapBasedConfig = (arrayBasedConfig: Notifications.InternalNotificationConfigMap[]) => {
  const base: Record<string, string> = {}

  if (!arrayBasedConfig) {
    return {}
  }

  arrayBasedConfig.forEach((item) => {
    base[item.key] = item.value
  })

  return base
}

/**
 * Converts a map based config to an array based config
 *
 * @param mapBasedConfig - The map based config
 *
 * @returns The array based config
 */
export const mapBasedConfigToArrayBasedConfig = (mapBasedConfig?: Notifications.NotificationConfigMap) => {
  if (!mapBasedConfig) {
    return []
  }

  return Object.entries(mapBasedConfig).map(([key, value]) => ({ key, value }))
}

/**
 * Gets the background color by platform
 *
 * @param platform - The platform
 *
 * @returns The background color
 */
export const getBgColorByPlatform = (platform: string) => {
  const platformValue = platform as Notifications.Platform

  switch (platformValue) {
    case NOTIFICATIONS_PLATFORMS.EMAIL:
      return `${INSIGHTS_COLOR_WAY[0].base}55`
    case NOTIFICATIONS_PLATFORMS.SLACK:
      return `${INSIGHTS_COLOR_WAY[1].base}55`
    default:
      return `${INSIGHTS_COLOR_WAY[2].base}55`
  }
}

/**
 * Maps a notification type to a chip color
 *
 * @param type - The notification type
 *
 * @returns The chip color
 */
export const mapNotificationTypeToChipColor = (type: string) => {
  const typeValue = type as Notifications.NotificationType

  const color = NOTIFICATIONS_TEMPLATES_TYPES_TO_COLOR_MAP[typeValue]

  return `${color || INSIGHTS_COLOR_WAY[0].base}55`
}

/**
 * Maps a notification severity to a chip background color
 *
 * @param severity - The notification severity
 *
 * @returns The chip background color
 */
export const mapNotificationSeverityToChipBgColor = (severity: string) => {
  const severityValue = severity as Notifications.Severity

  switch (severityValue) {
    case NOTIFICATIONS_SEVERITIES.INFO:
      return palette.new.talkative_turquoise_10
    case NOTIFICATIONS_SEVERITIES.WARNING:
      return palette.new.youthful_yellow_10
    case NOTIFICATIONS_SEVERITIES.ERROR:
      return palette.new.rebellious_red_10
    case NOTIFICATIONS_SEVERITIES.CRITICAL:
      return palette.new.rebellious_red_10
    case NOTIFICATIONS_SEVERITIES.INSIGHT:
      return palette.new.versatile_violet_10
    default:
      return palette.new.gainful_grey_20
  }
}

/**
 * Maps a notification severity to a chip color
 *
 * @param severity - The notification severity
 *
 * @returns The chip color
 */
export const mapNotificationSeverityToChipColor = (severity: string) => {
  const severityValue = severity as Notifications.Severity

  switch (severityValue) {
    case NOTIFICATIONS_SEVERITIES.INFO:
      return palette.new.talkative_turquoise
    case NOTIFICATIONS_SEVERITIES.WARNING:
      return palette.new.youthful_yellow
    case NOTIFICATIONS_SEVERITIES.ERROR:
      return palette.new.rebellious_red
    case NOTIFICATIONS_SEVERITIES.CRITICAL:
      return palette.new.rebellious_red
    case NOTIFICATIONS_SEVERITIES.INSIGHT:
      return palette.new.versatile_violet
    default:
      return palette.new.gainful_grey
  }
}

/**
 * Gets the sink option label
 *
 * @param sink - The sink
 *
 * @returns The sink option label
 */
export const getSinkOptionLabel = (sink: Notifications.NotificationSinkItem) => {
  return `${sink.name || sink.id} (Platform: ${sink.platform}, Enabled: ${sink.enabled ? '✅' : '❌'})`
}

/**
 * Checks if a sink option is equal to a value
 *
 * @param option - The option
 * @param value - The value
 *
 * @returns Whether the option is equal to the value
 */
export const isSinkOptionEqualToValue = (option: Notifications.NotificationSinkItem, value: Notifications.NotificationSinkItem) => {
  return option.id === value.id
}

/**
 * Maps a notification status to a chip color
 *
 * @param status - The notification status
 *
 * @returns The chip color
 */
export const mapNotificationStatusToChipColor = (status: string) => {
  const statusValue = status as Notifications.DispatchStatus

  switch (statusValue) {
    case NOTIFICATIONS_STATUS.SUCCESS:
      return palette.new.generous_green
    case NOTIFICATIONS_STATUS.FAILED:
      return palette.new.rebellious_red
    case NOTIFICATIONS_STATUS.NO_SINKS:
      return palette.new.gainful_grey
    case NOTIFICATIONS_STATUS.PENDING:
      return palette.new.gainful_grey
    case NOTIFICATIONS_STATUS.UNKNOWN:
      return palette.new.gainful_grey
    default:
      return palette.new.gainful_grey
  }
}

/**
 * Maps a notification status to a chip background color
 *
 * @param status - The notification status
 *
 * @returns The chip background color
 */
export const mapNotificationStatusToChipBgColor = (status: string) => {
  const statusValue = status as Notifications.DispatchStatus

  switch (statusValue) {
    case NOTIFICATIONS_STATUS.SUCCESS:
      return palette.new.generous_green_10
    case NOTIFICATIONS_STATUS.FAILED:
      return palette.new.rebellious_red_10
    case NOTIFICATIONS_STATUS.NO_SINKS:
      return palette.new.gainful_grey_20
    case NOTIFICATIONS_STATUS.PENDING:
      return palette.new.gainful_grey_20
    case NOTIFICATIONS_STATUS.UNKNOWN:
      return palette.new.gainful_grey_20
    default:
      return palette.new.gainful_grey_20
  }
}

/**
 * Gets the notification message id
 * @param type notification type
 * @param severity notification severity
 * @returns the notification message id
 */
export const getNotificationMessageId = (type: Notifications.NotificationType, severity: Notifications.Severity) => {
  return `${NOTIFICATION_TYPE_TO_CONTENT_KEY_MAP[type]}.${severity}`
}

/**
 * Generates a notification graph
 *
 * @param routingGraph - The routing graph
 *
 * @returns The notification graph
 */
export const generateNotificationGraph = (routingGraph: Notifications.NotificationGraphResponse) => {
  const baseGraph: Notifications.NotificationGraph = {
    nodes: [],
    edges: [],
  }

  if (!routingGraph) {
    return baseGraph
  }

  const availableTypes = Object.keys(routingGraph) as Notifications.NotificationType[]

  if (availableTypes.length === 0) {
    return baseGraph
  }

  const nodes: Notifications.NotificationGraph['nodes'] = []
  const edges: Notifications.NotificationGraph['edges'] = []

  availableTypes.forEach((type) => {
    const sinksInfo = routingGraph[type] as Notifications.SinkInfo[]

    nodes.push({
      id: type,
      type: NODE_TYPES.NOTIFICATION_TYPE,
      position: { x: 0, y: 0 },
      data: {
        label: type,
      },
    })

    const groupedBySeverity = groupBy(sinksInfo, 'severity')

    Object.entries(groupedBySeverity).forEach(([severity, sinks]) => {
      const severityNodeId = `${type}-${severity}`

      nodes.push({
        id: severityNodeId,
        type: NODE_TYPES.NOTIFICATION_TYPE,
        position: { x: 0, y: 0 },
        data: {
          label: severity,
          type,
          severity,
          hasParent: true,
          color: mapNotificationSeverityToChipColor(severity),
          backgroundColor: mapNotificationSeverityToChipBgColor(severity),
        },
      })

      edges.push({
        id: `${type}-${severityNodeId}`,
        source: type,
        target: severityNodeId,
        ...DEFAULT_ARROW_CONFIG,
      })

      sinks.forEach((sinkInfo) => {
        nodes.push({
          id: sinkInfo.routing_id,
          type: NODE_TYPES.NOTIFICATION_SINK_INFO,
          position: { x: 0, y: 0 },
          data: {
            type,
            sinkInfo,
          },
        })

        edges.push({
          id: `${severityNodeId}-${sinkInfo.routing_id}`,
          source: severityNodeId,
          target: sinkInfo.routing_id,
          ...DEFAULT_ARROW_CONFIG,
        })
      })
    })
  })

  return {
    nodes,
    edges,
  }
}
