import React from 'react'

import { Position } from '@xyflow/react'
import {
  Box,
  styled,
  Typography,
  useTheme,
} from '@mui/material'

import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'
import { getBgColorByPlatform } from '@utils/notifications.utils'
import { useIntl } from 'react-intl'
import { copyToClipboard } from '@utils/common.utils'

export const ConfigurationRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}))

export const ConfigurationRowLabel = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.new.black,
}))

export const ConfigurationRowValue = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.new.black,
  backgroundColor: theme.palette.new.business_black_5,
  padding: '1px 3px',
  borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
  textTransform: 'uppercase',
  cursor: 'pointer',
}))

export interface ReactFlowNotificationSinkInfoNodeComponentProps {
  /**
   * Node's data
   */
  data: {
    sinkInfo: Notifications.SinkInfo
  }
}

const ReactFlowNotificationSinkInfoNodeComponent: React.FC<ReactFlowNotificationSinkInfoNodeComponentProps> = ({ data }) => {
  const theme = useTheme()
  const intl = useIntl()
  const { sinkInfo } = data

  const onValueClick = (e: React.MouseEvent<any>, value: string) => {
    copyToClipboard({
      e,
      text: value,
      intl,
    })
  }

  const formatUUID = (uuid: string | undefined) => {
    if (!uuid) {
      return 'N/A'
    }

    return `...${uuid.slice(-4)}`
  }

  return (
    <Box
      data-testid={ReactFlowNotificationSinkInfoNodeComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '250px',
        height: '150px',
        background: theme.palette.new.white,
        border: '1px solid',
        borderColor: sinkInfo.active ? theme.palette.new.generous_green : theme.palette.new.rebellious_red,
        borderRadius: theme.spacing(0.5),
        position: 'relative',
        cursor: 'default',
        padding: theme.spacing(1),
      }}
    >
      <ReactFlowHandleComponent
        type='target'
        position={Position.Top}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          gap: '5px',
        }}
      >
        <ConfigurationRow>
          <ConfigurationRowLabel>
            {intl.formatMessage({ id: 'notifications.routings.graph.active' })}
          </ConfigurationRowLabel>
          <ConfigurationRowValue
            textTransform='uppercase'
            onClick={(e) => onValueClick(e, String(sinkInfo.active))}
            sx={{
              color: sinkInfo.active ? theme.palette.new.generous_green : theme.palette.new.rebellious_red,
              backgroundColor: sinkInfo.active ? theme.palette.new.generous_green_10 : theme.palette.new.rebellious_red_10,
            }}
          >
            {sinkInfo.active ? intl.formatMessage({ id: 'notifications.routings.graph.yes' }) : intl.formatMessage({ id: 'notifications.routings.graph.no' })}
          </ConfigurationRowValue>
        </ConfigurationRow>

        <ConfigurationRow>
          <ConfigurationRowLabel>
            {intl.formatMessage({ id: 'notifications.routings.graph.platform' })}
          </ConfigurationRowLabel>
          <ConfigurationRowValue
            onClick={(e) => onValueClick(e, sinkInfo.platform)}
            sx={{
              color: theme.palette.new.black,
              backgroundColor: getBgColorByPlatform(sinkInfo.platform),
            }}
          >
            {sinkInfo.platform}
          </ConfigurationRowValue>
        </ConfigurationRow>

        <Box />

        <ConfigurationRow>
          <ConfigurationRowLabel>
            {intl.formatMessage({ id: 'notifications.routings.graph.routingId' })}
          </ConfigurationRowLabel>
          <ConfigurationRowValue
            onClick={(e) => onValueClick(e, sinkInfo.routing_id)}
          >
            {formatUUID(sinkInfo.routing_id)}
          </ConfigurationRowValue>
        </ConfigurationRow>

        <ConfigurationRow>
          <ConfigurationRowLabel>
            {intl.formatMessage({ id: 'notifications.routings.graph.sinkId' })}
          </ConfigurationRowLabel>
          <ConfigurationRowValue
            onClick={(e) => onValueClick(e, sinkInfo.sink_id)}
          >
            {formatUUID(sinkInfo.sink_id)}
          </ConfigurationRowValue>
        </ConfigurationRow>

        <ConfigurationRow>
          <ConfigurationRowLabel>
            {intl.formatMessage({ id: 'notifications.routings.graph.templateId' })}
          </ConfigurationRowLabel>
          <ConfigurationRowValue
            onClick={(e) => onValueClick(e, sinkInfo.template?.id || 'N/A')}
          >
            {formatUUID(sinkInfo.template?.id)}
          </ConfigurationRowValue>
        </ConfigurationRow>

      </Box>
    </Box>
  )
}

export default ReactFlowNotificationSinkInfoNodeComponent
