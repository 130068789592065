import { createAction } from 'redux-actions'

import {
  REQUEST_INSIGHTS_ABC_TOTALS,
  RECEIVE_INSIGHTS_ABC_TOTALS,

  START_FETCHING_INSIGHTS,
  STOP_FETCHING_INSIGHTS,

  REQUEST_INSIGHTS_PIPELINE_RUN_INFO,
  RECEIVE_INSIGHTS_PIPELINE_RUN_INFO,

  REQUEST_PROMOTIONS_VISIBILITY_CHANGE,
  RECEIVE_PROMOTIONS_VISIBILITY_CHANGE,

  REQUEST_PROMOTIONS_SETTINGS_CHANGE,
  RECEIVE_PROMOTIONS_SETTINGS_CHANGE,

  REQUEST_LAST_YEAR_VISIBILITY_CHANGE,
  RECEIVE_LAST_YEAR_VISIBILITY_CHANGE,
} from './insights.action-types'

export const startFetchingInsightsAction = createAction<string>(START_FETCHING_INSIGHTS)
export const stopFetchingInsightsAction = createAction<string>(STOP_FETCHING_INSIGHTS)

export const requestInsightsAbcTotalsAction = createAction<Hera.AbcTotalsApiRequest>(REQUEST_INSIGHTS_ABC_TOTALS)
export const receiveInsightsAbcTotalsAction = createAction(RECEIVE_INSIGHTS_ABC_TOTALS)

export const requestInsightsPipelineRunInfoAction = createAction<Hera.LatestPipelineRunInfoAPIRequest>(REQUEST_INSIGHTS_PIPELINE_RUN_INFO)
export const receiveInsightsPipelineRunInfoAction = createAction(RECEIVE_INSIGHTS_PIPELINE_RUN_INFO)

export const requestEventsVisibilityChangeAction = createAction<boolean>(REQUEST_PROMOTIONS_VISIBILITY_CHANGE)
export const receiveEventsVisibilityChangeAction = createAction(RECEIVE_PROMOTIONS_VISIBILITY_CHANGE)

export const requestEventsSettingsChangeAction = createAction<string[]>(REQUEST_PROMOTIONS_SETTINGS_CHANGE)
export const receiveEventsSettingsChangeAction = createAction(RECEIVE_PROMOTIONS_SETTINGS_CHANGE)

export const requestLastYearVisibilityChangeAction = createAction<boolean>(REQUEST_LAST_YEAR_VISIBILITY_CHANGE)
export const receiveLastYearVisibilityChangeAction = createAction(RECEIVE_LAST_YEAR_VISIBILITY_CHANGE)
